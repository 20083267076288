<template>
  <div>
    <h1>Availability Heuristic</h1>

    <h3>You judge the probability of events by how easily examples come to mind.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Availability Heuristic is a cognitive shortcut where people estimate the likelihood of an event based on how easily they can recall examples of that event. This can lead to distorted perceptions, as vivid, recent, or emotionally charged events are more likely to be remembered, thus influencing decision-making disproportionately.</p>
    <p>For example, after seeing news reports about airplane crashes, a person might overestimate the risk of flying, despite statistical evidence that air travel is much safer than car travel. This heuristic can influence a wide range of decisions, from health risks to financial investments.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Availability Heuristic can lead to overestimating the likelihood of rare but dramatic events while underestimating more common, less sensational risks. This bias can influence public policy, personal decisions, and risk assessment, often leading to an overreaction to highly publicized risks and a lack of preparedness for more probable dangers.</p>
    <p>This heuristic can also contribute to the perpetuation of stereotypes and misinformation, as people may overgeneralize based on a few vivid examples rather than considering a broader context.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This heuristic arises because human memory favors information that is vivid, recent, or emotionally charged. Media coverage, personal experiences, and anecdotal evidence can all contribute to the prominence of certain memories, making them more likely to influence judgments about probability and risk.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract the Availability Heuristic, individuals should rely on statistical data and objective analysis rather than memory alone when assessing risks and probabilities. Expanding one’s information sources and considering a broader range of evidence can also help in making more accurate assessments.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Tversky and Kahneman (1973) first established the concept of the Availability Heuristic, showing how people tend to overestimate the frequency of events that are more easily recalled. Subsequent research by Slovic et al. (2004) demonstrated how media exposure can amplify the availability of certain risks, influencing both public perception and policy decisions.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>After seeing news reports about shark attacks, a person avoids swimming in the ocean, despite the extremely low probability of being attacked by a shark.</li>
      <li>An investor overestimates the risk of a stock market crash because they vividly remember the 2008 financial crisis, even though current conditions are significantly different.</li>
      <li>During a health scare, people rush to buy specific medications they remember from recent news stories, neglecting more effective treatments.</li>
      <li>After a high-profile crime, a community demands increased security measures, believing crime rates are rising when, in fact, they are declining.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This event happened recently, so it must be common."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Just because I can easily recall this event doesn’t mean it happens often. I should look at the actual data."</div>
    </div>

    <MenuBottomCD v-bind:current="'AvH'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
