<template>
  <div>
    <h1>Authority Bias</h1>

    <h3>You place undue trust in the opinions of an authority figure, regardless of the validity of the argument.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Authority Bias is a cognitive bias where people attribute greater accuracy or validity to the opinion of an authority figure, regardless of the actual evidence or logic behind their argument. This fallacy can lead to the acceptance of flawed reasoning or false information simply because it comes from someone perceived as an expert or leader.</p>
    <p>This bias is rooted in the tendency to defer to authority figures and to assume that their knowledge or experience grants them greater insight. While it is often reasonable to consider expert opinions, Authority Bias becomes problematic when it leads to the unquestioning acceptance of claims without independent evaluation.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Authority Bias can lead to the spread of misinformation, poor decision-making, and the perpetuation of flawed beliefs. In the medical field, for example, patients may follow a doctor's advice without considering alternative opinions or questioning the rationale behind the treatment. In the workplace, it can result in the adoption of ineffective strategies simply because they are endorsed by a senior leader.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias often arises from the social and cultural conditioning that teaches individuals to respect and trust authority figures. It may also stem from a lack of confidence in one's own knowledge or the belief that experts are always right. Additionally, the desire to align with authority figures can be motivated by the fear of negative consequences or the hope of gaining favor.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid Authority Bias, it is important to critically evaluate the arguments and evidence presented by authority figures, rather than accepting them at face value. This means seeking out additional sources of information, considering alternative perspectives, and being willing to question the validity of the claims made by those in positions of power. Developing a habit of independent thinking and analysis can help reduce the influence of this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Milgram (1963) on obedience to authority highlights the powerful influence that authority figures can have on individuals' behavior and decision-making. His experiments demonstrated that people are often willing to follow orders from authority figures, even when those orders conflict with their own moral values.</p>
    <p>A study by Asch (1951) on conformity further explores how social pressure and the desire to fit in can lead individuals to accept the opinions of authority figures, even when they contradict their own judgment. This research underscores the importance of independent thinking in resisting Authority Bias.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>"A patient unquestioningly follows a doctor's advice, even when there are alternative treatments available."</li>
      <li>"An employee implements a flawed strategy because it was endorsed by a senior executive, despite having reservations."</li>
      <li>"A consumer purchases a product simply because a celebrity or expert endorsed it, without researching its actual quality."</li>
      <li>"In a debate, one side wins simply because they have a well-known figure supporting their argument, regardless of the actual merits of the case."</li>
      <li>"A student accepts their professor's interpretation of a text without considering alternative viewpoints."</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This must be right because the expert said so."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should evaluate the evidence and reasoning behind this claim, even if it comes from an expert."</div>
    </div>

    <MenuBottomLF v-bind:current="'AB'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
