<template>
  <div>
    <h1>Curse of Knowledge</h1>

    <h3>Assuming others have the same knowledge as you.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Curse of Knowledge is a cognitive bias where more knowledgeable individuals struggle to understand that others may not have the same knowledge or expertise. This bias can lead to communication issues, where complex information is presented in a way that is difficult for others to understand because the speaker assumes that the audience has the same background knowledge.</p>
    <p>When someone is affected by the Curse of Knowledge, they may inadvertently use jargon, skip over important explanations, or fail to clarify concepts, leading to confusion or misunderstanding. Recognizing this bias is crucial for effective communication, especially in teaching, leadership, and technical fields where clear and accessible information is essential.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Curse of Knowledge can have significant consequences in various contexts. In education, teachers who assume their students understand foundational concepts may move too quickly through material, leaving some students behind. In the workplace, experts may struggle to convey complex ideas to colleagues, leading to miscommunication and errors. This bias can also affect personal relationships, where one person may assume the other understands their perspective or knowledge, leading to misunderstandings.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Curse of Knowledge arises from the difficulty in imagining what it is like not to know something once you have learned it. This bias is reinforced by familiarity and expertise, which can make it challenging to recognize gaps in others' understanding. Additionally, the brain tends to filter out information that seems obvious or trivial, leading to assumptions that others also find it obvious.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To prevent the Curse of Knowledge from hindering communication, it's important to adopt the perspective of the audience and actively consider their level of understanding. Breaking down complex information into simpler terms, avoiding jargon, and checking for comprehension can help bridge the knowledge gap. Additionally, asking for feedback and encouraging questions can ensure that the information is being communicated effectively.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research on the Curse of Knowledge has shown that experts often overestimate how much others know, leading to communication challenges. A study by Camerer et al. (1989) found that individuals with specialized knowledge are less able to predict others' understanding, often assuming that others know more than they do. Another study by Birch and Bloom (2007) demonstrated that this bias can affect not only communication but also decision-making, as experts may fail to consider the knowledge limitations of others when making decisions.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A software developer explains a new feature to a non-technical team using technical jargon, assuming they understand the terms, leading to confusion.</li>
      <li>A teacher skips over basic concepts in a lesson, believing that students already grasp them, resulting in some students struggling to keep up.</li>
      <li>A manager presents a strategy to their team without fully explaining the rationale behind it, assuming that everyone understands the context and reasoning.</li>
      <li>A parent assumes their child knows how to handle a situation because it seems obvious to them, without providing guidance or support.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"Everyone understands what I’m talking about; there's no need to explain further."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should make sure to explain this clearly and check for understanding, even if it seems obvious to me."</div>
    </div>

    <MenuBottomCD v-bind:current="'CK'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
