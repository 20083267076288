<template>
  <div>
    <h1>Affect Heuristic</h1>

    <h3>You make decisions based on your emotions rather than objective information.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Affect Heuristic is a cognitive bias where individuals make decisions based on their emotions and feelings, rather than on objective information and rational analysis. This bias occurs when people rely on their immediate emotional response to a situation, rather than considering the facts or evaluating the potential consequences of their decisions.</p>
    <p>This heuristic is common in many areas of life, including financial decisions, health choices, and social interactions. By relying on emotions rather than logic, individuals may make impulsive decisions that are not in their best interest. The Affect Heuristic can also lead to irrational fears or desires, as people may overestimate the risks or benefits of certain actions based on their emotional reactions.</p>
    <p>Understanding the Affect Heuristic is crucial for making more rational decisions. By recognizing when emotions are influencing their thinking, individuals can take a step back, consider the facts, and make more informed choices.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Affect Heuristic can have significant consequences in both personal and professional settings. In finance, individuals may make poor investment decisions based on fear or greed, rather than on careful analysis of market conditions. In healthcare, people may avoid necessary medical procedures due to fear of pain or discomfort, even when the benefits outweigh the risks.</p>
    <p>This bias can also affect relationships, where individuals may make hasty decisions based on anger, jealousy, or love, rather than considering the long-term impact of their actions. In politics, the Affect Heuristic can lead to polarized opinions, as people may support or oppose policies based on their emotional reactions, rather than on a rational evaluation of the evidence.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Affect Heuristic is driven by the human tendency to rely on emotions as a quick and easy way to make decisions. Emotions provide immediate feedback that can simplify complex situations, making it easier to choose a course of action. However, this reliance on emotions can lead to biased and irrational decisions, particularly when the emotional response is not aligned with the facts.</p>
    <p>This bias is also influenced by the availability heuristic, where individuals recall emotional experiences more easily than neutral ones. This can lead to an overestimation of the likelihood of certain events, based on their emotional impact. Additionally, social and cultural factors can reinforce the Affect Heuristic, where certain emotions are valued or encouraged in decision-making, even when they are not rational.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract the Affect Heuristic, it is important to practice mindfulness and self-awareness in decision-making. This involves recognizing when emotions are influencing your thinking and taking a step back to evaluate the facts. Setting aside time for reflection and seeking input from others can also help in making more rational decisions.</p>
    <p>Another strategy is to develop a habit of asking critical questions, such as "What are the facts?" and "What are the potential consequences?" before making a decision. By consciously considering the objective information, individuals can reduce the impact of emotions on their choices and make more informed decisions.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Slovic, Finucane, Peters, and MacGregor (2002) explored the role of the Affect Heuristic in decision-making, showing that individuals often rely on their emotions when evaluating risks and benefits. Their studies found that people tend to overestimate the risks of activities that evoke strong negative emotions, such as nuclear power, while underestimating the risks of activities that evoke positive emotions, such as driving.</p>
    <p>Further research by Lerner, Small, and Loewenstein (2004) examined how specific emotions, such as fear and anger, influence decision-making. Their findings suggest that different emotions can lead to different biases, with fear leading to risk aversion and anger leading to risk-seeking behavior. This research highlights the complex relationship between emotions and decision-making.</p>
    <p>A study by Finucane, Alhakami, Slovic, and Johnson (2000) investigated how the Affect Heuristic influences perceptions of risk and benefit, finding that individuals often perceive high risks and low benefits for activities they feel negatively about, and low risks and high benefits for activities they feel positively about. The research suggests that emotions can significantly distort judgment and decision-making.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person avoids investing in the stock market because they are afraid of losing money, even though historical data shows that long-term investments are likely to yield positive returns.</li>
      <li>An individual decides to buy a luxury car because they feel it will make them happy, without considering the long-term financial impact of the purchase.</li>
      <li>A patient refuses a recommended medical procedure because they fear the pain, even though the procedure is necessary for their health.</li>
      <li>Someone votes against a political candidate because they dislike their personality, without evaluating their policies or qualifications.</li>
      <li>A person overeats when they are stressed, using food as a way to cope with their emotions, rather than addressing the root cause of their stress.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’m too scared to take this risk; it’s not worth it."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should evaluate the risks and benefits objectively before deciding."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I feel so strongly about this, it must be the right decision."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I need to consider the facts and potential consequences before making a decision."</div>
    </div>

    <MenuBottomCD v-bind:current="'AH'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
