<template>
  <div>
    <h1>
      Magnification or Minimization
    </h1>

    <h3>You blow things way out of proportion or shrink them.</h3>


    <h4>Explained</h4>
    
    <p>Magnification or Minimization is a type of cognitive distortion that involves exaggerating or minimizing the importance or impact of certain events or situations. This type of thinking can lead to a distorted view of reality and can have negative impacts on a person's emotional well-being and overall functioning.</p>

    <p>Magnification, also known as "catastrophizing," involves blowing things out of proportion and exaggerating the negative aspects of a situation. For example, a person who is struggling with magnification might believe that a small mistake they made at work is a catastrophic event that will ruin their career. This type of thinking can lead to unnecessary anxiety and stress.</p>

    <p>Minimization, on the other hand, involves downplaying the significance or importance of events or situations. A person who is struggling with minimization might brush off their own accomplishments or the positive aspects of a situation, leading to low self-esteem and a distorted view of their own abilities.</p>

    <p>Both magnification and minimization can be damaging to a person's mental health and well-being, as they can lead to negative emotions and unbalanced thinking. It is important to recognize when these cognitive distortions are occurring and work to challenge and reframe them in order to maintain a more accurate and healthy perspective.</p>

    <p>Here are a few examples of magnification and minimization:</p>

    <p>
      Magnification:
      <br>
      <ul>
        <li>Believing that a small mistake at work is a catastrophic event that will ruin your career.</li>
        <li>Thinking that a minor disagreement with a friend is a sign that the entire friendship is doomed.</li>
      </ul>
    </p>

    <p>
      Minimization:
      <br>
      <ul>
        <li>Dismissing your own accomplishments or strengths as unimportant or insignificant.</li>
        <li>Believing that a major accomplishment or success is just luck or coincidence.</li>
      </ul>
    </p>

    <p>By recognizing and challenging these types of cognitive distortions, you can work towards a more balanced and healthy perspective on life. This can lead to improved emotional well-being and overall functioning.</p>

    <h4>Example</h4>

    <p>Example thought with magnification distortion: "I can't believe I forgot to turn in that report on time. My boss is going to be so mad and I'll probably get fired. My whole career is ruined."</p>

    <p>Rewritten thought: "I made a mistake by forgetting to turn in that report on time. My boss might be disappointed, but it's not the end of my career. I can learn from this experience and make sure to prioritize my tasks better in the future."</p>

    <p>Example thought with minimization distortion: "I got a promotion at work, but it's probably just because the boss felt sorry for me. I'm not really that good at my job."</p>

    <p>Rewritten thought: "I was able to achieve a promotion at work, which is a significant accomplishment. It's not just because the boss felt sorry for me, but rather because I have proven myself to be capable and competent in my job. I should be proud of my success and recognize my own strengths and abilities."</p>

    <CBTAppText v-bind:current="'MoM'"></CBTAppText>

    <menu-bottom v-bind:current="'MoM'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>