<template>
  <div>
    <h1>
      Other Blame
    </h1>
    
    <h3>You blame others and overlook ways you contributed to the problem.</h3>

    <h4>Explained</h4>

    <p>The Other Blame cognitive distortion, also known as externalizing blame or externalizing responsibility, is a thinking error that involves attributing the cause of a problem or negative outcome to external factors or other people, rather than taking responsibility for one's own actions or acknowledging the role that one may have played in the situation. This type of thinking can lead to negative emotions, strained relationships, and a lack of personal growth and development.</p>

    <p>
    Examples of Other Blame thinking include:
    <br>
    <ul>
      <li>
        "It's not my fault that I failed the test. The teacher didn't explain the material well enough."
      </li>
      <li>
        "I didn't get the promotion because my boss doesn't like me, not because I didn't prepare for the interview or put in enough effort."
      </li>
      <li>
        "My partner is always making me angry. It's their fault I get so upset all the time."
      </li>
    </ul>
    </p>

    <p>Other Blame thinking can be harmful because it prevents individuals from taking responsibility for their actions and recognizing the role that they may have played in a situation. It can also lead to resentment and strained relationships with others, as individuals may feel like they are being unfairly blamed for problems or negative outcomes.</p>

    <p>The Other Blame cognitive distortion can also be seen on a global scale, when nations blame each other for conflicts or problems, rather than trying to find a solution through diplomacy or negotiation. This can lead to endless wars and conflicts, with each side feeling completely justified in their actions.</p>

    <p>In order to address Other Blame thinking, it can be helpful to practice self-reflection and consider the role that one's own actions may have played in a situation. It can also be helpful to engage in problem-solving with others, rather than simply blaming them for problems or negative outcomes. Seeking support from a therapist or counselor can also be beneficial in addressing and changing negative thought patterns.</p>

    <p>Ultimately, taking responsibility for one's own actions and recognizing the role that one plays in a situation can lead to personal growth and development, and can improve relationships with others. By addressing and changing Other Blame thinking, individuals can learn to take a more proactive approach to problem-solving and move forward in a positive direction.</p>


    <h4>Example</h4>

    <p>Example of a thought with the Other Blame error: "I'm late because the bus was late, not because I didn't give myself enough time to get to the bus stop."</p>

    <p>Reframed thought: "I'm late, and while it's true that the bus was late, it's also possible that I didn't give myself enough time to get to the bus stop. It's important for me to reflect on my own actions and consider what I could have done differently, such as leaving earlier or allowing for extra time for unexpected delays. This will help me to learn and grow from the experience, rather than simply blaming external factors."</p>

    <CBTAppText v-bind:current="'OB'"></CBTAppText>

    <menu-bottom v-bind:current="'OB'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>