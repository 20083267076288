<template>
    <div>
      <h1>Serial Position Effect</h1>
  
      <h3>You are more likely to remember the first and last items in a series.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Serial Position Effect is a cognitive bias where people tend to remember the first and last items in a series more easily than those in the middle. This effect is divided into two components: the Primacy Effect, where the first items are recalled more readily, and the Recency Effect, where the last items are more memorable.</p>
      <p>This bias can affect a wide range of activities, from learning and studying to making decisions based on lists or sequences.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Serial Position Effect can influence decision-making, marketing, and communication strategies. For example, in advertising, the first and last messages are often given more emphasis, as they are more likely to be remembered by the audience.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This effect is typically caused by the way information is encoded in memory. The first items are likely to be transferred to long-term memory (Primacy Effect), while the last items remain in short-term memory (Recency Effect).</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To mitigate the Serial Position Effect, try to review and reinforce the middle items in a series more frequently. Spacing out learning and revisiting less memorable items can also help improve overall recall.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Hermann Ebbinghaus first identified the Serial Position Effect through experiments involving memory and recall. Subsequent studies have confirmed that people are more likely to remember the first and last items in a list, highlighting the effect's robustness across various contexts.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"Students often remember the first and last points of a lecture better than the information presented in the middle."</li>
        <li>"Job candidates are more likely to remember the first and last questions in an interview."</li>
        <li>"Consumers recall the first and last items on a shopping list more easily than the ones in the middle."</li>
        <li>"During a presentation, the introduction and conclusion are more memorable than the content in between."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I remember the beginning and end, so I must understand the whole concept."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should review the middle points to ensure I have a complete understanding."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'SPE'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  