<template>
  <div>
    <h1>Denomination Effect</h1>

    <h3>You are less likely to spend larger bills than their equivalent in smaller denominations.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Denomination Effect is a cognitive bias where individuals are less likely to spend money when it is in larger denominations (e.g., a $100 bill) than when it is in smaller denominations (e.g., ten $10 bills), even though the total value is the same. This bias influences spending behavior by making larger denominations feel more substantial and less expendable.</p>
    <p>This effect can lead to different spending patterns depending on the form in which money is held. People may be more cautious and reluctant to part with larger bills, whereas smaller denominations may be spent more freely. The Denomination Effect is an example of how the form of money, rather than its actual value, can influence decision-making and financial behavior.</p>
    <p>Understanding the Denomination Effect can help individuals become more aware of their spending habits and make more rational financial decisions. By recognizing how the physical form of money affects their perception, people can better manage their finances and avoid unnecessary spending.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Denomination Effect can have significant implications for personal finance and consumer behavior. For instance, individuals may save more money by holding larger bills, as they are less likely to break them for small purchases. On the other hand, retailers may take advantage of this bias by providing change in smaller denominations, encouraging customers to spend more freely.</p>
    <p>This effect also influences charitable giving, as people may be less likely to donate larger bills compared to smaller ones. Additionally, it can affect budgeting and saving strategies, where the denomination of money held can influence how much is spent versus saved. Understanding this bias can help individuals make more intentional financial decisions and avoid the pitfalls of impulsive spending.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Denomination Effect is driven by the psychological impact of loss aversion, where people are more sensitive to losses than gains. Larger bills are perceived as more valuable and substantial, making individuals more reluctant to part with them. Additionally, the tangible nature of larger denominations can create a stronger emotional attachment, leading to more cautious spending behavior.</p>
    <p>This bias is also influenced by the mental accounting framework, where individuals categorize money based on its form and use different mental accounts for different denominations. This can lead to irrational spending patterns, where smaller bills are viewed as more expendable and are spent more readily, while larger bills are reserved for special or significant purchases.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To mitigate the Denomination Effect, individuals can practice more mindful spending by focusing on the total value of their money, rather than its physical form. This involves recognizing that the denomination of money should not influence spending decisions and making a conscious effort to base financial choices on actual needs and priorities.</p>
    <p>Another strategy is to break larger bills into smaller denominations when planning to make specific purchases, ensuring that spending is intentional and aligned with financial goals. Additionally, keeping track of spending habits and setting a budget can help individuals avoid impulsive purchases influenced by the Denomination Effect.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Raghubir and Srivastava (2009) provided early evidence of the Denomination Effect, showing that individuals are more likely to spend smaller denominations than larger ones. Their studies found that people are more hesitant to break larger bills, even when they intend to make a purchase, highlighting the psychological impact of this bias.</p>
    <p>Further research by Mishra, Mishra, and Nayakankuppam (2006) explored how the Denomination Effect influences charitable giving, finding that people are more likely to donate smaller bills than larger ones, even when the total amount is the same. This research suggests that the form of money can significantly impact financial behavior, including generosity.</p>
    <p>A study by LeBoeuf and Shafir (2005) examined how the Denomination Effect influences consumer spending, showing that individuals are more likely to make impulsive purchases with smaller denominations. Their findings underscore the importance of understanding this bias in the context of personal finance and consumer behavior.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person is less likely to spend a $50 bill compared to spending five $10 bills on small purchases, even though the total value is the same.</li>
      <li>A shopper feels more reluctant to break a $100 bill for a $5 purchase than to use a $10 bill for the same purchase.</li>
      <li>An individual is more likely to donate a $20 bill to charity if they have two $10 bills, rather than a single $20 bill.</li>
      <li>Someone prefers to hold onto a $50 bill for a significant purchase rather than using it for everyday expenses, even though they have smaller bills available.</li>
      <li>A person is more likely to spend a $5 bill on a coffee than to break a $50 bill for the same purchase, even though they have the larger bill available.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I should save this $100 bill for something important and use smaller bills for everyday expenses."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"The value is the same, so I should make spending decisions based on what I need, not the denomination of the bill."</div>
    </div>

    <MenuBottomCD v-bind:current="'DE'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
