<template>
  <div class="header">

    <div style="max-width:640px;margin:0 auto;">
        
      <!-- Left side -->
      <div style="float:left;">
        <!-- #375471 -->
        <div @click="toTop" class="logo">
          <router-link :to="homePath">
            <div class="logo-icon"></div>
            <div class="logo-text heading">Thinking Bugs</div>
          </router-link>
        </div>
      </div>

      <!-- Right side -->
      <div style="float:right;">
        <div @click="enterCBT()" class="app-button heading noselect" style="width:75px;font-weight:bold;float:right;" title="Cognitive Behavioral Therapy App">
          CBT App
        </div>

        <div style="height:25px;width:25px;float:right;padding-top:24px;margin-right:18px;"><router-link to="/about" style="">
          <svg version="1.1" height="18" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 19.7 36.6" style="enable-background:new 0 0 19.7 36.6;margin-top:-1px;" xml:space="preserve">
              <path class="white-black" style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.6131;" d="
                M9.9,13v19.3 M2,34.6h15.8 M5,13h4.9"/>
              <path class="white-black-fill" style="fill-rule:evenodd;clip-rule:evenodd;" d="M9.9,5.5c1.5,0,2.8-1.2,2.8-2.8S11.4,0,9.9,0C8.3,0,7.1,1.2,7.1,2.8
                S8.3,5.5,9.9,5.5L9.9,5.5z"/>
            </svg>
        </router-link></div>

        <div class="theme-switch">
          <div @click="$parent.themeSwitch" id="theme-switch-icon" class="noselect" style="width:18px;" title="Switch theme">
            <svg id="svg-contrast" class="theme-switch-btn" viewBox="0 0 893 893"><title>Switch theme</title><path d="M130.78,130.78A445.22,445.22,0,1,0,272.69,35.1,444.95,444.95,0,0,0,130.78,130.78ZM446.5,818A371.5,371.5,0,0,1,183.81,183.81,369.07,369.07,0,0,1,446.5,75V818Z" style="fill-rule:evenodd"/></svg>
          </div>
        </div>
      </div>

    </div>
    
  </div>

</template>

<script>

  export default {
    data() {
      return {
        homePath: '/',
        lastBug: '/',
        buttonContent: 'App',
        // themeLight: true
      }
    },
    methods: {
    
      cardCreate: function() {
        this.$emit('header-new');
      },

      toTop: function() {
        window.scrollTo(0,0);
        if (this.$parent.menuOpen) {
          this.$parent.menuOpen = false;
        }
      },
      menuClick: function () {

        window.scrollTo(0, 0);
        if (this.$parent.menuOpen) {
          this.$router.push('cbt-app');
        }
        else {
          this.$router.push(this.homePath);
        }
      },

      enterCBT: function() {
        this.lastBug = this.$route.path;
        this.$router.push('/cbt-app');
      },

      exitCBT: function() {
        this.$router.push(this.lastBug);
      },
      

    },

    mounted: function() {

      if (this.$route.name == 'CbtApp') {
        this.$parent.menuOpen = true;
      }

    },

    updated: function() {
      if (this.$parent.menuOpen) {
        this.buttonContent = 'x';
      }
      else {
        this.buttonContent = 'App';
      }
    }
  }

</script>

<style>
  .theme-switch {
    float:right;
    cursor:pointer;
    padding-top:24px;
    padding-right:34px;
  }

  @media screen and (max-width: 400px) {
    .theme-switch {
      padding-right: 25px;
    }
  }
</style>