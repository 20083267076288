<template>
  <div v-cloak>

    <template>

      <component v-bind:is="autoRoute"></component>

      <div style="height:50px;"></div>

    </template>

  </div>
</template>

<script>

import About from '../content/about';

import AllOrNothingThinking from '../content/all-or-nothing-thinking.vue';
import DiscountingPositives from '../content/discounting-positives';
import EmotionalReasoning from '../content/emotional-reasoning';
import FortuneTelling from '../content/fortune-telling';
import Labeling from '../content/labeling';
import MagnificationOrMinimization from '../content/magnification-or-minimization';
import Personalization from '../content/personalization';
import MentalFilter from '../content/mental-filter';
import MindReading from '../content/mind-reading';
import OtherBlame from '../content/other-blame';
import Overgeneralization from '../content/overgeneralization';
import SelfBlame from '../content/self-blame';
import ShouldStatements from '../content/should-statements';

import Anchoring from '../content/bt/anchoring';
import ConfirmationBias from '../content/cd/confirmation-bias';
import AvailabilityHeuristic from '../content/cd/availability-heuristic';
import SunkCostFallacy from '../content/cd/sunk-cost-fallacy';
import HindsightBias from '../content/cd/hindsight-bias';
import MotivatedReasoning from '../content/je/motivated-reasoning';
import WishfulThinking from '../content/ep/wishful-thinking';
import OverconfidenceBias from '../content/je/overconfidence-bias';
import IllusoryCorrelation from '../content/cd/illusory-correlation';
import BeliefBias from '../content/cd/belief-bias';
import CognitiveDissonance from '../content/cd/cognitive-dissonance';
import SystemJustification from '../content/cd/system-justification';
import FalseMemory from '../content/cd/false-memory';
import PseudocertaintyEffect from '../content/cd/pseudocertainty-effect';
import HyperbolicDiscounting from '../content/cd/hyperbolic-discounting';
import CognitiveMiser from '../content/cd/cognitive-miser';
import LawOfTheInstrument from '../content/cd/law-of-the-instrument';
import OverconfidenceEffect from '../content/cd/overconfidence-effect';
import PessimismBias from '../content/cd/pessimism-bias';
import OptimismBias from '../content/cd/optimism-bias';
import CurseOfKnowledge from '../content/cd/curse-of-knowledge';
import DunningKrugerEffect from '../content/cd/dunning-kruger-effect';
import BiasBlindSpot from '../content/cd/bias-blind-spot';
import IllusionOfControl from '../content/cd/illusion-of-control';
import SelfHandicapping from '../content/cd/self-handicapping';
import JustWorldHypothesis from '../content/cd/just-world-hypothesis';
import MereOwnershipEffect from '../content/cd/mere-ownership-effect';
import EgocentricBias from '../content/cd/egocentric-bias';
import HostileAttributionBias from '../content/cd/hostile-attribution-bias';
import ActorObserverBias from '../content/cd/actor-observer-bias';
import ReactiveDevaluation from '../content/cd/reactive-devaluation';
import FalseConsensusEffect from '../content/cd/false-consensus-effect';
import BaseRateFallacy from '../content/cd/base-rate-fallacy';
import DenominationEffect from '../content/cd/denomination-effect';
import FocusingEffect from '../content/cd/focusing-effect';
import SelfEnhancementBias from '../content/cd/self-enhancement-bias';
import System1AndSystem2Thinking from '../content/rb/system-1-and-system-2-thinking';
import AffectHeuristic from '../content/cd/affect-heuristic';

import FalseCause from '../content/lf/false-cause';
import StrawMan from '../content/lf/straw-man';
import SlipperySlope from '../content/lf/slippery-slope';
import FalseDichotomy from '../content/lf/false-dichotomy';
import CircularReasoning from '../content/lf/circular-reasoning';
import AppealToEmotion from '../content/lf/appeal-to-emotion';
import FramingEffect from '../content/lf/framing-effect';
import HaloEffect from '../content/lf/halo-effect';
import AuthorityBias from '../content/lf/authority-bias';


import GamblersFallacy from '../content/je/gamblers-fallacy';
import BaseRateNeglect from '../content/je/base-rate-neglect';
import OutcomeBias from '../content/je/outcome-bias';
import MisinformationEffect from '../content/je/misinformation-effect';
import SerialPositionEffect from '../content/je/serial-position-effect';
import PrimacyEffect from '../content/je/primacy-effect';
import RecencyEffect from '../content/je/recency-effect';
import HotHandFallacy from '../content/je/hot-hand-fallacy';
import PlanningFallacy from '../content/je/planning-fallacy';
import ZeroRiskBias from '../content/je/zero-risk-bias';
import NeglectOfProbability from '../content/je/neglect-of-probability';
import SurvivorshipBias from '../content/je/survivorship-bias';

import Groupthink from '../content/stt/groupthink';
import BandwagonEffect from '../content/stt/bandwagon-effect';
import IngroupBias from '../content/stt/ingroup-bias';
import OutgroupHomogeneity from '../content/stt/outgroup-homogeneity';
import Stereotyping from '../content/stt/stereotyping';
import StereotypeThreat from '../content/stt/stereotype-threat';
import MoralCredentialEffect from '../content/stt/moral-credential-effect';


import EgoBias from '../content/ep/ego-bias';
import SelfServingBias from '../content/ep/self-serving-bias';
import VisceralBias from '../content/ep/visceral-bias';

import FundamentalAttributionError from '../content/pb/fundamental-attribution-error.vue';
import ContrastEffect from '../content/pb/contrast-effect.vue';
import ReactanceBias from '../content/pb/reactance-bias.vue';
import RepresentativenessHeuristic from '../content/pb/representativeness-heuristic.vue';
import MereExposureEffect from '../content/pb/mere-exposure-effect.vue';

import OrderEffects from '../content/bt/order-effects.vue';
import VerticalLineFailure from '../content/bt/vertical-line-failure.vue';
import SearchSatisficing from '../content/bt/search-satisficing.vue';
import DiagnosisMomentum from '../content/bt/diagnosis-momentum.vue';
import ZebraRetreat from '../content/bt/zebra-retreat.vue';

import CongruenceBias from '../content/rb/congruence-bias.vue';
import BlindSpotBias from '../content/rb/blind-spot-bias.vue';
import SemmelweisReflex from '../content/rb/semmelweis-reflex.vue';

import SourceConfusion from '../content/pb/source-confusion.vue';
// import MereOwnershipEffect from '../content/md/mere-ownership-effect.vue';

import ProInnovationBias from '../content/je/pro-innovation-bias.vue';
// import System1andSystem2Thinking from '../content/dmf/system-1-and-system-2-thinking.vue';

export default {
  components: {
    ProInnovationBias,
    // System1andSystem2Thinking,

    SourceConfusion,
    // MereOwnershipEffect,

    CongruenceBias,
    BlindSpotBias,
    SemmelweisReflex,

    
    OrderEffects,
    VerticalLineFailure,
    SearchSatisficing,
    DiagnosisMomentum,
    ZebraRetreat,

    
    FundamentalAttributionError,
    ContrastEffect,
    ReactanceBias,
    RepresentativenessHeuristic,
    MereExposureEffect,

    EgoBias,
    SelfServingBias,
    VisceralBias,
    
    Groupthink,
    BandwagonEffect,
    IngroupBias,
    OutgroupHomogeneity,
    Stereotyping,
    StereotypeThreat,
    MoralCredentialEffect,
    
    GamblersFallacy,
    BaseRateNeglect,
    OutcomeBias,
    MisinformationEffect,
    SerialPositionEffect,
    PrimacyEffect,
    RecencyEffect,
    HotHandFallacy,
    PlanningFallacy,
    ZeroRiskBias,
    NeglectOfProbability,
    SurvivorshipBias,

    FalseCause,
    StrawMan,
    SlipperySlope,
    FalseDichotomy,
    CircularReasoning,
    AppealToEmotion,
    FramingEffect,
    HaloEffect,
    AuthorityBias,

    Anchoring,
    ConfirmationBias,
    AvailabilityHeuristic,
    SunkCostFallacy,
    HindsightBias,
    MotivatedReasoning,
    WishfulThinking,
    OverconfidenceBias,
    IllusoryCorrelation,
    BeliefBias,
    CognitiveDissonance,
    SystemJustification,
    FalseMemory,
    PseudocertaintyEffect,
    HyperbolicDiscounting,
    CognitiveMiser,
    LawOfTheInstrument,
    OverconfidenceEffect,
    PessimismBias,
    OptimismBias,
    CurseOfKnowledge,
    DunningKrugerEffect,
    BiasBlindSpot,
    IllusionOfControl,
    SelfHandicapping,
    JustWorldHypothesis,
    MereOwnershipEffect,
    EgocentricBias,
    HostileAttributionBias,
    ActorObserverBias,
    ReactiveDevaluation,
    FalseConsensusEffect,
    BaseRateFallacy,
    DenominationEffect,
    FocusingEffect,
    SelfEnhancementBias,
    System1AndSystem2Thinking,
    AffectHeuristic,

    AllOrNothingThinking,
    DiscountingPositives,
    EmotionalReasoning,
    FortuneTelling,
    // JumpingToConclusions,
    Labeling,
    MagnificationOrMinimization,
    Personalization,
    MentalFilter,
    MindReading,
    OtherBlame,
    Overgeneralization,
    SelfBlame,
    ShouldStatements,
    About
  },
  
  name: 'Page',

  data() {
    return {
      autoRoute: this.$route.path.substr(1),
      content: '',
    }
  },

  watch:{
    '$route' (to, from){
      if (to != from) {
        this.autoRoute = this.$route.path.substr(1)
      }
    }
  },

  mounted() {
    if (this.$route.path.substr(1) == 'cbt-app') {
      this.$parent.menuOpen = true;
    }
  },

}

</script>