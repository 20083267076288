<template>
  <div>
    <h1>Stereotype Threat</h1>

    <h3>You feel at risk of conforming to stereotypes about your social group.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Stereotype Threat refers to the anxiety or concern experienced by individuals who fear being judged or treated unfairly based on stereotypes about their social group. This fear can negatively affect performance and behavior, often leading to a self-fulfilling prophecy where the individual unconsciously conforms to the stereotype.</p>
    <p>This phenomenon is particularly prevalent in environments where stereotypes about intellectual abilities or competencies are salient, such as in academic or professional settings. The pressure to avoid confirming a negative stereotype can cause stress and hinder an individual's ability to perform to their full potential.</p>
    <p>Stereotype Threat not only affects individuals but can also perpetuate systemic inequalities by reinforcing the very stereotypes that individuals are trying to avoid. It can contribute to a cycle of underachievement and marginalization in certain groups.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The impact of Stereotype Threat can be profound, leading to decreased academic and professional performance, reduced confidence, and increased stress levels. Over time, repeated experiences of Stereotype Threat can lead to disengagement from activities or domains where the stereotype is relevant, further entrenching inequalities.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Stereotype Threat is often triggered by the awareness of negative stereotypes about one's group, especially in situations where those stereotypes are relevant. Social and environmental cues, such as the demographics of a classroom or workplace, can also exacerbate the threat by making individuals feel more isolated or scrutinized.</p>
    <p>The internalization of stereotypes, even if individuals do not believe them, can also contribute to Stereotype Threat. This internalization creates a psychological burden that distracts from the task at hand and increases the likelihood of underperformance.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>Addressing Stereotype Threat involves creating environments that reduce the salience of stereotypes and promote a sense of belonging for all individuals. This can be achieved through diversity and inclusion initiatives, mentoring programs, and efforts to de-emphasize stereotypes in evaluations and assessments.</p>
    <p>Individuals can also develop strategies to cope with Stereotype Threat, such as affirming their self-worth, seeking out supportive peers, and focusing on personal goals rather than external judgments. Educational and professional settings can support these efforts by fostering an inclusive atmosphere that values diversity and individual achievement.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Claude Steele and Joshua Aronson (1995) has been foundational in understanding Stereotype Threat. Their studies demonstrated that when African American students were reminded of their race before taking a standardized test, their performance was negatively impacted, illustrating the power of Stereotype Threat.</p>
    <p>Subsequent research has expanded on these findings, showing that Stereotype Threat affects a wide range of groups, including women in STEM fields, older adults in cognitive tasks, and even athletes in performance settings. These studies highlight the pervasive nature of Stereotype Threat and the importance of addressing it in various contexts.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A female student may perform worse on a math test if she is reminded of the stereotype that women are less proficient in math.</li>
      <li>An older adult might struggle with memory tasks if they are reminded of the stereotype that aging leads to cognitive decline.</li>
      <li>In a workplace setting, an employee from a minority group may underperform if they feel that their abilities are being judged through the lens of racial or ethnic stereotypes.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I have to prove that I don't fit the stereotype, or people will think less of me."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I will focus on doing my best and not worry about what others might think. My abilities are not defined by stereotypes."</div>
    </div>

    <MenuBottomSTT v-bind:current="'STH'"></MenuBottomSTT>
  </div>
</template>

<script>
  import MenuBottomSTT from '../../components/MenuBottomSTT.vue';

  export default {
    components: {
      MenuBottomSTT,
    }
  }
</script>
