<template>
  <div>
    <h1>
      Overgeneralization
    </h1>

    <h3>You view a single negative event as a never-ending pattern of defeat.</h3>

    <h4>
      <strong>Explained</strong>
    </h4>

    <p>Overgeneralization is a type of cognitive distortion, or thinking error, that occurs when we take a specific event or experience and apply it too broadly to other situations. This can lead to negative or faulty conclusions that are not supported by the evidence.</p>

    <p>For example, someone who has had a negative experience with one person of a particular race may overgeneralize and believe that all people of that race are untrustworthy or bad. Someone who has had a negative experience with one romantic partner may overgeneralize and believe that all relationships will inevitably end badly.</p>

    <p>Overgeneralization can lead to negative thoughts and emotions, and can also lead to negative behaviors such as avoiding certain situations or people based on false or overly broad beliefs.</p>

    <p>One way to address overgeneralization is to identify and challenge the thought. This can involve examining the evidence for and against the belief, and considering alternative explanations or perspectives. It can also be helpful to seek out new experiences that challenge or contradict the overgeneralized belief.</p>

    <p>Another approach is to practice mindfulness, which involves paying attention to the present moment in a non-judgmental way. This can help to identify and challenge negative thoughts and beliefs as they arise, and to recognize that our thoughts and beliefs do not always reflect reality.</p>

    <p>It can also be helpful to seek the support of a therapist or counselor, who can help to identify and address cognitive distortions and other negative thought patterns.</p>

    <p>Overall, it is important to recognize that overgeneralization is a common and normal human tendency, but it can have negative consequences if not addressed. By identifying and challenging overgeneralized beliefs, we can improve our mental health and well-being, and better navigate the complexities of the world around us.</p>

    <h4>
      Example
    </h4>

    <p>
      Example of overgeneralized thought:<br>
      "I always mess up everything I do. I'm such a failure."
    </p>

    <p>
      Reframed thought:<br>
      "I made a mistake on this project, but that doesn't mean I'm a failure overall. I have many other successes and accomplishments, and I can learn from this experience and do better in the future."
    </p>

    <p>In the overgeneralized thought, the person is taking one specific event (making a mistake on a project) and applying it too broadly to their entire identity and all of their actions. This can lead to negative self-esteem and self-doubt.</p>

    <p>The reframed thought acknowledges the mistake, but recognizes that it is just one event and does not define the person's overall worth or abilities. It also recognizes that mistakes can be opportunities for learning and growth.</p>

    <CBTAppText v-bind:current="'Ovg'"></CBTAppText>

    <menu-bottom v-bind:current="'Ovg'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>