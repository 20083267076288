<template>
    <div>
      <h1>Zebra Retreat</h1>
  
      <h3>You revert to considering more common diagnoses rather than rare ones, even when the rare diagnosis might be more accurate.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Zebra retreat refers to the tendency to dismiss rare or unusual diagnoses in favor of more common ones, even when the evidence strongly suggests the rare diagnosis is correct. The term "zebra" comes from the medical adage, "When you hear hoofbeats, think of horses, not zebras," meaning that common conditions are more likely than rare ones. However, this mindset can lead to overlooking critical diagnoses simply because they are less common.</p>
      <p>This bias is especially prevalent in fields where decision-makers are under pressure to make quick judgments, such as in emergency medicine or high-stakes business environments. The fear of being wrong or of making an unconventional choice can drive individuals back to more familiar, common diagnoses, even when those might not be the best fit for the situation.</p>
      <p>Overcoming zebra retreat requires a willingness to consider all possibilities, no matter how rare, and to make decisions based on evidence rather than the frequency of occurrence.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Zebra retreat can have serious consequences, particularly in fields where accurate diagnosis is crucial. In medicine, it can lead to misdiagnosis and inappropriate treatment, potentially causing harm to patients. In business, it can result in missed opportunities, as less conventional strategies that might be more effective are overlooked in favor of more familiar approaches.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The primary cause of zebra retreat is cognitive bias, where individuals are more comfortable sticking with familiar, common explanations rather than exploring less common ones. This is often reinforced by past experiences and the tendency to rely on heuristics—mental shortcuts that simplify decision-making. Additionally, the fear of being wrong or of making an unconventional choice can drive this bias.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To prevent zebra retreat, it’s important to cultivate a mindset that values evidence over familiarity. This involves being open to all possibilities, no matter how rare, and making decisions based on the strength of the evidence rather than the frequency of occurrence. Regularly challenging assumptions and seeking input from others can also help counteract this bias.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research in medical decision-making has highlighted the risks of zebra retreat. For example, a study by Groopman (2007) discussed how clinicians often default to more common diagnoses, even when rare conditions might better explain the symptoms. This research emphasizes the importance of maintaining diagnostic flexibility and being willing to consider less common diagnoses when the evidence supports them.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A doctor diagnoses a patient with a common cold, overlooking the possibility of a rare but serious illness that presents similar symptoms.</li>
        <li>A business leader sticks to a conventional marketing strategy, ignoring an innovative approach that could capture a niche market more effectively.</li>
        <li>An investor favors well-known, established companies over lesser-known startups, missing out on potential high returns from emerging industries.</li>
        <li>A teacher assumes a student's struggle with math is due to a common learning gap, rather than considering a rare learning disorder.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This condition is rare, so it’s probably not the cause of the symptoms."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Even though this condition is rare, it’s worth considering if the symptoms match."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I’ll go with the more common explanation to be safe."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should consider all possibilities, common or rare, before making a decision."</div>
      </div>
  
      <MenuBottomBT v-bind:current="'ZR'"></MenuBottomBT>
    </div>
  </template>
  
  <script>
    import MenuBottomBT from '../../components/MenuBottomBT.vue';
  
    export default {
      components: {
        MenuBottomBT,
      }
    }
  </script>
  