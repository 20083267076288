<template>
  <div>
    <h1>Base Rate Fallacy</h1>

    <h3>You ignore general statistical information in favor of specific, anecdotal information.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Base Rate Fallacy, also known as Base Rate Neglect, is a cognitive bias where individuals ignore general statistical information (the base rate) and focus instead on specific, anecdotal information. This bias leads people to make judgments or decisions based on vivid or memorable examples, rather than considering the broader statistical context.</p>
    <p>This fallacy is common in everyday reasoning, particularly when people are confronted with specific cases that seem more representative or emotionally compelling. By neglecting the base rate, individuals may arrive at conclusions that are not supported by the overall data, leading to errors in judgment and decision-making.</p>
    <p>Understanding the Base Rate Fallacy is crucial for improving decision-making accuracy. By considering both the specific details and the broader statistical context, individuals can make more informed and rational decisions.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Base Rate Fallacy can have significant consequences in various fields, including medicine, law, and finance. In healthcare, doctors may overestimate the likelihood of a rare disease based on a patient’s symptoms, ignoring the base rate of the disease’s prevalence. In legal settings, jurors may be swayed by compelling evidence from a specific case, even if the statistical likelihood of the defendant’s guilt is low.</p>
    <p>In financial markets, investors may make decisions based on the performance of a few high-profile stocks, neglecting the overall trends in the market. This can lead to poor investment choices and financial losses. The Base Rate Fallacy also plays a role in everyday decisions, such as when individuals make choices based on anecdotes or personal experiences, rather than on statistical evidence.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Base Rate Fallacy is driven by the human tendency to rely on vivid, specific examples rather than abstract statistical information. This is partly due to the availability heuristic, where individuals are more likely to recall and be influenced by information that is easily accessible or emotionally charged. Additionally, people often struggle to understand and apply statistical concepts, leading them to focus on specific cases rather than the broader context.</p>
    <p>Another contributing factor is the representativeness heuristic, where individuals judge the probability of an event based on how similar it is to a known category or stereotype, rather than on the actual statistical likelihood. This can lead to overconfidence in specific cases and underestimation of the base rate.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract the Base Rate Fallacy, it is important to always consider the base rate when making decisions or judgments. This involves taking the time to gather and analyze statistical information, rather than relying solely on specific examples or anecdotes. In situations where base rate information is available, it should be given appropriate weight in the decision-making process.</p>
    <p>Education and training in statistical reasoning can also help individuals become more aware of the Base Rate Fallacy and how to avoid it. By developing a habit of questioning the representativeness of specific cases and considering the broader context, individuals can make more accurate and informed decisions.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Kahneman and Tversky (1973) first highlighted the Base Rate Fallacy, showing how individuals often ignore statistical information in favor of specific details. Their studies demonstrated that people tend to rely on the representativeness heuristic, leading them to make judgments based on how closely a specific case matches a known category, rather than on the actual base rate.</p>
    <p>Further research by Gigerenzer and Hoffrage (1995) explored how individuals can be trained to better understand and apply base rate information, finding that statistical education and practice can reduce the impact of this fallacy. Their work emphasizes the importance of teaching statistical reasoning to improve decision-making accuracy.</p>
    <p>A study by Koehler (1996) examined how the Base Rate Fallacy influences legal decision-making, showing that jurors are often swayed by specific evidence, even when the base rate suggests a different conclusion. The findings suggest that providing clear statistical information and educating jurors about base rates can help reduce this bias in legal settings.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A doctor diagnoses a rare disease based on a patient’s symptoms, even though the statistical likelihood of the disease is very low, ignoring the base rate of the disease’s prevalence.</li>
      <li>A juror is convinced of a defendant’s guilt based on compelling eyewitness testimony, despite the statistical evidence showing a low likelihood of the defendant’s involvement.</li>
      <li>An investor chooses to invest in a high-profile tech stock, ignoring the broader market trends that suggest a downturn in the tech sector.</li>
      <li>A person believes that flying is more dangerous than driving because they have heard of several recent plane crashes, even though the base rate of car accidents is much higher.</li>
      <li>Someone decides to avoid a particular restaurant because they heard a negative review from a friend, despite the restaurant having overwhelmingly positive reviews and a high overall rating.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This case is so unique, I’m sure the usual statistics don’t apply."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Even though this case seems unique, I need to consider the base rate to make an informed decision."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"That one example really stands out, so it must be the norm."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should look at the broader data to see if that example is representative or just an outlier."</div>
    </div>

    <MenuBottomCD v-bind:current="'BRF'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
