<template>
    <div>
      <h1>Reactance Bias</h1>
  
      <h3>You react against rules or restrictions, doing the opposite of what is intended.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Reactance Bias occurs when people perceive a threat to their freedom of choice and respond by doing the opposite of what is intended. For example, when someone feels their autonomy is being restricted by a rule or a suggestion, they might deliberately act in defiance, even if the rule or suggestion is in their best interest.</p>
      <p>This bias is a psychological reaction aimed at restoring a sense of control and autonomy. While it can be a natural response to overbearing restrictions, Reactance Bias often leads to self-sabotaging behaviors, as individuals make choices that are counterproductive simply to assert their independence.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Reactance Bias can have significant impacts in various areas of life. In health care, patients might resist following medical advice simply because they feel it's imposed on them, leading to poorer health outcomes. In marketing, consumers might avoid purchasing a product if they feel it's being too aggressively promoted.</p>
      <p>In personal relationships, this bias can cause conflicts, as individuals may resist compromise or cooperation if they feel their freedom is being restricted. Understanding Reactance Bias is crucial for recognizing when a desire for autonomy is leading to irrational decisions.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>Reactance Bias is driven by a fundamental human need for autonomy and control. When this need is threatened, the brain triggers a defensive reaction to restore the perceived loss of freedom. The more forcefully a rule or restriction is imposed, the stronger the reactance.</p>
      <p>Additionally, cultural factors that emphasize individualism and personal freedom can exacerbate this bias. In societies where autonomy is highly valued, people are more likely to react negatively to perceived constraints on their freedom of choice.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To prevent Reactance Bias, it's important to present choices in a way that preserves individuals' sense of autonomy. Rather than imposing rules or decisions, framing them as suggestions or options can reduce the likelihood of a reactance response.</p>
      <p>In situations where rules are necessary, explaining the reasoning behind them and involving individuals in the decision-making process can also mitigate this bias. By fostering a sense of shared control, people are less likely to feel their autonomy is being threatened.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research on Reactance Bias, such as the studies conducted by Brehm (1966), has shown that this bias is a powerful motivator of behavior, particularly in contexts where personal freedoms are at stake. Brehm's experiments demonstrated that when people perceive their freedom to choose is being restricted, they are more likely to resist and do the opposite of what is intended.</p>
      <p>Further studies have explored how this bias manifests in consumer behavior, showing that aggressive marketing tactics can backfire by triggering reactance, leading consumers to reject products or services that are pushed too hard.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A teenager might start smoking because they are told not to by authority figures, seeing it as a way to assert their independence.</li>
        <li>During a diet, a person might eat unhealthy food specifically because they feel their food choices are too restricted.</li>
        <li>In a negotiation, one party might refuse a reasonable offer simply because they feel pressured to accept it, even though it's in their best interest.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I don’t like being told what to do, so I’ll do the opposite."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I can still make my own choices, but I should consider what’s best for me rather than just reacting."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"They’re pushing this product too hard, so I’ll avoid it."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Instead of rejecting it outright, I’ll evaluate the product on its own merits."</div>
      </div>
  
      <MenuBottomPB v-bind:current="'RB'"></MenuBottomPB>
    </div>
  </template>
  
  <script>
    import MenuBottomPB from '../../components/MenuBottomPB.vue';
  
    export default {
      components: {
        MenuBottomPB,
      }
    }
  </script>
  