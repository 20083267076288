<template>
    <div>
      <h1>Primacy Effect</h1>
  
      <h3>You are more likely to remember the first items presented in a list.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Primacy Effect is a cognitive bias where individuals tend to remember the first items in a sequence better than those that follow. This effect occurs because the initial items are more likely to be encoded into long-term memory, making them more accessible later.</p>
      <p>This bias is particularly relevant in situations where the order of information presentation can influence memory and decision-making, such as in learning environments, marketing, and legal settings.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Primacy Effect can lead to skewed decision-making, as individuals may give undue weight to the first pieces of information they encounter. This can affect everything from first impressions to the effectiveness of persuasive communication.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This effect is often caused by the way information is processed and stored in memory. The first items in a sequence are given more attention and are rehearsed more, leading to better retention.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To counteract the Primacy Effect, it's important to give equal attention to all items in a sequence and to review and rehearse information that comes later in the sequence. Being aware of this bias can help in making more balanced decisions.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>The Primacy Effect was first identified by Hermann Ebbinghaus in his memory experiments. Research in psychology has consistently shown that the first items in a list are more likely to be remembered, confirming the effect's significance.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"Students often remember the first topics covered in a study session better than those covered later."</li>
        <li>"In a trial, the opening statements of a lawyer may have a lasting impact on the jury's perception."</li>
        <li>"A consumer is more likely to remember the first product they see in an advertisement."</li>
        <li>"A person tends to remember the first impression of someone more strongly than subsequent interactions."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"The first thing I learned is the most important."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should consider all the information equally before making a judgment."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'PE'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  