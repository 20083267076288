<template>
  <div>
    <h1>
      Fortune Telling
    </h1>

    <h3>You jump to conclusions not warranted by the facts.</h3>

    <h4>Explained</h4>

    <p>Fortune Telling, also known as Jumping to Conclusions, is a cognitive distortion, which is a type of thinking error or thinking bug, that involves predicting the future with absolute certainty. This type of distorted thinking can lead to negative emotions and actions, and it's important to recognize and address it in order to improve mental health and well-being.</p>

    <p>One example of fortune telling is assuming that a specific outcome will occur without any evidence to support it. For example, someone might say "I'll never get the job I applied for, so there's no point in even trying." This type of thinking ignores the fact that the outcome is not yet determined and discounts any potential positive outcomes.</p>

    <p>Another example of fortune telling is predicting that a negative event will have long-lasting and negative consequences. For example, someone might say "If I fail this test, my whole future will be ruined." This type of thinking ignores the fact that failures and setbacks are a normal part of life and that there are often multiple paths to success.</p>

    <p>Fortune telling can be harmful because it can lead to feelings of hopelessness and despair, and it can also lead to avoidance of opportunities and challenges. When we believe that the future is predetermined and that we have no control over it, we may be less likely to take action or try new things.</p>

    <p>To address fortune telling, it's important to challenge and reframe negative predictions about the future. Instead of assuming that a specific outcome will occur, consider the possibility that there may be other outcomes as well. For example, instead of saying "I'll never get the job," try saying "I may or may not get the job, but I can still try my best and learn from the experience."</p>

    <p>It's also helpful to focus on the present moment and take action in the here and now. Instead of worrying about the future, try to focus on what you can do in the present to improve your chances of success. This could involve seeking feedback, developing new skills, or seeking support from others.</p>

    <p>Overall, fortune telling is a common cognitive distortion that can lead to negative emotions and actions. By recognizing and addressing this thinking error, we can improve our mental health and well-being and increase our chances of success in the future.</p>

    <p>To address fortune telling, it is helpful to evaluate your thoughts and determine if they are based on evidence and have rational foundations. Ask yourself if your predictions about the future are supported by data and if they are true. It is important not to make broad and sweeping conclusions without a rational basis. If you are unsure about the accuracy of your thoughts, consider seeking input from a trusted friend or a cognitive-behavioral therapy (CBT) specialist.</p>

    <h4>Example</h4>

    <p>
      Example of fortune telling error:<br>
      "I'll never get the promotion I applied for, so there's no point in even trying."
    </p>

    <p>Reframed thought:<br>"I may or may not get the promotion, but I can still try my best and learn from the experience. Even if I don't get the promotion, there may be other opportunities for growth and advancement in the future."</p>

    <p>This reframed thought acknowledges the possibility that the outcome is not yet determined and that there may be multiple paths to success. It also shifts the focus from the negative prediction to a more positive and proactive approach.</p>
      
    <CBTAppText v-bind:current="'FT'"></CBTAppText>

    <menu-bottom v-bind:current="'FT'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>