<template>
  <div>
    <h1>Framing Effect</h1>

    <h3>You react differently to the same information depending on how it is presented.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Framing Effect is a cognitive bias where people make different decisions based on how the same information is presented. For example, people might react more positively to a treatment with a "90% survival rate" than to one with a "10% mortality rate," even though both statistics describe the same outcome.</p>
    <p>This bias occurs because people tend to be influenced by the context or "frame" in which information is delivered, rather than by the content itself. The Framing Effect can lead to irrational decision-making, as individuals may base their choices on superficial aspects of the information rather than on its actual substance.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Framing Effect can significantly affect decisions in various fields, including marketing, politics, and healthcare. It can lead to manipulation by those who present information in a way that influences people's choices. Understanding this bias is crucial for making more informed decisions and for recognizing when others may be trying to influence your thinking through framing.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Framing Effect is often caused by cognitive shortcuts, where the brain simplifies complex information by focusing on the most immediately noticeable aspects. This can lead to an overemphasis on the way information is presented rather than on the information itself. Additionally, emotions and prior experiences can amplify the impact of framing, making certain presentations more appealing or aversive based on personal biases.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid falling victim to the Framing Effect, it is important to critically evaluate the content of the information rather than how it is presented. Consider rephrasing the information in different ways to see if your perception changes. Additionally, focusing on the underlying facts and statistics, rather than on the narrative or language used to present them, can help you make more rational decisions.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Tversky and Kahneman (1981) on "The Framing of Decisions and the Psychology of Choice" highlights how the Framing Effect influences decision-making. Their study demonstrates that people's preferences can be dramatically altered by the way options are presented, even when the underlying information remains the same.</p>
    <p>Another study by Levin, Schneider, and Gaeth (1998) on "All Frames Are Not Created Equal: A Typology and Critical Analysis of Framing Effects" provides a comprehensive review of the different types of framing effects and their implications for decision-making in various contexts.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>"A food product labeled as '90% fat-free' is more likely to be purchased than one labeled '10% fat' even though both are identical."</li>
      <li>"A political candidate might be described as 'experienced' or 'part of the establishment,' depending on the intended framing."</li>
      <li>"A medical procedure may be more accepted if described as having a '70% success rate' rather than a '30% failure rate.'</li>
      <li>"In a survey, people might express different levels of support for a policy based on whether the consequences are framed positively or negatively."</li>
      <li>"A company might frame a pay raise as a 'generous 5% increase,' even though it might not keep up with inflation."</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This option sounds better because of the way it's presented."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should consider the actual facts and statistics, regardless of how they're framed."</div>
    </div>

    <MenuBottomLF v-bind:current="'FE'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
