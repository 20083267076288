<template>
  <div>
    <h1>Illusory Correlation</h1>

    <h3>You falsely perceive a relationship between two unrelated events.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Illusory Correlation is a cognitive bias where individuals perceive a relationship between two unrelated events, often because they co-occur or are striking in some way. This bias can lead to false beliefs about causality, reinforcing stereotypes and superstitions.</p>
    <p>This phenomenon occurs when people focus on information that confirms their expectations while ignoring contradictory evidence. For example, if someone believes that all politicians are corrupt, they may notice and remember instances of corruption more readily than examples of honesty, reinforcing the false belief.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Illusory Correlations can lead to the formation of stereotypes, where individuals overgeneralize traits or behaviors to entire groups based on limited observations. This bias can also contribute to superstition, as people connect unrelated events and attribute meaning or causality where none exists. In decision-making, Illusory Correlation can lead to poor judgments and reinforce unfounded beliefs.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias arises from the human tendency to seek patterns and make sense of the world, even when there is no real connection between events. It is also fueled by confirmation bias, where people pay more attention to information that supports their pre-existing beliefs and ignore information that does not.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To prevent Illusory Correlation, it's important to critically evaluate the evidence for any perceived relationships and to be aware of the potential for bias. Keeping a record of events and examining them objectively can help to identify whether a correlation is real or imagined. Additionally, seeking out disconfirming evidence and considering alternative explanations can reduce the influence of this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Chapman and Chapman (1967) first identified the phenomenon of Illusory Correlation in the context of clinical psychology, where therapists perceived correlations between patient symptoms and test responses that were not actually present. Subsequent studies, such as by Hamilton and Gifford (1976), demonstrated how this bias contributes to the formation of stereotypes, as people overestimate the association between minority groups and negative behaviors.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person believes that carrying a lucky charm causes good things to happen, despite no evidence of a causal connection.</li>
      <li>After experiencing bad weather on a trip, an individual concludes that traveling on certain days is unlucky.</li>
      <li>A manager believes that a specific employee is responsible for most errors because they remember the times the employee made mistakes but not when others did.</li>
      <li>People associate full moons with strange behavior, even though studies show no significant increase in unusual activity during full moons.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"Every time I do X, Y happens, so they must be related."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Just because X and Y happen together doesn’t mean one causes the other. I should look for other explanations."</div>
    </div>

    <MenuBottomCD v-bind:current="'IC'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
