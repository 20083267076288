<template>
    <div>
      <h1>Representativeness Heuristic</h1>
  
      <h3>You judge the probability of something based on how closely it matches a stereotype or prototype.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Representativeness Heuristic is a cognitive shortcut where people assess the probability of an event or situation by how closely it resembles a typical case or stereotype. For example, if someone is described as quiet and bookish, you might assume they are a librarian, even though there are many professions that could fit this description. This heuristic simplifies decision-making but often leads to inaccurate judgments because it ignores relevant statistical information.</p>
      <p>Although this heuristic helps in making quick decisions, it can also be misleading. The bias arises because people tend to ignore base rates and other critical information, focusing instead on how representative the information seems of a particular category. This can result in overestimating the likelihood of rare events simply because they are more vivid or memorable.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Representativeness Heuristic can have wide-ranging impacts, particularly in areas where probability and risk assessment are critical. For example, in financial markets, investors might make decisions based on how similar a new investment opportunity is to past successful ventures, disregarding fundamental analysis. This can lead to bubbles or other market inefficiencies.</p>
      <p>Moreover, this heuristic contributes to the persistence of stereotypes and biases in social judgments. For instance, people might be unfairly judged or treated based on superficial characteristics that align with societal stereotypes, rather than on individual merits. This not only perpetuates discrimination but also hinders the fair assessment of situations and individuals.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The tendency to use the Representativeness Heuristic is driven by our brain's preference for patterns and categories. When faced with uncertainty or incomplete information, our minds lean on these mental shortcuts to quickly make sense of the world. This process is often subconscious, making it challenging to recognize and counteract the bias.</p>
      <p>Cultural and societal influences also reinforce this heuristic. Over time, exposure to stereotypes in media, education, and social interactions can solidify these patterns, making them more likely to be used as shortcuts in judgment. These ingrained patterns are then applied broadly, often inappropriately, across various contexts.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To reduce the influence of the Representativeness Heuristic, it’s essential to develop a habit of questioning initial impressions and stereotypes. This involves actively seeking out and considering statistical information, base rates, and other relevant data before making a judgment. By balancing representative information with broader contextual understanding, more accurate and fair decisions can be made.</p>
      <p>Additionally, promoting critical thinking and diversity in experiences can help weaken the automatic reliance on this heuristic. By exposing oneself to a wide range of perspectives and challenging stereotypes, individuals can develop a more nuanced and accurate understanding of the world.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>The concept of the Representativeness Heuristic was first introduced by Tversky and Kahneman in their seminal 1974 paper, where they demonstrated how this cognitive shortcut leads to systematic errors in judgment. Their research showed that people often ignore base rate information, such as general population statistics, in favor of more vivid, representative information.</p>
      <p>Subsequent studies have confirmed these findings across different domains, highlighting the heuristic's role in perpetuating stereotypes and biases. For example, research in social psychology has shown that people are more likely to attribute characteristics based on representativeness rather than objective analysis, leading to both positive and negative biases in social interactions.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>When trying to guess the occupation of a person described as meticulous and detail-oriented, many might assume they are an accountant, despite there being more teachers or salespeople in the general population.</li>
        <li>In hiring, a manager might favor a candidate who fits the stereotypical image of a role, such as a male candidate for a technical position, overlooking more qualified candidates who don't fit the stereotype.</li>
        <li>A gambler might believe that a series of wins in a game is an indication that they will continue to win, failing to consider that each event is independent and statistically random.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This person fits the typical image of a doctor, so they must be one."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Just because someone looks like a doctor doesn't mean they are. I should consider other information before making that assumption."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This investment opportunity reminds me of a successful one I had before, so it must be a good choice."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I need to analyze this opportunity on its own merits, not just based on how it resembles past successes."</div>
      </div>
  
      <MenuBottomPB v-bind:current="'RH'"></MenuBottomPB>
    </div>
  </template>
  
  <script>
    import MenuBottomPB from '../../components/MenuBottomPB.vue';
  
    export default {
      components: {
        MenuBottomPB,
      }
    }
  </script>
  