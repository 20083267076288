<template>
  <div>
    <div class="footer-top-space"></div>
    <div class="footer site-footer">
      <div class="footer-content">
        
        <span style="float:left;">    
          Thinking Bugs © 2020-2024
        </span>

        <div style="float:right;width:30px;height:30px;margin-top:-5px;margin-bottom:-5px;" class="buttons-hover">
        <a href="https://twitter.com/thinkingbugs" title="Twitter" target="_blank">
          <svg class="svg-button"  viewBox="0 0 124.11 124.11">
            <path  d="M199.05,335a62.05,62.05,0,1,0,62.05,62.05A62.05,62.05,0,0,0,199.05,335h0Zm46.81,39.49a35.82,35.82,0,0,1-10.3,2.82,18,18,0,0,0,7.88-9.92,35.93,35.93,0,0,1-11.39,4.35,17.94,17.94,0,0,0-30.55,16.35,50.9,50.9,0,0,1-37-18.74,18,18,0,0,0,5.55,23.94,17.87,17.87,0,0,1-8.12-2.24c0,0.07,0,.15,0,0.22a18,18,0,0,0,14.38,17.58,17.95,17.95,0,0,1-8.09.31A18,18,0,0,0,185,421.62,36.16,36.16,0,0,1,158.48,429,50.74,50.74,0,0,0,186,437.1c33,0,51-27.32,51-51q0-1.17-.05-2.32a36.45,36.45,0,0,0,8.94-9.28h0Z" transform="translate(-137 -335)"/>
          </svg>
        </a>
      </div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      homePath: '/',
    }
  },
  methods: {      
    menuClose: function () {
      this.$parent.menuOpen = false;
    },
    menuClick: function () {
      this.$parent.menuOpen = true;
      window.scrollTo(0, 0);
    },
    toTop: function() {
      window.scrollTo(0,0);
      if (this.$parent.menuOpen) {
        this.$parent.menuOpen = false;
        this.$router.push(this.homePath);
      }
    },
  }
};
</script>

<style>

.svg-button {
  /* fill:#6a6b6c; */
  fill:#878787;
  fill-rule:evenodd;
  cursor:pointer;
  transition: fill 0.2s ease;
}

.buttons-hover:active .svg-button {
  fill:#ababab;
}

@media screen and (min-width: 600px) {
  .buttons:hover .svg-button {
    fill:#ababab;
  }
  .buttons-hover:hover .svg-button {
    fill:#ababab;
  }
}

.buttons:active .svg-button {
  fill:#ababab;
}

.buttons-hover:active .svg-button {
  fill:#ababab;
}


  .footer {
    width: 100%;
    background-color:#444;
    left: 0;
    padding-top: 35px;
    padding-bottom: 45px;
    min-height: 70px;
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }

  .footer-link {
    color: #e7e7e7!important;
  }

  @media only screen and (max-width: 600px) {
    .footer {
      padding-left:5px;
      padding-right:6px;
    }
  }

  .footer-content {
    max-width: 640px;
    margin: 0 auto;
  }

  .footer-top-space {
    height: 90px;
  }

  @media only screen and (max-width: 600px) {
    .footer-content {
      padding-left:15px;
      padding-right:15px;
    }
    .footer-top-space {
      height: 80px;
    }
  }

  .footer-column {
    width: 310px;
    float: left;
  }

  .footer {
    font-size: 14px;
    color: rgb(226, 226, 226);
  }

  .footer a {
    font-size: 14px;
    color: rgb(226, 226, 226);
    text-decoration: underline;
    line-height:22px;
    border-bottom: 0px!important;
  }
</style>