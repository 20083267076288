<template>
    <div>
      <h1>Misinformation Effect</h1>
  
      <h3>Your memories become less accurate due to post-event information.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Misinformation Effect refers to the phenomenon where a person's memory of an event becomes less accurate due to the introduction of misleading post-event information. This cognitive bias shows how memories can be influenced and altered by external factors, leading to the recall of incorrect or distorted details.</p>
      <p>This effect is particularly significant in legal contexts, where eyewitness testimony can be influenced by suggestive questioning or media coverage, potentially leading to wrongful convictions or other judicial errors.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Misinformation Effect can have serious consequences, particularly in legal settings, where inaccurate memories can lead to false testimony or wrongful convictions. It can also affect personal relationships and decisions, as individuals may act on distorted memories.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This effect often occurs when individuals are exposed to misleading information after an event has occurred. It can be exacerbated by suggestive questioning, repeated exposure to misinformation, and high levels of stress or emotional arousal during the original event.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To prevent the Misinformation Effect, it's crucial to avoid leading questions and be mindful of the sources of post-event information. Taking notes immediately after an event and verifying details with others who were present can help preserve accurate memories.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research by Elizabeth Loftus has extensively documented the Misinformation Effect. In a series of studies, Loftus demonstrated how participants' memories of events were altered by exposure to misleading information, showing the powerful influence of post-event information on memory recall.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A witness recalls seeing a weapon in a crime scene after hearing news reports, even though there was no weapon."</li>
        <li>"Someone remembers a conversation differently after discussing it with others who had a different recollection."</li>
        <li>"A person believes they were at a specific event after repeatedly hearing about it, even though they were not actually present."</li>
        <li>"An employee misremembers a meeting's outcome after hearing a colleague's version of events."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I clearly remember what happened, so I must be right."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Memories can be influenced by later information, so I should verify the details."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'ME'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  