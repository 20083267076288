<template>
  <div>
    <h1>Anchoring</h1>

    <h3>You rely too heavily on the first piece of information encountered.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Anchoring is a cognitive bias where your decisions are heavily influenced by the first piece of information you encounter—known as the "anchor." This initial reference point can significantly shape how you perceive subsequent information, often leading to skewed judgments. Even if the anchor is arbitrary or irrelevant, it can still disproportionately affect decision-making.</p>
    <p>This bias is particularly pervasive in situations of uncertainty, where individuals may lack sufficient information or expertise. In such cases, the anchor provides a seemingly reliable reference point, leading people to adjust their estimates or opinions based on it. Anchoring can occur in various contexts, from pricing and negotiations to everyday decisions.</p>
    <p>Understanding the impact of anchoring is crucial for making more balanced decisions. By recognizing when an anchor is influencing your thinking, you can consciously adjust your judgment, consider alternative information, and reach more accurate conclusions.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Anchoring can have significant implications across various domains, including finance, healthcare, and law. For instance, in financial markets, initial price estimates can anchor investor expectations, leading to persistent overvaluation or undervaluation of assets. In healthcare, the first diagnosis a doctor considers can anchor their subsequent thinking, potentially leading to misdiagnosis.</p>
    <p>In legal contexts, the first settlement offer in negotiations often sets the tone for the final agreement, even if it is far from fair or reasonable. Similarly, in social settings, initial impressions can anchor how individuals are perceived, influencing subsequent judgments about their character or abilities.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Anchoring arises from the human tendency to use cognitive shortcuts, especially when faced with complex decisions or insufficient information. It is also driven by the desire to avoid cognitive dissonance, as individuals are more likely to stick with an initial reference point rather than adjust their thinking. Additionally, time constraints and pressure to make decisions quickly can exacerbate the reliance on anchoring.</p>
    <p>Another contributing factor is the social and cultural emphasis on consistency, which can lead individuals to adhere to an initial judgment to appear decisive or rational, even when new information suggests a different conclusion.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract anchoring, it is essential to gather a broad range of information before making a decision. This includes seeking out diverse perspectives, considering alternative anchors, and delaying judgment until all relevant data has been evaluated. Reflecting on why an initial piece of information seems influential can also help mitigate its impact.</p>
    <p>In negotiations or decision-making, explicitly questioning the validity of the anchor and considering the opposite viewpoint can reduce its influence. Additionally, training in critical thinking and mindfulness can help individuals become more aware of how anchoring affects their reasoning and enable them to make more informed decisions.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Tversky and Kahneman (1974) was among the first to demonstrate the effects of anchoring. Their studies showed that even arbitrary numbers could significantly influence participants' estimates in unrelated tasks, highlighting the powerful impact of this bias. For example, when participants were asked to estimate the percentage of African countries in the United Nations, their answers were heavily influenced by a randomly generated number they had been shown earlier.</p>
    <p>Further research by Chapman and Johnson (1999) explored the role of anchoring in judgment and decision-making, finding that even when participants were aware of the anchor’s irrelevance, they were still influenced by it. Their work underscored the difficulty of overcoming this bias, even with deliberate effort.</p>
    <p>A study by Epley and Gilovich (2006) examined how anchoring affects predictions and judgments, demonstrating that even experts in their fields are not immune to this bias. Their findings suggest that anchoring is a pervasive cognitive distortion that can impact decision-making in a wide range of contexts.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>During salary negotiations, the first number mentioned often sets the stage for the rest of the discussion. If an employer initially offers $60,000, the candidate might negotiate up to $65,000, even if they could have reasonably asked for $70,000 or more.</li>
      <li>When estimating the cost of a home renovation, the first quote received can influence expectations and decisions, even if subsequent quotes are more accurate or based on better information.</li>
      <li>In an auction, the initial bid can anchor participants’ perceptions of the item’s value, leading them to bid higher or lower based on that starting point rather than on the item's true worth.</li>
      <li>In marketing, an initial high price might be shown with a discount to make the final price seem more appealing, even if the final price is not actually a bargain.</li>
      <li>A doctor who initially considers a specific diagnosis might focus on confirming that diagnosis, overlooking symptoms that point to a different condition.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"The first offer is $50,000, so that must be close to my market value."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"This is just a starting point. I should research what others in my field are earning and negotiate based on that information."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"The house is listed at $500,000, so it must be worth at least that much."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While the house is listed at $500,000, I should compare it with other similar properties in the area to determine its true market value."</div>
    </div>

    <MenuBottomBT v-bind:current="'An'"></MenuBottomBT>
  </div>
</template>

<script>
  import MenuBottomBT from '../../components/MenuBottomBT.vue';

  export default {
    components: {
      MenuBottomBT,
    }
  }
</script>
