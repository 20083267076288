<template>
    <div>
      <h1>Neglect of Probability</h1>
  
      <h3>You disregard probability when making a decision, focusing on the outcome instead.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Neglect of Probability is a cognitive bias where individuals focus on the potential outcomes of a decision while ignoring the probability of those outcomes occurring. This bias leads people to overestimate the likelihood of rare events or underestimate the likelihood of common events, resulting in poor decision-making.</p>
      <p>For example, people might buy lottery tickets despite the extremely low odds of winning, or they might avoid flying due to a fear of crashes, even though air travel is statistically safer than driving. This bias is driven by emotions and the vividness of potential outcomes, rather than a rational assessment of probability.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Neglect of Probability can lead to irrational fears, misplaced investments, and poor risk management. It can cause people to take unnecessary precautions against unlikely events while ignoring more probable risks. In business, this bias might lead to misallocation of resources, such as focusing on rare but catastrophic risks while neglecting more likely, less dramatic risks.</p>
      <p>For instance, an organization might over-insure against a rare event, leaving other areas of potential risk underprotected. Similarly, individuals might avoid beneficial activities, like vaccination, due to a focus on rare side effects rather than the high probability of benefits.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This bias is often driven by the availability heuristic, where people judge the likelihood of an event based on how easily examples come to mind. Emotions also play a significant role, as fear or excitement can lead individuals to focus on the outcome rather than the probability. The complexity of statistical thinking and a general discomfort with uncertainty can further exacerbate this bias.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To mitigate Neglect of Probability, it's important to incorporate statistical thinking into decision-making processes. This includes assessing the likelihood of outcomes based on evidence and probability rather than emotions. Educating oneself about risks and probabilities, and seeking out expert advice when necessary, can also help in making more informed decisions.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research by Kahneman and Tversky (1979) on prospect theory highlighted how people tend to overweight small probabilities and underweight large probabilities, leading to biased decision-making. Further studies have shown that people are more likely to neglect probability when outcomes are emotionally charged or highly vivid.</p>
      <p>These findings emphasize the importance of probability in decision-making and the need for greater awareness of this bias in both personal and professional contexts.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"An investor pours money into a startup with a low chance of success, ignoring the high risk of failure."</li>
        <li>"A parent refuses to vaccinate their child due to fear of rare side effects, despite the overwhelming benefits."</li>
        <li>"A person avoids flying due to a fear of crashes, even though driving is statistically more dangerous."</li>
        <li>"A gambler bets on a long shot horse because of the high potential payout, neglecting the low probability of winning."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This outcome could happen, so I need to prepare for it."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should consider how likely this outcome is before deciding."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This rare event could ruin everything."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should focus on more likely scenarios and not let fear dictate my decisions."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'NP'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  