<template>
  <div>

    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Behavioral Traps List</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Behavioral Traps</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Behavioral traps are cognitive biases that influence our actions, often leading to unproductive or even harmful behaviors. These biases can cause us to fall into repetitive patterns, overlook important details, or rush to conclusions without fully considering the consequences. Understanding these traps can help us break free from unproductive habits and make more thoughtful, deliberate decisions that align with our goals and values.
      <div style="height:40px;"></div>
    </div>

    <!-- Order Effects -->
    <div v-if="current != 'OE'">
      <router-link to="/order-effects">
        <div class="footer-div">
          OE
        </div>
        <div class="footer-div-label">
          Order Effects
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        Your judgment is influenced by the order in which information is presented.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        OE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Order Effects
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Vertical Line Failure -->
    <div v-if="current != 'VLF'">
      <router-link to="/vertical-line-failure">
        <div class="footer-div">
          VLF
        </div>
        <div class="footer-div-label">
          Vertical Line Failure
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You persist with a failing strategy because it aligns with a linear approach, despite evidence it’s not working.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        VLF
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Vertical Line Failure
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Search Satisficing -->
    <div v-if="current != 'SS'">
      <router-link to="/search-satisficing">
        <div class="footer-div">
          SS
        </div>
        <div class="footer-div-label">
          Search Satisficing
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You stop searching for solutions once you find an option that meets the minimum criteria, rather than the best one.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SS
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Search Satisficing
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Diagnosis Momentum -->
    <div v-if="current != 'DM'">
      <router-link to="/diagnosis-momentum">
        <div class="footer-div">
          DM
        </div>
        <div class="footer-div-label">
          Diagnosis Momentum
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You become fixated on an initial diagnosis, making it difficult to consider alternative explanations.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        DM
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Diagnosis Momentum
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Anchoring -->
    <div v-if="current != 'An'">
      <router-link to="/anchoring">
        <div class="footer-div">
          An
        </div>
        <div class="footer-div-label">
          Anchoring
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You rely too heavily on the first piece of information encountered.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        An
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Anchoring
      </div>
    </div>

    <div class="footer-div-spacer"></div>
    
    <!-- Zebra Retreat -->
    <div v-if="current != 'ZR'">
      <router-link to="/zebra-retreat">
        <div class="footer-div">
          ZR
        </div>
        <div class="footer-div-label">
          Zebra Retreat
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You revert to considering more common diagnoses rather than rare ones, even when the rare diagnosis might be more accurate.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        ZR
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Zebra Retreat
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
