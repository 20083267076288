import { render, staticRenderFns } from "./mere-exposure-effect.vue?vue&type=template&id=ae1d6566"
import script from "./mere-exposure-effect.vue?vue&type=script&lang=js"
export * from "./mere-exposure-effect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports