<template>
    <div>
      <h1>Survivorship Bias</h1>
  
      <h3>You focus on successful cases while ignoring failures, leading to overestimation of success.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Survivorship Bias is a cognitive bias where people focus on successful cases and ignore those that failed, leading to an overestimation of the likelihood of success. This bias occurs because success stories are more visible and frequently reported, while failures are often overlooked.</p>
      <p>This bias can skew perceptions in various fields, including business, finance, and history, where the focus on survivors leads to incorrect conclusions about the factors that lead to success. By ignoring the "silent evidence" of failures, people might adopt strategies or beliefs that are not actually as effective as they seem.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Survivorship Bias can lead to unrealistic expectations and poor decision-making. For instance, in investing, people might follow the strategies of successful investors without considering the many who used the same approach but failed. In business, entrepreneurs might overestimate their chances of success by only focusing on successful startups.</p>
      <p>This bias also affects historical analysis, where the focus on winners of wars, empires, or technologies can lead to a distorted understanding of history, neglecting the many other factors that contributed to different outcomes.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This bias is often driven by the availability heuristic, where examples of success are more accessible and memorable than those of failure. Media coverage and storytelling tend to focus on winners, further reinforcing this bias. Additionally, people may prefer to learn from successes rather than failures, leading to a skewed understanding of what truly contributes to success.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To counteract Survivorship Bias, it's important to seek out information on both successes and failures when analyzing any situation. This includes looking for "silent evidence"—the cases that are not visible because they did not succeed. Critical thinking and skepticism can help in questioning whether success stories truly represent the full picture.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Survivorship Bias has been studied extensively in various contexts. A classic example is the analysis of World War II aircraft by Abraham Wald, who showed that focusing only on planes that returned from missions could lead to incorrect conclusions about where to add armor. His work highlighted the importance of considering both survivors and non-survivors in decision-making.</p>
      <p>In finance, studies have shown that investment strategies that appear successful might not actually outperform the market when considering the many funds that closed due to poor performance. This research underscores the importance of considering all data, not just the success stories.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"An entrepreneur follows the advice of successful startup founders without considering the many failed startups that followed the same advice."</li>
        <li>"An investor chooses to invest in high-risk stocks after seeing the success of a few high-profile investors, ignoring those who lost money."</li>
        <li>"A historian focuses on the strategies of winning generals in a war, neglecting the many that failed with similar strategies."</li>
        <li>"A student studies the habits of top performers, assuming they are the key to success, without considering those who used the same habits but didn't succeed."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"If they succeeded with this approach, I will too."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should consider how many others tried the same approach and failed before making a decision."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'SB'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  