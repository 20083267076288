<template>
  <div>
    <h1>Self-Enhancement Bias</h1>

    <h3>You have an inflated view of your own abilities or character.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Self-Enhancement Bias is a cognitive bias where individuals have an inflated view of their own abilities, traits, or character. This bias leads people to overestimate their competence, intelligence, or moral standing, often comparing themselves favorably to others. As a result, they may perceive themselves as superior, even when objective evidence suggests otherwise.</p>
    <p>This bias is common in various areas of life, including education, work, and social interactions. It can lead to overconfidence, poor decision-making, and strained relationships, as individuals may not accurately assess their own limitations or the contributions of others. Self-Enhancement Bias can also contribute to the persistence of stereotypes and social inequalities, as individuals may use this bias to justify their own status or success.</p>
    <p>Recognizing Self-Enhancement Bias is crucial for personal growth and effective decision-making. By developing a more accurate and balanced view of oneself, individuals can make more informed choices, build healthier relationships, and contribute more positively to society.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Self-Enhancement Bias can have significant consequences in both personal and professional settings. In the workplace, it can lead to overconfidence in one’s abilities, resulting in poor performance, missed opportunities, or even failure. In social interactions, this bias can create conflicts, as individuals may perceive themselves as superior to others, leading to misunderstandings and resentment.</p>
    <p>This bias can also hinder personal growth and development, as individuals may be less likely to seek feedback, acknowledge their mistakes, or learn from their experiences. Over time, Self-Enhancement Bias can lead to a lack of self-awareness, preventing individuals from reaching their full potential and achieving their goals.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Self-Enhancement Bias is driven by the human desire to maintain a positive self-image and to protect one’s self-esteem. This bias is reinforced by social and cultural factors that emphasize individual achievement, success, and status. Additionally, cognitive biases such as the availability heuristic, where individuals recall instances of their own success more easily than instances of failure, can contribute to this distortion.</p>
    <p>Another contributing factor is the comparison with others, where individuals selectively compare themselves to those they perceive as less competent or successful, further reinforcing their inflated self-view. This bias can also be exacerbated by positive feedback from others, which may be interpreted as confirmation of one’s superiority, even when it is not deserved.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Self-Enhancement Bias, it is important to seek regular feedback from others and to approach it with an open mind. This involves being willing to listen to constructive criticism and to reflect on one’s own limitations. Engaging in self-assessment and setting realistic goals can also help in developing a more balanced view of oneself.</p>
    <p>Practicing humility and recognizing the contributions of others can further reduce this bias. By acknowledging that everyone has strengths and weaknesses, individuals can build healthier relationships and make more informed decisions. Additionally, developing a habit of continuous learning and self-improvement can help in maintaining a more accurate self-view.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Alicke and Govorun (2005) explored the prevalence of Self-Enhancement Bias, finding that individuals consistently rate themselves higher on positive traits and abilities compared to others. Their studies suggest that this bias is widespread and can have significant implications for social behavior and decision-making.</p>
    <p>Further research by Dunning, Heath, and Suls (2004) examined how Self-Enhancement Bias affects performance in various domains, showing that individuals who overestimate their abilities are more likely to fail or underperform. Their findings highlight the importance of developing a more accurate self-assessment to avoid the negative consequences of this bias.</p>
    <p>A study by Kruger and Dunning (1999) investigated the relationship between Self-Enhancement Bias and competence, finding that individuals with lower competence are more likely to overestimate their abilities, a phenomenon known as the Dunning-Kruger Effect. This research underscores the importance of self-awareness and continuous learning in overcoming this bias.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A student believes they are smarter than their classmates, even though their grades do not reflect this superiority.</li>
      <li>An employee assumes they are the best performer in their team, despite receiving average performance reviews.</li>
      <li>A driver considers themselves an excellent driver, even though they have been involved in multiple accidents.</li>
      <li>Someone believes they are morally superior to others, even though they engage in the same behaviors they criticize in others.</li>
      <li>A manager overestimates their leadership abilities, leading to poor decision-making and team dissatisfaction.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’m clearly the best at this; others just don’t see it."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should seek feedback to understand my strengths and areas for improvement."</div>
    </div>


    <MenuBottomCD v-bind:current="'SEB'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
