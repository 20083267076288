<template>
  <div>
    <h1>Sunk Cost Fallacy</h1>

    <h3>You continue a behavior or endeavor because of previously invested resources.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Sunk Cost Fallacy occurs when individuals persist with a decision, action, or investment because they have already invested significant resources, such as time, money, or effort, even when it would be more rational to abandon the endeavor. The fallacy stems from the desire to avoid feeling like those resources have been wasted, leading to continued commitment even in the face of poor outcomes.</p>
    <p>Often, this bias leads to further losses, as the individual or organization "throws good money after bad," trying to recover the initial investment. It’s a common cognitive trap in business, personal relationships, and daily decision-making.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Sunk Cost Fallacy can have significant emotional, financial, and practical impacts. For instance, in business, it might lead companies to continue funding failing projects, which can drain resources that could be better spent elsewhere. On a personal level, individuals might stay in unhappy relationships or unfulfilling careers due to the time already invested, leading to prolonged dissatisfaction and missed opportunities for happiness.</p>
    <p>This fallacy also perpetuates stress and anxiety, as the realization of continued investment without return can weigh heavily on decision-makers, making it even harder to disengage from the failing course of action.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Sunk Cost Fallacy is driven by emotional attachment, fear of regret, and the psychological discomfort of admitting failure. Cultural values, such as perseverance and commitment, can exacerbate this bias by encouraging individuals to "stick it out" despite evidence that doing so is not in their best interest. Additionally, the human tendency to avoid loss at all costs, known as loss aversion, plays a significant role in perpetuating this fallacy.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid falling into the Sunk Cost Fallacy, it is crucial to regularly reassess ongoing projects and commitments based on their current and future value, not on past investments. Developing the habit of asking, "Would I start this again today, knowing what I know now?" can help reframe decision-making. Additionally, seeking input from others who are not emotionally invested in the situation can provide objective perspectives that counteract the bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Arkes and Blumer (1985) identified the Sunk Cost Fallacy as a common bias that leads individuals and organizations to continue investments in failing endeavors due to the resources already spent. Further studies, such as by Staw (1976), have explored how escalation of commitment in organizations is driven by this fallacy, with decision-makers often doubling down on failing projects to justify past investments.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A company continues to fund a product that consistently underperforms, hoping that additional investment will turn it around.</li>
      <li>An individual keeps attending a course they dislike because they’ve already paid the tuition, despite knowing they won’t benefit from it.</li>
      <li>A person remains in a long-term relationship that makes them unhappy because of the years they’ve spent with their partner.</li>
      <li>A government persists with a costly infrastructure project despite overwhelming evidence that it will not deliver the expected benefits.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’ve already invested so much time and money; I can’t quit now."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"The resources I’ve spent are gone, and continuing might cost me even more. It’s better to cut my losses and move on."</div>
    </div>

    <MenuBottomCD v-bind:current="'SCF'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
