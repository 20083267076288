<template>
  <div>
    <h1>Optimism Bias</h1>

    <h3>Overestimating the likelihood of positive outcomes.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Optimism Bias is the tendency to overestimate the likelihood of positive events and underestimate the likelihood of negative events. This bias can lead individuals to believe that they are less likely to experience adverse outcomes, such as illness or financial loss, and more likely to experience beneficial outcomes, such as success or happiness.</p>
    <p>While Optimism Bias can encourage a positive outlook and motivate individuals to pursue their goals, it can also result in underestimating risks and failing to prepare for potential setbacks. Recognizing this bias is crucial for maintaining a realistic perspective, where optimism is balanced with an understanding of potential challenges.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The impact of Optimism Bias can be both positive and negative. On the positive side, individuals with this bias may be more resilient, as they expect good things to happen and are more likely to persevere in the face of adversity. However, the downside is that they may take unnecessary risks, fail to plan for contingencies, or ignore warning signs, leading to negative outcomes that could have been avoided.</p>
    <p>For example, in financial planning, an overly optimistic outlook can lead to inadequate savings for retirement or insufficient insurance coverage. In health, individuals may underestimate their risk for certain conditions, leading to delayed diagnosis and treatment.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Optimism Bias is often rooted in the brain's reward system, which is more activated by positive expectations. It can also be influenced by cultural and social factors that encourage a positive outlook and discourage dwelling on potential negatives. Additionally, personal experiences of past successes can reinforce optimistic expectations, making it more difficult to accurately assess future risks.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To prevent Optimism Bias from leading to poor decisions, it's important to practice realistic optimism, where positive expectations are tempered with a clear understanding of potential risks. This can be achieved by seeking out objective information, considering worst-case scenarios, and regularly reviewing and adjusting plans based on new information. Consulting with others who have different perspectives can also provide a more balanced view.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research on Optimism Bias has shown that it is a widespread phenomenon that can influence a variety of decisions. A study by Sharot et al. (2011) found that individuals consistently underestimate their likelihood of experiencing negative events, which can lead to inadequate preparation and risk management. Another study by Armor and Taylor (2002) demonstrated that optimism can influence how people set goals and assess their ability to achieve them, often leading to overly ambitious plans that may not be realistic.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An investor believes that their stock portfolio will continue to grow, ignoring market trends that suggest a downturn is possible.</li>
      <li>A person avoids regular health check-ups, believing that they are unlikely to develop any serious conditions, despite a family history of illness.</li>
      <li>A student assumes they will pass an exam without studying, relying on past successes and underestimating the difficulty of the material.</li>
      <li>A new business owner is overly confident that their startup will succeed without considering the high failure rate of new businesses.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"Everything will work out fine, so I don't need to worry."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I’m hopeful things will work out, but I should prepare for challenges and be ready to adapt if things don’t go as planned."</div>
    </div>

    <MenuBottomCD v-bind:current="'OB'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
