<template>
  <div>
    <h1>Hindsight Bias</h1>

    <h3>You see past events as having been more predictable than they actually were.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Hindsight Bias, often summarized by the phrase "I knew it all along," is a cognitive distortion where people believe that they could have predicted the outcome of an event after it has already happened. This bias can lead individuals to overestimate their ability to have foreseen events and to downplay the role of uncertainty and chance.</p>
    <p>This bias can lead to overconfidence in decision-making and a failure to learn from past mistakes, as individuals may convince themselves that the outcomes were always inevitable and that they could have acted differently if only they had been aware of what they now know.</p>
    <p>Recognizing Hindsight Bias is essential for accurate reflection and learning from past experiences, as it helps to distinguish between what was known at the time and what is only obvious in retrospect.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Hindsight Bias can affect various aspects of life, from personal relationships to professional decisions. In business, it can lead to flawed post-mortem analyses, where decision-makers fail to identify the true causes of success or failure. In personal life, this bias might result in unnecessary guilt or self-blame, as individuals believe they should have predicted outcomes that were, in fact, unpredictable.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Hindsight Bias is caused by the cognitive dissonance that arises when the outcomes of events are known. To resolve this dissonance, the brain reconstructs memories to make past events seem more predictable than they actually were. This bias is also reinforced by the human tendency to find patterns and make sense of the world, leading to a retrospective sense of inevitability.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To mitigate Hindsight Bias, it's important to keep a detailed record of decisions, including the information and reasoning used at the time, to compare with outcomes. This can help maintain an accurate perspective on what was knowable before the outcome occurred. Additionally, acknowledging the role of uncertainty and chance in decision-making can help reduce the bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Fischhoff (1975) demonstrated the prevalence of Hindsight Bias, showing how individuals tend to view outcomes as more predictable after they have occurred. The study revealed that people consistently overestimate their ability to predict events once they know the outcome, which can lead to distorted memories and overconfidence in decision-making.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>After a team loses a game, a fan claims they knew the outcome was inevitable, despite having felt uncertain beforehand.</li>
      <li>Following a stock market crash, an investor believes they should have seen it coming, even though they were optimistic just days earlier.</li>
      <li>After a political election, people often believe the winner's victory was obvious, even if the race was considered close or unpredictable beforehand.</li>
      <li>A project manager believes that the signs of a project's failure were clear all along, despite having felt confident about its success before the outcome.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I knew this would happen; it was so obvious."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"It’s easy to see how things turned out now, but I couldn’t have known for sure at the time."</div>
    </div>

    <MenuBottomCD v-bind:current="'HB'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
