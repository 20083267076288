<template>
  <div>
    <h1>Law of the Instrument</h1>

    <h3>You over-rely on a familiar tool or method, even when it may not be suitable.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Law of the Instrument, often summarized as "if all you have is a hammer, everything looks like a nail," refers to the cognitive bias where individuals over-rely on a familiar tool, method, or perspective, even when it is not the most appropriate for the situation. This bias can lead to the application of inadequate solutions to problems, resulting in suboptimal outcomes.</p>
    <p>This phenomenon is common in professional and personal settings, where individuals might default to the tools or methods they know best, rather than exploring alternative approaches that might be better suited to the task at hand.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Law of the Instrument can lead to inefficiency, as the overuse of familiar methods may fail to address the nuances of a problem. In business, it might result in missed opportunities for innovation or the perpetuation of outdated practices. In personal life, it can cause individuals to rely on coping mechanisms or strategies that are no longer effective, hindering personal growth and problem-solving abilities.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is driven by comfort and familiarity, as individuals tend to gravitate towards what they know best. The desire to minimize effort and avoid the uncertainty of trying new methods also contributes to the reliance on familiar tools. Additionally, confidence in one's expertise can lead to the mistaken belief that the usual methods will always suffice, regardless of the situation.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid the pitfalls of the Law of the Instrument, it’s important to cultivate flexibility in thinking and to be open to exploring new methods and tools. Regularly questioning whether the current approach is the best one for the situation and seeking input from others can help in selecting the most appropriate solutions. Continuous learning and adapting to new information are also key to overcoming this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Maslow (1966), who coined the term "Law of the Instrument," emphasized how over-reliance on a single approach can limit problem-solving abilities. Subsequent studies in cognitive psychology, such as those by Luchins (1942), explored how mental set and functional fixedness can lead individuals to apply familiar solutions even when they are inappropriate, demonstrating the need for cognitive flexibility in complex problem-solving.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An IT specialist insists on using a particular software tool for all projects, even when a different tool would be more efficient.</li>
      <li>A teacher relies on the same teaching method for all students, without adapting to different learning styles or needs.</li>
      <li>A manager applies the same strategy to every business problem, missing opportunities for innovation by not considering other approaches.</li>
      <li>A person sticks to the same workout routine, despite diminishing returns, rather than exploring new exercises that could yield better results.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This tool has always worked for me, so I’ll use it again."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should consider whether there’s a better tool or method for this specific task."</div>
    </div>

    <MenuBottomCD v-bind:current="'LotI'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
