<template>
  <div>
    <h1>False Memory</h1>

    <h3>You remember events differently from how they actually occurred.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>False Memory is a cognitive distortion where individuals recall events that either did not happen or remember them differently from how they actually occurred. This phenomenon can be influenced by suggestion, misinformation, and the passage of time, leading to the creation of entirely new memories or the alteration of existing ones.</p>
    <p>False Memories can be vivid and detailed, making them seem real to the person experiencing them. This can have significant implications in legal contexts, where eyewitness testimony can be affected, as well as in personal relationships, where disagreements about past events can arise due to differing memories.</p>
    <p>Understanding False Memory is crucial for recognizing the fallibility of human memory and the potential for distortion, even when one feels certain about their recollections.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>False Memories can lead to incorrect beliefs and decisions based on inaccurate recollections. In legal settings, they can contribute to wrongful convictions if eyewitnesses recall events incorrectly. In everyday life, False Memories can cause misunderstandings, conflicts, and even the erosion of trust in relationships, as individuals may be convinced of events that never happened or happened differently.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>False Memories are often caused by the brain’s reconstructive nature, where memories are pieced together from various sources rather than being exact recordings of events. This process can be influenced by suggestions, leading questions, and exposure to misinformation. Over time, memories can also become distorted as they are recalled and reconsolidated, with new details added or old ones altered.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To reduce the likelihood of False Memories, it’s important to carefully document significant events and to be aware of the potential for suggestion and misinformation to alter memories. Being skeptical of memories that seem unusually vivid or detailed can also help, as can seeking corroboration from other sources when accuracy is critical.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Loftus and Palmer (1974) demonstrated how the wording of questions could influence eyewitnesses' memories, leading to the creation of False Memories. Their studies showed that memory is highly susceptible to suggestion, and that even subtle changes in language can alter a person’s recollection of an event. More recent research by Hyman et al. (1995) further explored how False Memories can be implanted through repeated suggestion, emphasizing the malleability of human memory.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An individual recalls attending an event that they never actually attended because of repeated stories about it from others.</li>
      <li>A person vividly remembers a childhood experience, only to later discover that it never happened or occurred differently.</li>
      <li>During a legal trial, an eyewitness is certain they saw a suspect at the scene, but later evidence shows that the suspect was elsewhere at the time.</li>
      <li>A person recalls having a conversation with someone, but the other person insists it never took place, suggesting a False Memory.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’m sure I remember this correctly."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Memory can be tricky. I should check if others remember it the same way before being too confident."</div>
    </div>

    <MenuBottomCD v-bind:current="'FM'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
