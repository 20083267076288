<template>
    <div>
      <h1>Recency Effect</h1>
  
      <h3>You are more likely to remember the most recent items presented in a list.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Recency Effect is a cognitive bias where individuals tend to remember the last items in a sequence better than those in the middle. This effect occurs because the last items are still in short-term memory, making them more accessible for immediate recall.</p>
      <p>This bias is particularly relevant in scenarios where the order of information presentation affects memory retention, such as in learning, marketing, and communication strategies.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Recency Effect can influence decisions and judgments, as the most recent information may disproportionately affect perception and recall. This can be particularly significant in situations like witness testimonies or sales pitches, where the last thing heard might overshadow everything else.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The Recency Effect occurs because the last items in a sequence are still active in short-term memory. Without further processing, these items may be recalled more easily than information presented earlier.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To counteract the Recency Effect, it's important to review and reinforce earlier information. Spacing out learning sessions and periodically reviewing earlier content can help ensure that all information is retained more evenly.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>The Recency Effect has been well-documented in psychological research, particularly in studies of memory and learning. Hermann Ebbinghaus's experiments demonstrated that people are more likely to remember items at the end of a list, confirming the effect's robustness.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A student remembers the last points covered in a lecture more clearly than those in the middle."</li>
        <li>"In a sales pitch, the closing argument sticks in the client's mind more than the earlier points."</li>
        <li>"A juror recalls the final statements of a lawyer more vividly than the rest of the case."</li>
        <li>"A consumer remembers the last item seen in a shopping list more clearly than the earlier ones."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"The last thing I heard is the most important."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should consider all the information, not just what was last mentioned."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'RE'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  