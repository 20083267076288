<template>
    <div>
      <h1>Blind Spot Bias</h1>
  
      <h3>You recognize the impact of biases on others but fail to see how they influence your own thinking.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Blind spot bias refers to the tendency to recognize cognitive biases in others while being unaware of how those same biases affect our own thinking. This bias can lead to overconfidence in our own judgment and decision-making abilities, as we believe we are less susceptible to biases than others.</p>
      <p>This bias can be particularly harmful in situations where self-awareness and objectivity are crucial, such as leadership, education, and personal development. By failing to recognize our own biases, we are more likely to make flawed decisions and perpetuate misunderstandings.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Blind spot bias can lead to overconfidence in decision-making, as individuals believe they are less biased than others. This can result in poor judgment, flawed decisions, and a lack of self-improvement. In leadership and management, blind spot bias can hinder effective decision-making and lead to a lack of accountability for mistakes.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The primary cause of blind spot bias is a lack of self-awareness and a tendency to view oneself more favorably than others. This bias is often reinforced by social and cultural norms that emphasize individualism and self-confidence. Additionally, cognitive dissonance can exacerbate blind spot bias, as individuals may be reluctant to acknowledge their own biases due to the discomfort it causes.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To prevent blind spot bias, it’s important to cultivate self-awareness and regularly reflect on your own thinking and decision-making processes. Seeking feedback from others and engaging in critical self-reflection can help identify and address blind spots. Additionally, practicing intellectual humility and being open to the possibility of being wrong can help mitigate this bias.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research in social psychology has shown that blind spot bias is a pervasive issue in human cognition. A study by Pronin, Lin, and Ross (2002) found that individuals consistently rated themselves as less susceptible to biases than others, highlighting the prevalence of this bias. This research underscores the importance of self-awareness and critical reflection in overcoming blind spot bias.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A manager believes they are fair and objective in their decision-making, while criticizing others for being biased.</li>
        <li>A teacher assumes they are free of biases in grading, while pointing out bias in student evaluations.</li>
        <li>A person criticizes their friend for stereotyping others, without recognizing their own tendency to stereotype.</li>
        <li>A researcher believes their methodology is free of bias, while pointing out flaws in others' research.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I’m more objective than others in my decisions."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I need to be aware of my own biases just as much as I notice them in others."</div>
      </div>
  
      <MenuBottomRB v-bind:current="'BSB'"></MenuBottomRB>
    </div>
  </template>
  
  <script>
    import MenuBottomRB from '../../components/MenuBottomRB.vue';
  
    export default {
      components: {
        MenuBottomRB,
      }
    }
  </script>
  