<template>
  <div>
    <h1>Ingroup Bias</h1>

    <h3>You favor members of your own group over those in outgroups.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Ingroup Bias, also known as ingroup favoritism, is the tendency to favor members of one's own group over those who are not part of that group. This bias can manifest in various forms, such as giving preferential treatment, forming positive impressions, and exhibiting greater trust towards ingroup members.</p>
    <p>This bias is rooted in social identity theory, where individuals derive a sense of self-esteem and belonging from their group memberships. The stronger the identification with a group, the more likely one is to exhibit ingroup bias. While this can foster group cohesion, it can also lead to discrimination and exclusion of outgroup members.</p>
    <p>Ingroup Bias can be observed in many areas of life, including workplace dynamics, social interactions, and even in the formation of societal norms. It can perpetuate social divides and contribute to conflict between different groups, as people may unjustly favor their own group while marginalizing others.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The impact of Ingroup Bias can be significant, leading to unfair treatment of outgroup members and perpetuating social inequalities. In the workplace, this bias can result in favoritism, nepotism, and a lack of diversity. In social settings, it can reinforce stereotypes and contribute to prejudice.</p>
    <p>On a broader scale, Ingroup Bias can influence societal structures, where certain groups are systematically privileged over others. This can lead to institutionalized discrimination, where policies and practices unfairly benefit the ingroup while disadvantaging the outgroup.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Ingroup Bias is driven by the need for social identity and belonging. People are naturally inclined to form groups, and once these groups are established, there is a tendency to favor those within the group to strengthen social bonds. This is often exacerbated by competition with other groups, where loyalty to the ingroup is seen as essential.</p>
    <p>Cultural and historical factors also play a role in reinforcing Ingroup Bias. Long-standing rivalries, cultural norms, and societal values can all contribute to the persistence of this bias. Additionally, limited exposure to outgroup members can lead to a lack of understanding and empathy, further entrenching the bias.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>Addressing Ingroup Bias requires increasing awareness of the bias and its effects. Encouraging interactions between different groups and promoting inclusive environments can help reduce the bias. Education that emphasizes the common humanity of all people and highlights the arbitrary nature of group distinctions can also mitigate the effects of Ingroup Bias.</p>
    <p>Organizations can foster diversity and inclusion by implementing policies that counteract favoritism and promote equal opportunities for all members. Encouraging empathy and perspective-taking can also help individuals recognize the biases they hold and make more equitable decisions.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research in social psychology, such as studies by Henri Tajfel and colleagues, has shown that even arbitrary group distinctions can lead to Ingroup Bias. In experiments where participants were randomly assigned to different groups, they still displayed favoritism towards their group, despite the lack of meaningful differences between the groups.</p>
    <p>Further research has demonstrated that Ingroup Bias is not limited to negative treatment of outgroup members but also includes positive favoritism towards ingroup members. This can lead to a "double-edged sword" where the benefits of group cohesion come at the cost of fairness and equality.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>In a workplace setting, a manager might give preferential treatment to employees who share their cultural background, even if other employees are equally or more qualified.</li>
      <li>In social settings, people might form cliques based on shared interests or identities, excluding those who do not belong to the same group.</li>
      <li>In politics, voters might support candidates from their own community or demographic group, regardless of the candidates' qualifications or policies.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"It's natural to support my own group over others."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While it's normal to feel connected to my group, I should strive to treat everyone fairly, regardless of group membership."</div>
    </div>

    <MenuBottomSTT v-bind:current="'IB'"></MenuBottomSTT>
  </div>
</template>

<script>
  import MenuBottomSTT from '../../components/MenuBottomSTT.vue';

  export default {
    components: {
      MenuBottomSTT,
    }
  }
</script>
