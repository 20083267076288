<template>
    <div>
      <h1>Fundamental Attribution Error</h1>
  
      <h3>You overemphasize personal characteristics and ignore situational factors in judging others' behavior.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Fundamental Attribution Error is a common cognitive bias where people tend to attribute others' actions to their character rather than to external circumstances. For example, if someone cuts you off in traffic, you're more likely to think they're a bad driver rather than consider they might be rushing due to an emergency.</p>
      <p>This bias often leads to misunderstandings and misjudgments, as it overlooks the significant influence of situational factors on behavior. It can make us unfairly critical of others while excusing our own actions under similar circumstances by blaming external factors.</p>
      <p>Understanding the Fundamental Attribution Error can help us cultivate more empathy and patience in our interactions with others, as we learn to consider the broader context of people's actions.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Fundamental Attribution Error can have significant social consequences. It often leads to negative judgments and conflicts in personal and professional relationships. For example, in the workplace, a manager might attribute an employee's poor performance to laziness, ignoring external pressures or challenges the employee might be facing.</p>
      <p>This bias can also exacerbate cultural misunderstandings, as people might attribute differences in behavior or customs to character flaws rather than recognizing the influence of different cultural norms and environments.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The Fundamental Attribution Error arises from our brain's tendency to favor simplicity over complexity. It's easier to attribute behavior to a person's character because it provides a straightforward explanation, rather than considering the multitude of situational factors that could be at play.</p>
      <p>This bias is also fueled by a lack of information. When we don't know the full context of someone's situation, we're more likely to default to character-based explanations. Additionally, social and cultural norms that emphasize personal responsibility and individualism can reinforce this bias.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To prevent the Fundamental Attribution Error, it's essential to practice empathy and consider multiple perspectives before forming judgments about others' behavior. Taking the time to ask questions, gather more information, and reflect on possible situational influences can help mitigate this bias.</p>
      <p>Mindfulness practices that encourage awareness of our thought processes can also reduce the tendency to make snap judgments. By slowing down and reflecting on the reasons behind someone's actions, we can arrive at a more balanced understanding of their behavior.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research on the Fundamental Attribution Error has shown that this bias is prevalent across cultures, although its strength can vary depending on cultural norms. For example, studies have found that Western cultures, which emphasize individualism, are more prone to this bias compared to Eastern cultures that value collectivism and situational context.</p>
      <p>One classic study by Ross, Amabile, and Steinmetz (1977) demonstrated this bias by showing how participants underestimated the influence of situational factors in a quiz game, overestimating the quizmaster's intelligence based on their role alone.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>When someone is late to a meeting, you assume they are irresponsible, not considering that they might have been stuck in traffic.</li>
        <li>You see a student struggling with a subject and conclude that they are not smart, rather than considering they might be dealing with personal issues affecting their performance.</li>
        <li>In a courtroom, jurors might attribute a defendant's behavior to their personality, overlooking the impact of environmental factors like poverty or abuse.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"That person is rude for cutting in line."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Maybe they're in a rush because they're having a bad day or an emergency."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"My colleague is so lazy for missing the deadline."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Perhaps they're overwhelmed with work or personal issues I don't know about."</div>
      </div>
  
      <MenuBottomPB v-bind:current="'FAE'"></MenuBottomPB>
    </div>
  </template>
  
  <script>
    import MenuBottomPB from '../../components/MenuBottomPB.vue';
  
    export default {
      components: {
        MenuBottomPB,
      }
    }
  </script>
  