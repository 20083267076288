<template>
  <div>
    
    <h1>
      About
    </h1>

    <div style="height:20px;"></div>

    <p>Thinking Bugs website is a resource for those looking to improve their mental health and well-being through the use of Cognitive Behavioral Therapy (CBT). On the site, visitors can find descriptions and examples of the 13 classical thinking errors, also known as cognitive distortions. These distortions are common patterns of thinking that can lead to negative emotions and behaviors.</p>

    <p>To help users identify and overcome negative thought patterns, the website also offers a privacy-focused CBT journaling app. This app provides a tool for journaling and reframing thoughts in a more positive and healthy way. By using the app, users can learn to recognize thinking bugs in their thoughts and take steps to rewrite and reframe them. Whether you are looking to learn more about cognitive distortions or want to work on improving your mental health through CBT, the Thinking Bugs website and journaling app are valuable resources.</p>

    <p>At Thinking Bugs, we utilize the garden and computer metaphors to better understand how these "bugs" can affect the mind and its functioning.</p>

    <p>In the garden metaphor, bugs refer to negative thoughts and behaviors that can take over and dominate the mind if it is not properly cared for. These "bugs" can be thought of as weeds that grow and spread in the garden of the mind, hindering the growth of positive thoughts and behaviors.</p>

    <p>Similarly, in the computer metaphor, bugs refer to errors or glitches in the system that can cause problems and hinder the performance of the computer. In the mind, these "bugs" can be negative thought patterns or thinking errors that disrupt and hinder optimal functioning. Just as a computer requires regular maintenance to fix bugs and ensure proper functioning, the mind also needs care and attention to identify and address negative thought patterns.</p>

    <p>If you are new to CBT, we recommend seeking additional resources before using our CBT journaling app. While our website provides helpful information, it is not intended to fully explain the practice of CBT.</p>

    <div style="height:25px;"></div>

    
    <h1>CBT App</h1>
    <div style="height:20px;"></div>

      <p>
        The Thinking Bugs CBT App is an offline-first progressive web app (PWA) - it can be installed on most mobile and desktop devices and used even without the internet.
      </p>

      <p>
        The app is a digital version of the classical CBT practice, based on the <a href="https://feelinggood.com/tag/daily-mood-log/" target="_blank">mood logging technique</a>, as described and popularized by <a href="https://en.wikipedia.org/wiki/David_D._Burns" target="_blank">David D. Burns</a> and <a href="https://en.wikipedia.org/wiki/Aaron_T._Beck" target="_blank">Aaron T. Beck</a>.
      </p>

      <p>
        The CBT App is privacy-focused by design. Your records are saved on your local device only - they are never sent over the internet. There are no accounts. If you delete your records, they are deleted permanently.
      </p>

      <p>
        You can export your records, and import them later if you want to have them on a different device or another browser. Export allows to keep 
        records' archive or to share them with other people.
      </p>

      <p>
        When you import records from a backup, the existing data in the app (if any) is deleted and replaced with new records from the imported archive.
      </p>

      <div style="height:25px;"></div>
    <p><b>DISCLAIMER</b></p>

    <p style="text-transform:uppercase;">
      Some descriptions and examples of cognitive distortions on this website have been partially generated by the ChatGPT AI engine and subsequently reviewed by a human editor to ensure accuracy.
      <br><br>
      The ideas and suggestions provided are not intended to replace professional medical advice. Always consult your physician or a qualified healthcare provider regarding any matters related to your health.
    </p>

    <div style="height:10px;"></div>


    <!-- <menu-bottom ></menu-bottom> -->
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>