<template>
  <div>
    <h1>Cognitive Dissonance</h1>

    <h3>You feel discomfort from holding conflicting beliefs or behaviors.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Cognitive Dissonance is a psychological phenomenon where individuals experience discomfort or tension when they hold two or more conflicting beliefs, values, or attitudes. This discomfort often leads to attempts to reduce the dissonance, either by changing one of the conflicting beliefs, acquiring new information that outweighs the dissonant beliefs, or reducing the importance of one of the beliefs.</p>
    <p>This concept is central to understanding how people justify decisions or behaviors that are inconsistent with their beliefs. Cognitive Dissonance can drive individuals to rationalize their actions, even when those actions contradict their values or when they are aware that their decisions are not in their best interest.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Cognitive Dissonance can lead to a range of behaviors, from justifying unethical actions to maintaining harmful habits despite knowing the risks. In the long term, unresolved cognitive dissonance can contribute to stress, anxiety, and mental health issues. In social contexts, it can lead to strained relationships as individuals seek to resolve dissonance by avoiding or discrediting opposing viewpoints.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This phenomenon is caused by the inherent discomfort of holding conflicting beliefs or engaging in behaviors that contradict one’s values. The need for consistency in thoughts, beliefs, and actions is a fundamental aspect of human psychology, and when this consistency is disrupted, individuals feel compelled to resolve the conflict. External pressures, such as social expectations or cultural norms, can also intensify the experience of cognitive dissonance.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To manage Cognitive Dissonance, it’s important to be aware of the underlying beliefs that may be in conflict and to address them directly. Open-mindedness and critical self-reflection can help in resolving dissonance by allowing individuals to adjust their beliefs or behaviors in a way that aligns with their values. Engaging in honest dialogue with others can also help in identifying and resolving cognitive dissonance.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Festinger (1957) introduced the theory of Cognitive Dissonance, exploring how people strive for internal consistency. Subsequent studies, such as those by Harmon-Jones and Mills (1999), examined how Cognitive Dissonance influences decision-making and behavior, highlighting its role in various psychological processes, including justification and rationalization.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person who values honesty but lies to protect a friend might experience cognitive dissonance, leading them to justify the lie as being for a good cause.</li>
      <li>Someone who is committed to a healthy lifestyle but smokes might downplay the risks of smoking to reduce the discomfort of their conflicting behavior.</li>
      <li>A consumer buys a product they can't afford and later experiences cognitive dissonance, leading them to justify the purchase as necessary or deserved.</li>
      <li>A student who cheats on an exam might convince themselves that everyone else is cheating too, reducing the dissonance caused by acting against their values.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I know this isn’t right, but I have to do it."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I need to find a way to act in line with my values, even if it’s difficult."</div>
    </div>

    <MenuBottomCD v-bind:current="'CD'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
