<template>
  <div>
    <h1>Self-Handicapping</h1>

    <h3>You create obstacles to your own success as an excuse for potential failure.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Self-Handicapping is a cognitive distortion where individuals create obstacles to their success, providing themselves with an excuse if they fail. This behavior is driven by the fear of failure and the desire to protect one's self-esteem. By sabotaging their own efforts, individuals can avoid confronting the possibility that they might not be capable or competent enough to succeed.</p>
    <p>This cognitive bias often manifests in procrastination, setting unattainable goals, or neglecting preparation for important tasks. For instance, a student might wait until the last minute to study for an exam, knowing that if they perform poorly, they can blame their lack of time rather than their intellectual abilities.</p>
    <p>While Self-Handicapping can provide short-term relief from anxiety, it ultimately leads to underperformance, missed opportunities, and a perpetuation of self-doubt. Over time, this behavior can become a self-fulfilling prophecy, where the individual repeatedly fails to reach their potential due to the obstacles they place in their own path.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Self-Handicapping has significant implications for personal and professional development. It often leads to chronic underachievement, as individuals consistently undermine their own efforts. This behavior can also create tension in relationships, as others may perceive the individual as unreliable, unmotivated, or unwilling to take responsibility for their actions.</p>
    <p>In the workplace, Self-Handicapping can result in missed deadlines, poor performance, and a lack of career advancement. It can also hinder collaboration, as team members may become frustrated with the individual's lack of commitment or follow-through. Over time, this behavior can damage one's reputation and limit opportunities for growth and success.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Self-Handicapping is often caused by a deep-seated fear of failure and a desire to protect one's self-esteem. Individuals may engage in this behavior as a defense mechanism to avoid confronting their own limitations or inadequacies. Perfectionism, low self-esteem, and a fear of judgment can also contribute to this cognitive distortion.</p>
    <p>Cultural and social factors can exacerbate Self-Handicapping, particularly in environments that place a high value on success and achievement. In such contexts, individuals may feel immense pressure to succeed, leading them to create excuses for potential failure as a way to preserve their self-worth.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To overcome Self-Handicapping, it's essential to focus on effort rather than outcomes and to develop a growth mindset. Setting realistic goals, breaking tasks into manageable steps, and seeking support from others can help reduce the need to create obstacles. Building self-confidence through small, incremental successes can also diminish the fear of failure and encourage a more proactive approach to challenges.</p>
    <p>Practicing self-compassion and learning to accept imperfections can also help prevent Self-Handicapping. By recognizing that failure is a natural part of the learning process, individuals can reduce the pressure they place on themselves and approach challenges with greater resilience and determination.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Berglas and Jones (1978) provided early evidence of Self-Handicapping, showing how individuals would sabotage their own performance to protect their self-esteem. In their study, participants who were given the opportunity to choose between a performance-enhancing drug and a performance-impairing drug before taking a test were more likely to choose the impairing drug if they were unsure of their abilities, providing them with an excuse for potential failure.</p>
    <p>Further research by Uysal and Lu (2018) examined the role of Self-Handicapping in academic settings, finding that students who engaged in this behavior were more likely to experience academic difficulties and lower levels of well-being. The study also highlighted the importance of addressing Self-Handicapping early on to prevent long-term negative outcomes.</p>
    <p>Another study by Midgley, Arunkumar, and Urdan (1996) explored the relationship between Self-Handicapping and academic achievement, demonstrating that this behavior is associated with lower grades, decreased motivation, and a reduced likelihood of pursuing challenging tasks. The findings emphasize the need for interventions that promote a growth mindset and resilience in the face of failure.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A student procrastinates on studying for an exam, knowing that if they perform poorly, they can blame their lack of time rather than their abilities.</li>
      <li>An athlete avoids rigorous training, using an injury as an excuse for a potential loss, rather than admitting they were outperformed.</li>
      <li>An employee sets overly ambitious goals, knowing they are unlikely to achieve them, to avoid criticism for failing to meet more realistic expectations.</li>
      <li>A writer waits until the last minute to start a project, so they can attribute any negative feedback to the rush rather than their writing skills.</li>
      <li>A manager deliberately assigns themselves too many tasks, so that if a project fails, they can blame their workload rather than their leadership abilities.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"If I don’t try too hard, I have an excuse if I fail."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should give my best effort, regardless of the outcome, so I can learn and improve."</div>
    </div>
    
    <div class="space-thoughts"></div> 

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"It’s better to not try at all than to risk failing."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Taking risks and facing challenges head-on will help me grow and achieve my goals."</div>
    </div>

    <MenuBottomCD v-bind:current="'SH'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
