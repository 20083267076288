<template>
  <div>
    <h1>Dunning-Kruger Effect</h1>

    <h3>Overestimating one's abilities or knowledge in areas where one is less competent.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Dunning-Kruger Effect is a cognitive bias where individuals with limited knowledge or competence in a specific area overestimate their own abilities or understanding. This bias leads people to believe they are more knowledgeable or skilled than they actually are, often dismissing the expertise of others.</p>
    <p>This bias is particularly problematic because those affected by it are often unaware of their own lack of competence, making them resistant to learning or improvement. Recognizing the Dunning-Kruger Effect is essential for fostering humility and a willingness to seek out and value expert knowledge and feedback.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Dunning-Kruger Effect can have significant negative consequences, particularly in decision-making and learning. For example, individuals who overestimate their abilities may take on tasks or roles for which they are not qualified, leading to poor performance and potentially harmful outcomes. In education, this bias can hinder learning, as students who believe they already understand a topic may not engage with the material or seek help when needed.</p>
    <p>This bias can also contribute to conflicts in the workplace, where individuals who overestimate their competence may challenge or dismiss the input of more knowledgeable colleagues, leading to poor team dynamics and decision-making.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Dunning-Kruger Effect is often driven by a lack of self-awareness and metacognition, where individuals are unable to accurately assess their own knowledge or abilities. This bias can be exacerbated by overconfidence, where past successes or a lack of negative feedback reinforce an inflated self-perception. Additionally, the illusion of understanding can arise from a superficial engagement with complex topics, where individuals believe they understand more than they actually do.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract the Dunning-Kruger Effect, it is important to cultivate self-awareness and seek out honest feedback from others. Engaging in continuous learning, especially in areas where one has limited experience, can help individuals recognize the limits of their knowledge and appreciate the value of expertise. Encouraging a culture of humility and open-mindedness can also reduce the impact of this bias in group settings.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>The Dunning-Kruger Effect was first identified by psychologists David Dunning and Justin Kruger in a 1999 study, which found that individuals with lower competence in a specific area were more likely to overestimate their abilities. This study, titled "Unskilled and Unaware of It," highlighted the paradox that those with the least knowledge are often the most confident in their understanding. Further research by Dunning and others has confirmed that this bias is widespread and can affect a wide range of domains, from academic performance to professional expertise.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A novice chess player believes they are highly skilled after winning a few games, underestimating the complexity of the game and the skill level of experienced players.</li>
      <li>An employee overestimates their ability to manage a project, ignoring the advice of more experienced colleagues and leading to project delays and issues.</li>
      <li>A student assumes they understand a complex topic after reading a summary, leading them to perform poorly on an exam that requires deeper knowledge.</li>
      <li>A person gives health advice to others based on a few articles they read online, overestimating their understanding of the topic and potentially spreading misinformation.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’ve got this all figured out; there’s nothing more I need to learn."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While I feel confident, I should seek feedback and continue learning to ensure I fully understand this topic."</div>
    </div>

    <MenuBottomCD v-bind:current="'DKE'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
