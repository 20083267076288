<template>
  <div>
    <h1>Pseudocertainty Effect</h1>

    <h3>You take on more risk when decisions are framed in a certain way.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Pseudocertainty Effect is a cognitive bias where individuals make riskier decisions when outcomes are framed as certain, even if they are not. This effect occurs because people prefer certainty over uncertainty and are willing to take greater risks to achieve what they believe to be a certain outcome, even when the risk is not justified.</p>
    <p>This bias often manifests in scenarios where choices are presented in a way that emphasizes the elimination of risk or guarantees a particular result, leading individuals to overlook the actual probabilities involved. It can influence decisions in finance, healthcare, and everyday life, leading to choices that might not align with rational risk assessment.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Pseudocertainty Effect can lead to suboptimal decision-making, particularly in high-stakes situations where risk management is crucial. In finance, it might cause investors to pursue risky strategies based on perceived guarantees, leading to significant losses. In healthcare, patients might choose treatments with uncertain outcomes because they are framed as more certain, potentially leading to poorer health outcomes.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is driven by the human preference for certainty and the discomfort with ambiguity. When faced with uncertainty, individuals are more likely to be swayed by presentations that offer the illusion of control or guaranteed outcomes, even if those guarantees are not supported by the facts.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract the Pseudocertainty Effect, it’s important to critically evaluate the framing of decisions and to focus on the actual probabilities and risks involved. Asking questions about the underlying assumptions and seeking out multiple perspectives can help in making more rational, less biased decisions.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Kahneman and Tversky (1981) on decision framing first identified the Pseudocertainty Effect, showing how individuals are influenced by the way choices are presented, particularly when it comes to risk. Their studies highlighted the importance of framing in decision-making and how it can lead to irrational choices when people perceive certainty where there is none.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An investor chooses a risky financial product because it is presented as having a "guaranteed" high return, despite the fine print indicating otherwise.</li>
      <li>A patient opts for an aggressive medical treatment because it is framed as having a high success rate, without considering the potential risks and side effects.</li>
      <li>A person buys an extended warranty for a product because they believe it eliminates all risk, even though the chances of needing it are low.</li>
      <li>Consumers are more likely to purchase a product advertised as "risk-free" or with a "money-back guarantee," even when the actual terms are restrictive.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This option is guaranteed, so it’s worth the risk."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should carefully examine the actual risks and not be swayed by promises of certainty."</div>
    </div>

    <MenuBottomCD v-bind:current="'PCE'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
