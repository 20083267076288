<template>
  <div>
    <h1>Motivated Reasoning</h1>

    <h3>You rationalize your decisions based on what you want to believe.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Motivated Reasoning is a cognitive bias where individuals process information in a way that aligns with their desires and pre-existing beliefs, leading to biased decision-making. Instead of objectively analyzing facts, people selectively gather, interpret, and recall information that supports their preferred outcomes.</p>
    <p>This bias can lead to self-deception, where individuals convince themselves that their decisions are rational and well-founded, even when they are based on subjective or biased reasoning. Motivated Reasoning can also contribute to polarization, as people become entrenched in their views, dismissing opposing evidence.</p>
    <p>Understanding Motivated Reasoning is key to fostering more balanced decision-making and recognizing when personal biases may be influencing judgments.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Motivated Reasoning can have significant effects on personal and collective decision-making. In politics, it can lead to extreme partisanship, where individuals only accept information that supports their political views. In personal life, this bias can cause people to make decisions that satisfy their desires but are not necessarily in their best interest.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Motivated Reasoning is driven by the desire to protect one’s self-image and the need for cognitive consistency. When confronted with information that contradicts their beliefs or desires, individuals experience discomfort and are motivated to resolve this dissonance by justifying their existing views. Emotional attachment to outcomes and the fear of being wrong also play a role in reinforcing this bias.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Motivated Reasoning, it's important to actively seek out and consider opposing viewpoints and to engage in critical self-reflection. Practicing intellectual humility and being open to changing one's mind in light of new evidence can help mitigate this bias. Additionally, adopting a mindset that values truth over being right can lead to more balanced reasoning.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Kunda (1990) explored the concept of Motivated Reasoning, showing how people's desires and emotions can influence the cognitive processes they use to evaluate information. More recent studies, such as those by Taber and Lodge (2006), have demonstrated how this bias contributes to political polarization, with individuals interpreting information in a way that aligns with their pre-existing beliefs.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person downplays the health risks of smoking because they enjoy the habit and don’t want to quit.</li>
      <li>In a political debate, an individual dismisses credible evidence against their preferred candidate, insisting it’s biased or irrelevant.</li>
      <li>An investor holds onto a failing stock because they are emotionally attached to the company, ignoring signs that it’s time to sell.</li>
      <li>A person convinces themselves that a decision they made was the right one, even when new evidence suggests otherwise, because they don’t want to admit they were wrong.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This evidence doesn’t fit with what I believe, so it must be flawed."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"If this evidence contradicts my beliefs, I should investigate it further and be open to adjusting my views."</div>
    </div>

    <MenuBottomJE v-bind:current="'MR'"></MenuBottomJE>
  </div>
</template>

<script>
  import MenuBottomJE from '../../components/MenuBottomJE.vue';

  export default {
    components: {
      MenuBottomJE,
    }
  }
</script>
