<template>
  <div>
    <h1>All or Nothing Thinking</h1>

    <h3>You look at things in absolute, black-and-white categories.</h3>

    <h4>Explained</h4>

    <p>All or Nothing thinking, also known as Black and White thinking or Dichotomous thinking, is a cognitive distortion that involves rigidly categorizing situations, people, or events as either good or bad, right or wrong, and with no room for nuance or complexity. This type of thinking can lead to negative emotions and can hinder problem-solving and decision-making.</p>

    <p>For example, if someone engages in All or Nothing thinking, they may believe that they are either a complete success or a complete failure. They may view their performance on a task as either perfect or a complete disaster, with no room for any shades of gray. This can lead to feelings of disappointment and discouragement if they are not able to meet their own high standards.</p>

    <p>While a little exaggeration can be beneficial in some cases, such as increasing creativity or motivation, All or Nothing thinking becomes harmful when it becomes an automatic mind pattern and we see the world through the black and white lens without being aware of it. When combined with other cognitive distortions, such as Jumping to Conclusions and the Mental Filter, it can lead to extreme emotions and self-defeating conclusions.</p>


    <p>Here are some examples of All or Nothing thinking:</p>

    <ul>
      <li>"I didn't get the promotion at work, so I must be a complete failure."</li>
      <li>"I didn't do well on this test, so I'm not smart at all."</li>
      <li>"I can't do anything right, so I might as well not even try."</li>
      <li>"I didn't eat perfectly healthy today, so I've ruined my whole diet."</li>
      <li>"I made one mistake, so I must be incompetent."</li>
    </ul>

    <p>All or Nothing thinking can also involve categorizing people in extreme ways, such as believing that someone is either a hero or a villain, with no middle ground. This type of thinking can lead to conflicts and misunderstandings in relationships, as it fails to take into account the complexity of human behavior and the fact that people can have both positive and negative qualities.</p>

    <p>Here are some additional examples of All or Nothing thinking:</p>

    <ul>
      <li>Believing that if you can't do something perfectly, it's not worth doing at all. For example, a person may believe that if they can't complete a task without making any mistakes, they shouldn't do it at all.</li>

      <li>Believing that everything must be either right or wrong, with no room for differing opinions or perspectives. This can lead to a narrow-minded view of the world and an inability to consider alternative viewpoints.</li>

      <li>Believing that something is either good or bad, with no room for shades of gray. For example, a person may believe that their job is either the best in the world or the worst, with no room for any other perspective.</li>

      <li>Believing that you must be either completely happy or completely unhappy, with no room for any other emotions. This can lead to an inability to acknowledge and cope with a range of emotions, which can be an important part of healthy mental functioning.</li>
    </ul>

    <p>It's important to recognize that All or Nothing thinking is a cognitive distortion because it oversimplifies and distorts reality. The reality is often more nuanced and the truth is usually somewhere in between the two extremes. It's also subjective to each individual, so what one person sees as an absolute may not be the same for another.</p>

    <p>The "black and white" worldview is subjective to each individual. When other people, not part of the individual's All or Nothing Thinking belief system, observe the same situations, they do not make similar self-defeating conclusions, as the individual, doing the error.</p>

    <h4>Example</h4>
    <p>
      Original:
      <br>
      "I'm such an idiot, I can't believe I messed up that presentation in front of everyone. I'm never going to get promoted now."
    </p>

    <p>
      Rewritten:
      <br>
      "I made a mistake during my presentation, but that does not define my intelligence as a whole. I will work to learn from this experience and continue to strive for professional growth and development."
    </p>


    <CBTAppText v-bind:current="'AoN'"></CBTAppText>

    <menu-bottom v-bind:current="'AoN'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>