<template>
  <div>
    <div class="errors-list box">
      <!-- Content -->

      <h1>
        CBT Thinking Errors
      </h1>

      <h3 style="font-size:15px;margin-top:30px;" class="links-content">
        
        Cognitive Behavioral Therapy (CBT) is a scientific, well-tested, most proven, and most effective logical approach for challenging our distorted thoughts and transforming them from intrusive and unhelpful into rational and beneficial.

        <br><br>

        The irrational and often negative thoughts are common to different people. These thoughts are classified into different types, named together as cognitive distortions, also known as thinking errors or <a href="https://twitter.com/thinkingbugs/status/1182119140267569154" target="_blank">thinking bugs</a>.

        By deconstructing and changing our irrational thoughts, we can greatly improve our life quality, performance, general well-being and solve many psychological issues, such as anxiety, panic attacks, depression, OCD, PTSD, ADHD, and similar.

        <br><br>
        The list of classical cognitive distortions is presented below. These thinking bugs can be spotted in our thought patterns and fixed using the <a href="/cbt-app" target="_blank">CBT App</a>.

        <br><br>
        
      </h3>

      <div>
        
        <div class="te-div">
          <router-link to="/all-or-nothing-thinking">
            <div class="icon-div">
              AoN
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/all-or-nothing-thinking" class="link bug-title">
              All or Nothing Thinking
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You look at things in absolute, black-and-white categories.
            </span>
          </div>
        </div>
        
        <div class="te-div">
          <router-link to="/magnification-or-minimization">
            <div class="icon-div">
              MoM
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/magnification-or-minimization" class="link bug-title">
              Magnification or Minimization
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You blow things way out of proportion or shrink them.
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/mental-filter">
            <div class="icon-div">
              MF
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/mental-filter" class="link bug-title">
              Mental Filter
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You dwell on the negatives and ignore the positives.
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/discounting-positives">
            <div class="icon-div">
              DP
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/discounting-positives" class="link bug-title">
              Discounting Positives
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You insist your positive qualities don't count.
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/labeling">
            <div class="icon-div">
              Lab
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/labeling" class="link bug-title">
              Labeling
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              Instead of saying, “I made a mistake,” you tell yourself, “I'm a jerk” or “I'm a loser.”
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/personalization">
            <div class="icon-div">
              Per
            </div>
          </router-link>  
          <div class="icon-text">
            <router-link to="/personalization" class="link bug-title">
              Personalization
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You consider negative or irrelevant events as having something to do with you.
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/fortune-telling">
            <div class="icon-div">
              FT
            </div>
          </router-link>
            <div class="icon-text">
              <router-link to="/fortune-telling" class="link bug-title">
                Fortune Telling
              </router-link>
              <br>
              <div style="height:5px;"></div>
              <span class="small" style="text-decoration:none!important;">
                You jump to conclusions not warranted by the facts.
              </span>
            </div>
        </div>

        <div class="te-div">
          <router-link to="/mind-reading">
            <div class="icon-div">
              MR
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/mind-reading" class="link bug-title">
              Mind Reading
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You assume that people are reacting negatively to you.
            </span>
          </div>
        </div>


        <div class="te-div">
          <router-link to="/overgeneralization">
            <div class="icon-div">
              Ovg
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/overgeneralization" class="link bug-title">
              Overgeneralization
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You view a single negative event as a never-ending pattern of defeat.
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/should-statements">
            <div class="icon-div">
              SS
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/should-statements" class="link bug-title">
              Should Statements
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You use “shoulds,” “shouldn'ts,” “musts,” “oughts,” and “have tos.”
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/self-blame">
            <div class="icon-div">
              SB
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/self-blame" class="link bug-title">
              Self Blame
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You blame yourself for something you weren't entirely responsible for.
            </span>
          </div>
        </div>

        <div class="te-div">
          <router-link to="/other-blame">
            <div class="icon-div">
              OB
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/other-blame" class="link bug-title">
              Other Blame
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You blame others and overlook ways you contributed to the problem.
            </span>
          </div>
        </div>
        
        <div class="te-div">
          <router-link to="/emotional-reasoning">
            <div class="icon-div">
              ER
            </div>
          </router-link>
          <div class="icon-text">
            <router-link to="/emotional-reasoning" class="link bug-title">
              Emotional Reasoning
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small">
              You reason from your feelings: “I feel like an idiot, so I must be one.”
            </span>
          </div>
        </div>

      </div>


      <div style="clear:both;height:40px;"></div>

    </div>

    <div class="box additional-bugs">
      <h1>Additional Thinking Bugs</h1>

      <div style="height:25px;"></div>
      Below you will find additional thinking bugs that extend beyond the traditional CBT thinking errors. These categories encompass various cognitive distortions, logical fallacies, and other biases that can distort our thinking, influence our behavior, and impact our decision-making. Click on each category to explore the different thinking errors.

      <div style="height:20px;"></div>

      <div>
        <a href="#cd"><strong>Cognitive Distortions</strong></a> - Patterns of biased thinking that distort reality and often lead to negative emotions.<br><br>
        <a href="#lf"><strong>Logical Fallacies</strong></a> - Errors in reasoning that weaken arguments and often mislead us in discussions or debates.<br><br>
        <a href="#je"><strong>Judgment Errors</strong></a> - Biases that affect decision-making processes, leading to incorrect or suboptimal decisions.<br><br>
        <a href="#stt"><strong>Social Thinking Traps</strong></a> - Cognitive biases influenced by social dynamics and group behavior, leading to poor decisions or misconceptions.<br><br>
        <a href="#ep"><strong>Emotional Pitfalls</strong></a> - Thinking errors driven by strong emotions, often leading to irrational decisions or perceptions.<br><br>
        <a href="#pb"><strong>Perceptual Biases</strong></a> - Errors in how we perceive and interpret reality, often leading to misunderstandings or illusions.<br><br>
        <a href="#bt"><strong>Behavioral Traps</strong></a> - Biases that influence actions, leading to unproductive or harmful behaviors.<br><br>
        <a href="#rb"><strong>Reasoning Bugs</strong></a> - Subtle errors in logic and critical thinking that undermine sound reasoning.
        <!-- <a href="#md"><strong>Memory Distortions</strong></a> - Errors related to memory recall or retention, affecting how we remember events or information.<br><br>
        <a href="#dmf"><strong>Decision-Making Fallacies</strong></a> - Mistakes made during the decision-making process, often leading to poor outcomes. -->
      </div>
      
    </div>

    

    <span id="cd"></span>
    <div style="height:20px;"></div>
    <MenuBottomCD :home="true"></MenuBottomCD>

    
    <span id="lf"></span>
    <div style="height:20px;"></div>
    <MenuBottomLF :home="true"></MenuBottomLF>


    <span id="je"></span>
    <div style="height:20px;"></div>
    <MenuBottomJE :home="true"></MenuBottomJE>
    

    <span id="stt"></span>
    <div style="height:20px;"></div>
    <MenuBottomSTT :home="true"></MenuBottomSTT>

    
    <span id="ep"></span>
    <div style="height:20px;"></div>
    <MenuBottomEP :home="true"></MenuBottomEP>
    

    <span id="pb"></span>
    <div style="height:20px;"></div>
    <MenuBottomPB :home="true"></MenuBottomPB>


    <span id="bt"></span>
    <div style="height:20px;"></div>
    <MenuBottomBT :home="true"></MenuBottomBT>
    

    <span id="rb"></span>
    <div style="height:20px;"></div>
    <MenuBottomRB :home="true"></MenuBottomRB>
    

    <!-- <span id="md"></span>
    <div style="height:20px;"></div>
    <MenuBottomMD :home="true"></MenuBottomMD> -->


    <!-- <span id="dmf"></span>
    <div style="height:20px;"></div>
    <MenuBottomDMF :home="true"></MenuBottomDMF> -->


    <div style="height:80px;"></div>

  </div>
</template>


<script>
  import MenuBottomCD from '../components/MenuBottomCD.vue';
  import MenuBottomLF from '../components/MenuBottomLF.vue';
  import MenuBottomJE from '../components/MenuBottomJE.vue';
  import MenuBottomSTT from '../components/MenuBottomSTT.vue';
  import MenuBottomEP from '../components/MenuBottomEP.vue';
  import MenuBottomPB from '../components/MenuBottomPB.vue';
  import MenuBottomBT from '../components/MenuBottomBT.vue';
  import MenuBottomRB from '../components/MenuBottomRB.vue';
  import MenuBottomMD from '../components/MenuBottomMD.vue';
  import MenuBottomDMF from '../components/MenuBottomDMF.vue';

export default {

  components: {
    MenuBottomCD,
    MenuBottomLF,
    MenuBottomJE,
    MenuBottomSTT,
    MenuBottomEP,
    MenuBottomPB,
    MenuBottomBT,
    MenuBottomRB,
    MenuBottomMD,
    MenuBottomDMF
  },

  data: function () {
    return {
      
    }
  },

};
</script>


<style>
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: linear-gradient(to bottom, #d3d3d3, #ffffff);
  pointer-events: none; /* Allow interaction with underlying content */
}
</style>