<template>
  <div>
    <h1>Egocentric Bias</h1>

    <h3>You exaggerate the importance of your own role or contributions.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Egocentric Bias is a cognitive distortion where individuals overestimate the significance of their own role or contributions in events or outcomes. This bias leads people to view themselves as the central figure in situations, often downplaying or ignoring the contributions of others. It is driven by the natural human tendency to focus on oneself as the center of one’s own experience.</p>
    <p>This bias can affect decision-making, relationships, and perceptions of fairness, as individuals may believe they deserve more credit or blame than they actually do. Egocentric Bias can also lead to conflicts, as others may perceive the individual as self-centered or dismissive of others' contributions.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The impact of Egocentric Bias can be seen in both personal and professional settings. It can lead to misunderstandings and resentment in relationships, as others may feel undervalued or overlooked. In group work, it can result in poor collaboration and conflict, as individuals may overestimate their own contributions while underestimating those of their teammates. Over time, this bias can also lead to a skewed sense of self-worth and difficulty in accepting feedback.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is caused by the natural human focus on the self as the center of one’s own experience. It is reinforced by cognitive processes that emphasize personal involvement in events and by socialization practices that encourage individualism. Additionally, cultural factors that emphasize personal achievement and self-reliance can exacerbate Egocentric Bias.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Egocentric Bias, it's important to practice perspective-taking and to actively recognize the contributions of others. Engaging in reflective practices, such as journaling or seeking feedback, can help in identifying and addressing this bias. Developing a habit of acknowledging and appreciating the efforts of others can also reduce the impact of Egocentric Bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Ross and Sicoly (1979) explored Egocentric Bias in group work, showing how individuals overestimate their own contributions and underestimate those of others. Their study found that this bias often leads to conflicts in team settings, as individuals fail to recognize the collaborative nature of success. The findings highlight the importance of fostering a team-oriented mindset to reduce the effects of this bias.</p>
    <p>Further studies by Kruger and Gilovich (2004) examined how Egocentric Bias influences self-perception and decision-making. Their research demonstrated that individuals often overestimate their influence on positive outcomes and downplay their role in negative ones. This bias can lead to unrealistic self-assessments and hinder personal growth and development.</p>
    <p>Additional research by Heider (1958) explored the attributional processes that contribute to Egocentric Bias. Heider's work laid the foundation for understanding how individuals attribute successes to their own efforts and failures to external factors, reinforcing the tendency to view oneself as central to all situations.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A team member believes they did most of the work on a project, even though their colleagues contributed equally.</li>
      <li>A person takes credit for the success of a group effort, ignoring the roles played by others.</li>
      <li>An individual blames themselves entirely for a failed relationship, overlooking the factors and actions of the other person involved.</li>
      <li>Someone insists they were the main reason a social event was successful, downplaying the contributions of the host and other guests.</li>
      <li>A leader attributes the success of an organization solely to their own vision, ignoring the hard work and dedication of their employees.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This wouldn’t have happened without me."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I played a role in this, but so did others, and their contributions were equally important."</div>
    </div>
    
    <div class="space-thoughts"></div> 

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I must have been the reason for the failure."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should consider all the factors, including those outside my control, that contributed to this outcome."</div>
    </div>

    <MenuBottomCD v-bind:current="'EB'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
