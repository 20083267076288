<template>
  <div>

    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Decision-Making Fallacies List</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Decision-Making Fallacies</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Decision-making fallacies are mistakes that occur during the decision-making process, often leading to suboptimal or poor outcomes. These fallacies can be driven by biases, over-reliance on certain thinking styles, or an inability to adequately assess risks and benefits. Recognizing these fallacies is essential for making better, more informed decisions in both personal and professional contexts.
      <div style="height:40px;"></div>
    </div>

    <!-- Pro-Innovation Bias -->
    <div v-if="current != 'PIB'">
      <router-link to="/pro-innovation-bias">
        <div class="footer-div">
          PIB
        </div>
        <div class="footer-div-label">
          Pro-Innovation Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overvalue an innovation's benefits while underestimating its limitations or risks.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        PIB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Pro-Innovation Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- System 1 and System 2 Thinking -->
    <div v-if="current != 'S12'">
      <router-link to="/system-1-and-system-2-thinking">
        <div class="footer-div">
          S12
        </div>
        <div class="footer-div-label">
          System 1 and System 2 Thinking
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You rely on fast, intuitive thinking (System 1) or slow, deliberate reasoning (System 2), sometimes leading to flawed decisions when one system dominates inappropriately.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        S12
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        System 1 and System 2 Thinking
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
