<template>
  <div>
    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Social Thinking Traps List</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Social Thinking Traps</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Social thinking traps are cognitive biases deeply influenced by social dynamics and group behavior. These biases can lead to poor decisions, reinforce stereotypes, and contribute to the perpetuation of misconceptions. When individuals are part of a group, they might prioritize harmony and conformity over critical thinking, leading to decisions that can be flawed or even harmful. By understanding these social thinking traps, we can better navigate group interactions and make more informed, independent decisions.
      <div style="height:40px;"></div>
    </div>

    <!-- Groupthink -->
    <div v-if="current != 'GT'">
      <router-link to="/groupthink">
        <div class="footer-div">
          GT
        </div>
        <div class="footer-div-label">
          Groupthink
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You prioritize group consensus over critical analysis, often leading to poor decisions.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        GT
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Groupthink
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Bandwagon Effect -->
    <div v-if="current != 'BE'">
      <router-link to="/bandwagon-effect">
        <div class="footer-div">
          BE
        </div>
        <div class="footer-div-label">
          Bandwagon Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You adopt beliefs or actions because others are doing the same.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        BE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Bandwagon Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Ingroup Bias -->
    <div v-if="current != 'IB'">
      <router-link to="/ingroup-bias">
        <div class="footer-div">
          IB
        </div>
        <div class="footer-div-label">
          Ingroup Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You favor members of your own group over those in outgroups.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        IB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Ingroup Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Outgroup Homogeneity -->
    <div v-if="current != 'OH'">
      <router-link to="/outgroup-homogeneity">
        <div class="footer-div">
          OH
        </div>
        <div class="footer-div-label">
          Outgroup Homogeneity
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You perceive members of an outgroup as more similar to each other than they really are.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        OH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Outgroup Homogeneity
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Stereotyping -->
    <div v-if="current != 'ST'">
      <router-link to="/stereotyping">
        <div class="footer-div">
          ST
        </div>
        <div class="footer-div-label">
          Stereotyping
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You generalize characteristics, motives, or behaviors to an entire group.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        ST
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Stereotyping
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Stereotype Threat -->
    <div v-if="current != 'STH'">
      <router-link to="/stereotype-threat">
        <div class="footer-div">
          STH
        </div>
        <div class="footer-div-label">
          Stereotype Threat
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You feel at risk of conforming to stereotypes about your social group.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        STH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Stereotype Threat
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Moral Credential Effect -->
    <div v-if="current != 'MCE'">
      <router-link to="/moral-credential-effect">
        <div class="footer-div">
          MCE
        </div>
        <div class="footer-div-label">
          Moral Credential Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You are more likely to engage in morally questionable behavior after establishing your moral credentials.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        MCE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Moral Credential Effect
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
