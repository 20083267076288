<template>
  <div>
    <h1>Actor-Observer Bias</h1>

    <h3>You attribute your own actions to external factors, while blaming others for their actions.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Actor-Observer Bias is a cognitive distortion where individuals attribute their own behavior to external factors while attributing others' behavior to internal characteristics. This bias leads people to excuse their own actions by citing situational factors, while holding others accountable for their actions based on their perceived personality traits or intentions.</p>
    <p>This bias is driven by the tendency to view oneself as a product of circumstances, while viewing others as autonomous agents responsible for their actions. It can lead to double standards in judgment and may cause conflicts in relationships, as individuals may be quick to criticize others while justifying their own behavior.</p>
    <p>Actor-Observer Bias can also hinder self-awareness and personal growth, as it prevents individuals from taking responsibility for their actions and learning from their mistakes.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The impact of Actor-Observer Bias can be seen in both personal and professional settings. It can lead to misunderstandings and conflicts in relationships, as individuals may feel unfairly judged or criticized. In the workplace, this bias can result in poor teamwork and collaboration, as colleagues may attribute failures to others' incompetence while excusing their own shortcomings. Over time, this bias can also hinder self-reflection and personal development, as individuals may avoid taking responsibility for their actions.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is caused by the natural human tendency to view oneself as the center of one’s own experience and to focus on external factors when explaining one’s own behavior. It is reinforced by cognitive processes that emphasize situational explanations for personal actions, while focusing on dispositional explanations for others' behavior. Additionally, cultural and social norms that emphasize individual responsibility can exacerbate Actor-Observer Bias.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Actor-Observer Bias, it's important to practice self-reflection and to consider how others might perceive your actions. Developing empathy and trying to see situations from others' perspectives can help reduce this bias. Additionally, actively questioning your assumptions and seeking feedback from others can help in recognizing and addressing this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Jones and Nisbett (1972) provided early evidence of Actor-Observer Bias, showing how individuals attribute their own behavior to situational factors while attributing others' behavior to dispositional factors. Further studies by Storms (1973) explored how this bias influences social interactions and conflict resolution, highlighting its role in miscommunication and relationship difficulties.</p>
    <p>Later research by Malle (2006) examined the underlying mechanisms of Actor-Observer Bias, focusing on how perspective-taking and empathy can reduce the impact of this bias. Malle's work emphasizes the importance of considering others' perspectives and recognizing the situational factors that influence behavior.</p>
    <p>A study by Taylor and Koivumaki (1976) explored the role of Actor-Observer Bias in conflict resolution, finding that individuals who are aware of this bias are more likely to engage in constructive dialogue and find mutually beneficial solutions. The findings suggest that addressing Actor-Observer Bias can lead to better outcomes in both personal and professional relationships.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person attributes their own lateness to traffic, while blaming a colleague's lateness on laziness or poor time management.</li>
      <li>Someone excuses their own anger by citing stress, while viewing others' anger as a sign of a bad temper.</li>
      <li>A student blames their poor performance on a difficult exam, while attributing another student's failure to a lack of effort or intelligence.</li>
      <li>An individual justifies their own unethical behavior by citing external pressures, while condemning others for similar actions based on perceived moral failings.</li>
      <li>A manager excuses their own lack of communication by blaming a busy schedule, while criticizing an employee for being unresponsive.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"They messed up because they’re careless, but I had a good reason for what I did."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Maybe they were dealing with difficult circumstances too. I should consider that before judging."</div>
    </div>
    
    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I was late because of traffic, but they were late because they’re irresponsible."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"We were both late, and traffic might have been a factor for them too. I should be more understanding."</div>
    </div>

    <MenuBottomCD v-bind:current="'AOB'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
