<template>
  <div>
    <h1>
      Mind Reading
    </h1>
    
    <h3>You assume that people are reacting negatively to you.</h3>

    <h4>
      Explained
    </h4>

    <p>Mind reading is a cognitive distortion that occurs when we assume we know what someone else is thinking or feeling without actually verifying it with them. This type of thinking can lead to misunderstandings and conflicts in relationships, as we may act on assumptions that turn out to be incorrect.</p>

    <p>
      Examples of mind reading include:
      <br>
      <ul>
        <li>
          Assuming that someone is upset with us because they didn't smile at us when we saw them
        </li>
        <li>
          Thinking that a colleague is judging us because they didn't respond to our email as quickly as we would have liked
        </li>
        <li>
          Believing that a friend is ignoring us because they didn't return our phone call
        </li>
      </ul>
    </p>

    <p>Mind reading is a problematic thinking pattern because it can lead to misunderstandings and conflicts in relationships. When we assume we know what someone else is thinking or feeling, we may act on those assumptions and behave in ways that are not helpful or appropriate. For example, if we assume that someone is upset with us because they didn't smile at us, we may become defensive or upset ourselves, leading to a negative spiral of negative emotions.</p>

    <p>To address the mind reading cognitive distortion, it's important to recognize when we are making assumptions about what someone else is thinking or feeling. We can then actively seek out clarification and verification by asking the other person directly. By communicating openly and honestly with others, we can avoid misunderstandings and build stronger, more supportive relationships.</p>

    <p>It's also helpful to practice mindfulness and pay attention to our own thoughts and emotions. This can help us to recognize when we are making assumptions about others and intervene to change our thinking patterns. Seeking support from a therapist or other mental health professional can also be helpful in addressing cognitive distortions and improving our relationships.</p>

    <h4>Example</h4>

    <p>Example of a thought with the mind reading error: "She didn't return my text right away, so she must be mad at me."</p>

    <p>Rewritten (reframed) thought: "I'm not sure why she didn't return my text right away. It could be because she's busy or has other things on her mind. I will ask her directly if there's anything going on or if she's upset with me."</p>

    <p>In the first example, the person is making an assumption about what the other person is thinking or feeling based on limited information (the lack of a timely response to a text message). This assumption is then interpreted as a negative conclusion (that the person is mad at them).</p>

    <p>The rewritten (reframed) thought takes a more open-minded and curious approach, recognizing that there could be multiple reasons for the other person's behavior and seeking out clarification rather than jumping to a negative conclusion. This reframed thought is less likely to lead to misunderstandings or conflicts in the relationship.</p>

    <CBTAppText v-bind:current="'MR'"></CBTAppText>

    <menu-bottom v-bind:current="'MR'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>