<template>
  <div>
    <h1>Illusion of Control</h1>

    <h3>You overestimate your ability to control events.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Illusion of Control is a cognitive bias where individuals believe they have more influence over outcomes than they actually do. This bias manifests particularly in situations involving chance, randomness, or factors outside of one's control. For example, people might believe that their actions, such as choosing a lottery number or wearing a lucky item, can influence a completely random event.</p>
    <p>One reason for this bias is that humans inherently seek to control their environment as a means of survival and comfort. When we feel in control, we reduce our anxiety about uncertainty. This need for control can lead us to perceive causal relationships where none exist, giving us a false sense of security and predictability.</p>
    <p>The Illusion of Control can lead to overconfidence and sometimes reckless decision-making, especially in areas like investing, gambling, or health-related decisions, where the outcomes are influenced by many unpredictable factors. Recognizing this bias is key to developing a more realistic understanding of what we can and cannot control.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Illusion of Control can have far-reaching consequences, particularly in high-stakes areas such as finance and healthcare. For instance, an investor might believe they can predict market movements, leading to risky bets that can result in significant financial loss. Similarly, in health, an individual might ignore medical advice, believing that their personal habits or home remedies can influence serious medical outcomes.</p>
    <p>This bias can also impact personal relationships and decision-making. For instance, someone might take undue credit for a successful outcome in a team setting or blame themselves excessively when things go wrong, failing to account for external factors. This skewed perception of control can lead to both unnecessary guilt and misplaced confidence.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Illusion of Control is often rooted in the desire for predictability and security. When faced with uncertainty, humans prefer to believe that they can influence outcomes rather than accept randomness or lack of control. Past experiences where control was exerted successfully can also reinforce this bias, leading individuals to overgeneralize their sense of influence in unrelated situations.</p>
    <p>Cognitive biases such as the confirmation bias, where we tend to notice and remember events that confirm our beliefs, can also fuel the Illusion of Control. For instance, if someone wins a bet after performing a particular ritual, they may believe the ritual contributed to their success, even though it had no real effect.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To prevent the Illusion of Control, it's essential to cultivate awareness of the limits of one’s influence, particularly in situations involving chance or randomness. Engaging in critical thinking, seeking out diverse perspectives, and relying on evidence-based decision-making can help mitigate this bias. Additionally, practicing mindfulness can help individuals recognize when they are overestimating their control over outcomes.</p>
    <p>Another effective strategy is to use decision-making frameworks that explicitly account for uncertainty, such as probabilistic reasoning. By acknowledging the role of randomness and focusing on the factors you can control, you can reduce the impact of this bias. Seeking feedback from others can also help in providing a more objective assessment of your level of control in a given situation.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Langer (1975) demonstrated the Illusion of Control in a classic experiment where participants believed they had control over a lottery outcome despite its random nature. In this study, participants who were given the option to choose their own lottery numbers felt more confident about their chances of winning than those who were assigned numbers randomly, even though the outcome was purely based on luck.</p>
    <p>Subsequent studies, such as those conducted by Alloy and Abramson (1979), explored the relationship between the Illusion of Control and depression. Their findings suggested that individuals with depression are less likely to exhibit this bias, as they tend to have a more accurate perception of their lack of control in certain situations. This phenomenon, known as "depressive realism," contrasts with the overly optimistic perception of control seen in non-depressed individuals.</p>
    <p>Additional research by Thompson, Armstrong, and Thomas (1998) examined how this bias influences risk-taking behavior in gambling. Their studies showed that the Illusion of Control can lead to increased gambling persistence, as individuals believe they can influence outcomes through their actions, such as choosing specific numbers or timing their bets.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person believes that their choice of lottery numbers increases their chances of winning, despite the outcome being entirely random.</li>
      <li>A gambler feels confident that their betting strategy can influence the outcome of a game of chance, leading them to place larger bets than they should.</li>
      <li>An investor attributes a stock market gain to their personal insight rather than market trends or external factors, leading to overconfidence in future investments.</li>
      <li>A manager believes that their presence in the office prevents mistakes, leading them to micromanage their team unnecessarily.</li>
      <li>An individual relies on a lucky charm during a job interview, believing it will increase their chances of getting the job, even though their qualifications and performance are what matter.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"If I just follow this strategy, I can guarantee success."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I can do my best to influence the outcome, but I need to accept that some factors are beyond my control."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This ritual always brings me luck; it will work again."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While this ritual may comfort me, I should focus on what I can actually do to improve my chances."</div>
    </div>

    <MenuBottomCD v-bind:current="'IoC'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
