<template>
  <div>
    <h1>Circular Reasoning</h1>

    <h3>You assume the truth of the conclusion within the argument itself.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Circular Reasoning, also known as "begging the question," is a logical fallacy in which the conclusion of an argument is assumed in the premise. This creates a loop where the argument goes in circles, with no real evidence provided to support the conclusion. Instead of proving the point, the argument simply restates it in different words.</p>
    <p>This fallacy often occurs when the argument relies on a premise that is as questionable as the conclusion it seeks to prove. By assuming the truth of what needs to be proven, Circular Reasoning fails to advance the discussion or provide a solid basis for belief.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Circular Reasoning can be misleading because it gives the appearance of logic and reasoning without actually providing any real support for the argument. It can lead to confusion and make it difficult to critically evaluate the argument. In debates, this fallacy can derail the conversation, as it prevents meaningful discussion of the actual issues at hand.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Circular Reasoning often arises from a lack of awareness of the need for independent evidence to support an argument. It may also result from a deep-seated belief in the conclusion being argued, leading the individual to assume its truth without question. In some cases, Circular Reasoning is used deliberately to obscure the weakness of an argument and give the illusion of validity.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid Circular Reasoning, it is important to ensure that the premises of an argument provide independent support for the conclusion. This means questioning the assumptions underlying the argument and seeking evidence that does not rely on the conclusion itself. Additionally, being aware of this fallacy can help you recognize it in others’ arguments and respond appropriately.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Walton (2008) in "Informal Logic: A Pragmatic Approach" discusses the prevalence of Circular Reasoning in everyday argumentation and its implications for critical thinking. Walton emphasizes the importance of identifying and challenging circular arguments to improve the quality of discourse.</p>
    <p>A study by Macagno and Walton (2014) on argumentation schemes provides a detailed analysis of Circular Reasoning and offers strategies for recognizing and avoiding this fallacy in various contexts.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>"The Bible is true because it is the word of God, and we know that God exists because the Bible says so."</li>
      <li>"We must reduce taxes because it is the right thing to do. It is the right thing to do because taxes should be reduced."</li>
      <li>"He is trustworthy because he is an honest person."</li>
      <li>"Democracy is the best form of government because the majority rules, and the majority should rule because it’s the essence of democracy."</li>
      <li>"You must obey the law because it is illegal to break it."</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This is true because it’s obvious."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should provide independent evidence to support my claim instead of assuming it’s true."</div>
    </div>

    <MenuBottomLF v-bind:current="'CR'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
