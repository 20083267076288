<template>
  <div>
    <h1>Confirmation Bias</h1>

    <h3>You favor information that confirms your preexisting beliefs.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Confirmation Bias is a cognitive distortion where individuals favor, seek out, and interpret information in a way that confirms their preexisting beliefs or hypotheses. This bias affects how people gather and recall information, leading to skewed perceptions and reinforcing existing viewpoints.</p>
    <p>This bias can lead to the dismissal of evidence that contradicts one's beliefs, creating a feedback loop that strengthens those beliefs over time. It is particularly problematic in contexts requiring objective analysis, such as scientific research, journalism, and personal decision-making.</p>
    <p>Understanding Confirmation Bias is crucial for fostering open-mindedness and ensuring that decisions are based on a balanced assessment of all available information.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Confirmation Bias can have profound impacts on personal and societal levels. It contributes to polarization, as individuals become entrenched in their views and less open to alternative perspectives. In scientific research, it can lead to biased studies and flawed conclusions. In everyday life, it can cause people to make decisions based on incomplete or inaccurate information, leading to suboptimal outcomes.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Confirmation Bias is driven by the human need for cognitive consistency and the discomfort that comes from encountering information that contradicts one’s beliefs. Social and cultural influences also play a role, as people are more likely to align with the views of their peers or communities. The internet and social media can amplify this bias by creating echo chambers where only confirming information is encountered.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Confirmation Bias, it's important to actively seek out information and perspectives that challenge one's views. Engaging in critical thinking, questioning assumptions, and being open to changing one's mind in light of new evidence are key strategies. Additionally, discussing topics with a diverse range of people can help broaden understanding and reduce the impact of this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Nickerson (1998) explored the pervasive nature of Confirmation Bias, demonstrating how individuals systematically favor information that aligns with their beliefs. Studies by Lord, Ross, and Lepper (1979) further highlighted how Confirmation Bias affects the interpretation of evidence, showing that people tend to dismiss or downplay contradictory information even when presented with balanced evidence.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person who believes in a particular political ideology only follows news sources that support their views, ignoring or discrediting sources that present opposing perspectives.</li>
      <li>A scientist unintentionally designs an experiment to confirm their hypothesis rather than test it objectively, leading to biased results.</li>
      <li>An individual who believes in the effectiveness of a specific diet seeks out success stories and avoids reading about instances where the diet failed.</li>
      <li>A manager makes a decision based on their gut feeling and then selectively interprets subsequent data to confirm that their decision was correct.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This information confirms what I already know, so it must be true."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should consider evidence that challenges my views to ensure I’m not missing something important."</div>
    </div>

    <MenuBottomCD v-bind:current="'CB'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
