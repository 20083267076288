<template>
  <div>
    <h1>Reactive Devaluation</h1>

    <h3>You devalue proposals or solutions simply because they come from an opposing party.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Reactive Devaluation is a cognitive bias where individuals devalue proposals, ideas, or solutions simply because they come from an opposing party or perceived adversary. This bias occurs when people reject potentially beneficial solutions because they are overly focused on who is making the offer rather than the content of the proposal itself.</p>
    <p>This distortion is common in negotiations, conflicts, and political debates, where the identity of the proposer can overshadow the merit of the proposal. It often leads to missed opportunities for compromise and collaboration, as individuals may reject good ideas simply because they do not want to agree with the opposing side.</p>
    <p>Recognizing Reactive Devaluation is crucial for more productive interactions and decision-making. By focusing on the content of proposals rather than their source, individuals can avoid this bias and make more rational and beneficial decisions.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Reactive Devaluation can have serious consequences in both personal and professional settings. In negotiations, it can lead to deadlock, where parties refuse to agree on terms even when they are in their best interest. In politics, it can contribute to polarization, where individuals reject policies or initiatives simply because they are proposed by the "other side."</p>
    <p>This bias can also strain personal relationships, as individuals may dismiss helpful suggestions or compromises from family members or friends due to past conflicts or negative perceptions. Over time, Reactive Devaluation can lead to a breakdown in communication and trust, making it more difficult to resolve conflicts or reach mutually beneficial agreements.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Reactive Devaluation is driven by the natural human tendency to protect one’s own interests and to view the intentions of others with suspicion, especially in adversarial situations. It is also influenced by social and cultural factors that emphasize competition and winning over collaboration. In some cases, it may be reinforced by past experiences of betrayal or conflict, leading individuals to distrust proposals from perceived adversaries.</p>
    <p>Additionally, this bias can be exacerbated by group dynamics, where loyalty to one’s own group or team leads individuals to reject ideas from outsiders, even when those ideas could be beneficial. This can create an "us versus them" mentality, where the identity of the proposer becomes more important than the content of the proposal.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Reactive Devaluation, it is important to focus on the content of proposals and solutions rather than their source. This can be achieved by evaluating ideas based on objective criteria, seeking input from neutral third parties, and considering how the proposal aligns with your own goals and values. In negotiations, it can be helpful to separate the people from the problem and to approach the discussion with a collaborative mindset.</p>
    <p>Engaging in active listening and asking clarifying questions can also help in understanding the true merits of a proposal, rather than dismissing it outright due to its source. By fostering an open and respectful dialogue, individuals can reduce the impact of this bias and find common ground with others, even in adversarial situations.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Ross and Stillinger (1991) demonstrated the effects of Reactive Devaluation in conflict resolution. Their study found that individuals were more likely to reject proposals from perceived adversaries, even when those proposals aligned with their own interests. This research highlights the importance of focusing on the content of proposals rather than their source in order to make more rational decisions.</p>
    <p>Further studies by Maoz and colleagues (2002) explored how Reactive Devaluation affects peace negotiations, finding that individuals on both sides of a conflict were more likely to reject offers from the opposing side, even when those offers were objectively beneficial. Their findings underscore the challenge of overcoming this bias in high-stakes negotiations.</p>
    <p>A study by Curhan and Neale (2004) examined how Reactive Devaluation impacts negotiations in business settings, showing that individuals were more likely to reject offers from competitors, even when those offers would have led to mutual benefits. The research suggests that overcoming this bias requires a deliberate focus on the objective merits of the proposal, rather than the identity of the proposer.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>In a political debate, a politician rejects a policy proposal simply because it comes from the opposing party, despite the fact that the policy aligns with their own stated goals.</li>
      <li>During a business negotiation, a company dismisses a merger offer from a competitor, even though the merger would benefit both companies, because they do not want to be seen as "losing" to the competitor.</li>
      <li>In a family conflict, a person refuses to consider a compromise suggested by a relative they have had disagreements with in the past, even though the compromise would resolve the current issue.</li>
      <li>A student rejects a suggestion from a classmate they do not like, even though the suggestion would improve their group project.</li>
      <li>A manager dismisses an idea from an employee they have had conflicts with, even though the idea could increase productivity and profitability.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I can’t trust their idea because it comes from the other side."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should evaluate the idea based on its merits, regardless of who proposed it."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"If I agree with their proposal, it will look like I’m conceding."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Agreeing with a good idea is not a sign of weakness; it’s a sign of rational thinking."</div>
    </div>

    <MenuBottomCD v-bind:current="'RD'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
