<template>
  <div>
    <h1>Mere Ownership Bias</h1>

    <h3>You value things more simply because you own them.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Mere Ownership Bias, also known as the Endowment Effect, is a cognitive bias where individuals assign greater value to objects, products, or ideas simply because they own them. This bias leads people to overvalue their possessions compared to similar items owned by others, even when there is no objective reason for the higher valuation.</p>
    <p>This bias is prevalent in various aspects of life, from personal belongings to investments, and even in opinions and beliefs. It can cause individuals to hold onto possessions or ideas more tenaciously than is rational, sometimes leading to suboptimal decisions, such as refusing to sell a stock at a fair price or stubbornly defending a personal belief despite evidence to the contrary.</p>
    <p>Understanding the Mere Ownership Bias is crucial for making more rational decisions, particularly in situations involving buying, selling, or trading. By recognizing this bias, individuals can take steps to evaluate their possessions and ideas more objectively, leading to better financial and personal outcomes.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Mere Ownership Bias can have significant effects on financial decisions, such as in the real estate or stock markets, where individuals may overvalue their assets and miss out on opportunities to sell or trade them for more beneficial outcomes. In personal relationships, this bias can lead to conflicts, as people may place undue importance on their possessions, creating friction when others do not share the same valuation.</p>
    <p>This bias also plays a role in consumer behavior, where individuals may be more likely to hold onto items they purchase, even when they no longer need or use them. This can contribute to clutter, waste, and even financial loss. Additionally, Mere Ownership Bias can affect decision-making in organizations, where leaders may overvalue projects or ideas they developed, leading to resistance to change or innovation.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Mere Ownership Bias is driven by the psychological attachment people form with their possessions. Once something is owned, it becomes part of the individual's identity, leading to an inflated sense of its value. This bias is reinforced by emotional connections, memories, and the sense of loss aversion, where the pain of losing something owned is felt more acutely than the pleasure of gaining something new.</p>
    <p>Another contributing factor is the endowment effect, where ownership creates a sense of entitlement or specialness, making it difficult for individuals to part with their possessions, even when it is in their best interest to do so. This effect is further amplified by social and cultural norms that emphasize material wealth and ownership as a measure of success.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract Mere Ownership Bias, it’s important to approach decisions involving personal possessions with a more objective mindset. One strategy is to ask yourself whether you would still value the item as highly if you did not own it. This can help in making more rational decisions about whether to keep, sell, or trade possessions.</p>
    <p>Practicing minimalism and reducing attachment to material possessions can also help mitigate this bias. By focusing on experiences and relationships rather than objects, individuals can reduce the emotional attachment to their belongings and make more objective decisions. Regularly reassessing the value and usefulness of possessions can also help in preventing the buildup of unnecessary items due to Mere Ownership Bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Kahneman, Knetsch, and Thaler (1990) provided foundational evidence of the Mere Ownership Bias, demonstrating that people tend to place a higher value on items they own compared to identical items they do not own. Their experiments showed that individuals were reluctant to trade or sell their possessions, even when offered a fair price, highlighting the powerful influence of ownership on perceived value.</p>
    <p>Further research by Beggan (1992) explored how the Mere Ownership Bias extends to non-material possessions, such as ideas and opinions. His studies suggest that once people take ownership of a belief or idea, they are more likely to defend it and less likely to accept opposing viewpoints, even in the face of contradictory evidence.</p>
    <p>A study by Johnson, Haubl, and Keinan (2007) examined the role of emotional attachment in the Mere Ownership Bias, finding that the longer individuals own an item, the more attached they become, and the more likely they are to overvalue it. This research emphasizes the importance of recognizing and mitigating this bias, particularly in situations involving long-term ownership.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A homeowner refuses to sell their house for market value, believing it is worth more due to the emotional memories associated with it.</li>
      <li>An investor holds onto a declining stock, convinced of its future potential simply because they have owned it for a long time.</li>
      <li>A person overvalues a family heirloom, even though it has little monetary value and no one else is interested in it.</li>
      <li>A collector insists that their collection is unique and priceless, despite others offering similar items at lower prices.</li>
      <li>A writer defends a particular idea or theory, refusing to accept criticism, because they developed it and feel personally attached to it.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This is mine, so it must be worth more."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should evaluate this item based on its actual value, not just because I own it."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I can’t part with this; it’s too valuable to me."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While it has sentimental value, I need to consider whether keeping it is truly beneficial."</div>
    </div>

    <MenuBottomCD v-bind:current="'MOE'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
