<template>
    <div>
      <h1>Vertical Line Failure</h1>
  
      <h3>You persist with a failing strategy because it aligns with a linear approach, despite evidence it’s not working.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Vertical line failure occurs when an individual continues to follow a linear, step-by-step approach to solving a problem, even when it’s clear that this approach is not effective. This bias is driven by a deep-seated preference for order and structure, making it difficult for the person to abandon the failing strategy and consider alternative, potentially non-linear solutions.</p>
      <p>This bias is particularly prevalent in situations where people have invested significant time and effort into a particular method, leading them to persist with it even in the face of mounting evidence that it’s not working. The fear of abandoning a familiar process often outweighs the potential benefits of exploring new approaches.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Vertical line failure can lead to significant inefficiencies, missed opportunities, and failure to achieve desired outcomes. In business, this might manifest as a company sticking to a failing product strategy, resulting in financial losses. In personal decision-making, it could lead to persisting with ineffective habits or routines that hinder progress.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This bias stems from a preference for predictability and structure. The human brain is wired to favor linear, step-by-step processes because they are easier to follow and give a sense of control. Additionally, cognitive dissonance plays a role, as abandoning a strategy that one has invested in can be psychologically uncomfortable, leading to persistence with a failing approach.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To avoid vertical line failure, it’s essential to regularly evaluate the effectiveness of your strategies and be willing to pivot when necessary. Incorporating flexibility into your problem-solving approach, seeking out diverse perspectives, and being open to non-linear solutions can help overcome this bias. Additionally, adopting a mindset that values adaptability over rigidity can lead to more successful outcomes.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Studies in behavioral economics have highlighted the dangers of sticking to a failing strategy due to vertical line failure. For instance, research by Northcraft and Neale (1986) demonstrated that people are more likely to persist with a failing strategy when they have invested significant resources into it, even when presented with clear evidence that a change is needed.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A business continues to invest in a product that consistently underperforms because the development process follows a traditional linear model.</li>
        <li>Students persist in using ineffective study methods because they align with a familiar, step-by-step approach, despite poor results on exams.</li>
        <li>A dieter sticks to a failing diet plan because it follows a strict, linear regimen, ignoring alternative approaches that might be more effective.</li>
        <li>A project manager continues with a rigid timeline, even when it’s clear that the project is falling behind and needs a more flexible approach.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I need to stick to this plan because it's what I've always done."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"If this plan isn’t working, it’s okay to explore new approaches and find a better solution."</div>
      </div>
  
      <MenuBottomBT v-bind:current="'VLF'"></MenuBottomBT>
    </div>
  </template>
  
  <script>
    import MenuBottomBT from '../../components/MenuBottomBT.vue';
  
    export default {
      components: {
        MenuBottomBT,
      }
    }
  </script>
  