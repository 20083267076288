<template>
  <div>
    <h1>Overconfidence Effect</h1>

    <h3>You have excessive confidence in your own answers or judgments.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Overconfidence Effect is a cognitive bias where an individual’s subjective confidence in their judgments or abilities exceeds their objective accuracy. This bias can lead to overestimation of one’s knowledge, underestimation of risks, and poor decision-making, as the individual may act on beliefs that are not fully informed or accurate.</p>
    <p>This phenomenon is particularly common in situations requiring expertise or judgment, where the individual may not be aware of their limitations. Overconfidence can result in a failure to seek additional information, consult others, or consider alternative viewpoints, leading to suboptimal outcomes.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Overconfidence Effect can have significant impacts in various areas, from financial markets to everyday decision-making. In finance, it can lead to excessive risk-taking and investment losses. In professional settings, overconfident leaders may ignore valuable input from others, leading to flawed strategies and decisions. On a personal level, overconfidence can cause individuals to make unrealistic commitments or underestimate the challenges they face.</p>
    <p>Over time, the accumulation of poor decisions based on overconfidence can lead to a loss of credibility and trust, as others recognize the gap between the individual's confidence and actual performance.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Overconfidence is driven by several factors, including cognitive biases such as the Dunning-Kruger Effect, where individuals with lower ability in a domain are more likely to overestimate their competence. Confirmation bias also plays a role, as people tend to focus on information that supports their beliefs while ignoring contradictory evidence. Additionally, social and cultural factors that reward confidence and assertiveness can reinforce this bias.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To mitigate the Overconfidence Effect, it’s important to cultivate self-awareness and a realistic assessment of one’s abilities. Seeking feedback, considering alternative perspectives, and regularly reviewing past decisions to learn from mistakes can help reduce overconfidence. Additionally, adopting a mindset of humility and recognizing the limits of one’s knowledge can lead to more balanced and informed decision-making.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Lichtenstein, Fischhoff, and Phillips (1982) examined the Overconfidence Effect, demonstrating how people often overestimate the accuracy of their judgments. The study revealed that this bias is prevalent across various domains, from general knowledge to specific expertise areas. More recent research by Moore and Healy (2008) explored the causes and consequences of overconfidence, highlighting its impact on decision-making and performance in competitive environments.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An investor is certain about a stock’s future success, leading them to invest heavily without diversifying their portfolio, only to experience significant losses.</li>
      <li>A student overestimates their preparation for an exam, leading to poor performance because they didn’t study enough.</li>
      <li>A manager ignores team members’ input, confident that their strategy is the best, resulting in a project failure.</li>
      <li>A driver feels overly confident in their skills and takes unnecessary risks on the road, increasing the likelihood of an accident.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I know what I’m doing, so I don’t need to double-check."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"It’s worth taking the time to review and get feedback to ensure I’m making the right decision."</div>
    </div>

    <MenuBottomCD v-bind:current="'OE'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
