<template>
  <div>
    <h1>Halo Effect</h1>

    <h3>You let one positive trait influence your overall judgment of a person or thing.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Halo Effect is a cognitive bias where the perception of one positive quality leads to the assumption of other positive qualities. For example, if someone is physically attractive, people might also assume they are kind, intelligent, or capable, even without evidence for these traits. This effect causes people to overlook potential flaws or other attributes in favor of the one positive trait they notice.</p>
    <p>This fallacy often leads to distorted perceptions and unfair assessments, as individuals are judged not on their complete set of characteristics but on a single trait that overshadows everything else. The Halo Effect is commonly observed in situations like job interviews, marketing, and social interactions, where first impressions or prominent features can unduly influence opinions.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Halo Effect can lead to biased decision-making and judgments, resulting in unfair advantages or disadvantages for individuals based on superficial qualities. In the workplace, it can affect hiring, promotions, and performance evaluations, leading to decisions based more on appearance or likability than on actual skills or qualifications. In consumer behavior, it can influence purchasing decisions, as people may favor products or brands that have a strong positive association.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is often caused by the brain's tendency to make quick judgments based on limited information. The Halo Effect allows people to simplify complex assessments by relying on a single positive attribute to form an overall impression. Additionally, societal and cultural norms may reinforce this bias by valuing certain traits—such as attractiveness or charisma—more highly than others.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid the Halo Effect, it is important to take a more balanced and comprehensive view of individuals and situations. This involves consciously separating different attributes and evaluating each one on its own merits rather than allowing one trait to dominate your perception. Seeking input from others and being aware of your own biases can also help mitigate the influence of the Halo Effect.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Thorndike (1920) on the "Halo Effect" first identified this bias in the context of employee evaluations, showing that a single positive trait could influence overall judgments of performance. His findings have since been expanded upon in various studies, demonstrating the pervasive nature of this bias in different areas of life.</p>
    <p>A study by Nisbett and Wilson (1977) on "Telling More Than We Can Know: Verbal Reports on Mental Processes" further explores how people are often unaware of the Halo Effect influencing their judgments, making it a subtle but powerful bias in decision-making.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>"An attractive person is assumed to be more competent and trustworthy, even if there is no evidence to support these qualities."</li>
      <li>"A charismatic leader may be perceived as more effective or intelligent than they actually are because of their charm."</li>
      <li>"A popular brand may be assumed to produce higher-quality products, even if the quality of each product varies."</li>
      <li>"In job interviews, candidates with strong communication skills might be perceived as more qualified overall, despite lacking specific technical expertise."</li>
      <li>"A student who excels in one subject may be assumed to perform well in all subjects, regardless of their actual abilities."</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This person is great at this, so they must be great at everything."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should evaluate each of their qualities separately rather than letting one trait overshadow the others."</div>
    </div>

    <MenuBottomLF v-bind:current="'HE'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
