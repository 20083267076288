<template>
  <div>
    <h1>
      Mental Filter
    </h1>
    
    <h3>You dwell on the negatives and ignore the positives.</h3>

    <h4>Explained</h4>

    <p>Mental filter, also known as selective attention, is a type of cognitive distortion that involves focusing on a single negative aspect of a situation while ignoring all other positive or neutral aspects. This distorted thinking can lead to negative emotions and unhealthy behaviors, as individuals may become fixated on the negative aspect and overlook any potential solutions or opportunities for growth.</p>

    <p>One common example of a mental filter is when an individual only remembers the negative comments made by a manager during a performance review, while forgetting the positive feedback or constructive criticism. This can lead to feelings of inadequacy or low self-esteem, and may prevent the individual from seeing the value in their own skills and abilities.</p>

    <p>Another example of a mental filter is when an individual becomes fixated on a single mistake or failure, and ignores all of their past successes or accomplishments. This can lead to a negative self-image and a belief that they are not capable of achieving success.</p>

    <p>
      It is important to recognize when you may be using a mental filter, as this distorted thinking can have a negative impact on your mental health and well-being. To combat mental filters, it can be helpful to practice gratitude and focus on the positive aspects of a situation, seek out alternative perspectives, and challenge negative thoughts. Seeking support from a mental health professional can also be helpful in addressing cognitive distortions and improving overall well-being.
    </p>  

    <p>
      Here are a few more examples of mental filters:
      <ul>
        <li>A student only focuses on the one question they got wrong on a test, ignoring all of the other questions they answered correctly. This can lead to feelings of inadequacy and a belief that they are not a good student.</li>
      
        <li>An employee only remembers the negative feedback they received during a performance review, ignoring the praise they received for their hard work. This can lead to low self-esteem and a belief that they are not a valuable member of the team.</li>
        
        <li>A person only remembers the negative experiences they have had with a particular group of people, ignoring all of the positive interactions they have had. This can lead to negative stereotypes and a belief that all members of that group are negative or untrustworthy.</li>
        
        <li>An individual only remembers the negative aspects of a relationship, ignoring all of the positive times they have shared with their partner. This can lead to feelings of resentment and a belief that the relationship is not worth continuing.</li>
      </ul>
    </p>

    <p>It is important to note that cognitive distortions can be both positive and negative. A positive mental filter, sometimes referred to as "seeing things through rose-tinted glasses," involves having overly positive expectations that may ultimately lead to negative outcomes because they are unrealistic. On the other hand, a form of realistic optimism, or a "rationally positive" mental filter, can be beneficial in many ways and is considered normal and preferable.</p>

    <CBTAppText v-bind:current="'MF'"></CBTAppText>

    <menu-bottom v-bind:current="'MF'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>