<template>
  <div>
    <h1>Cognitive Miser</h1>

    <h3>You tend to think and solve problems in simple and less effortful ways.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The concept of the Cognitive Miser refers to the tendency of individuals to conserve cognitive energy by relying on mental shortcuts, or heuristics, instead of engaging in effortful, analytical thinking. While these shortcuts can be efficient, they often lead to biases and errors in judgment, as the mind seeks to minimize the effort required to process information.</p>
    <p>This phenomenon is a fundamental aspect of human cognition, where the brain, faced with complex and abundant information, tends to simplify decision-making processes. However, this can result in oversimplified solutions that overlook important details or lead to inaccurate conclusions.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>As a Cognitive Miser, an individual might rely on stereotypes, first impressions, or other heuristics to make decisions quickly, without considering all relevant information. This can lead to misjudgments, perpetuation of biases, and suboptimal decisions. In professional settings, it might result in poor problem-solving or decision-making processes that ignore critical factors.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This tendency is driven by the brain’s natural inclination to conserve cognitive resources and avoid the mental fatigue associated with deep thinking. Environmental factors, such as stress, time constraints, and cognitive overload, can exacerbate this tendency, leading individuals to default to simpler, less effortful thought processes.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To overcome the limitations of being a Cognitive Miser, it’s important to cultivate habits of critical thinking and reflection. Taking time to engage in deeper analysis, questioning initial assumptions, and being mindful of the reliance on heuristics can lead to more accurate and balanced decision-making. Additionally, creating environments that reduce cognitive overload and allow for thoughtful deliberation can help mitigate this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Fiske and Taylor (1984) introduced the concept of the Cognitive Miser, highlighting how individuals often rely on mental shortcuts to save cognitive effort. Their work showed that while these shortcuts are often necessary for navigating complex environments, they can lead to systematic errors in judgment. More recent studies by Kahneman (2011) in "Thinking, Fast and Slow" further explored the balance between intuitive and deliberate thinking, emphasizing the need to be aware of when heuristics might lead to flawed decisions.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An employer makes a hiring decision based on the first impression rather than carefully evaluating the candidate’s qualifications.</li>
      <li>A person chooses a familiar brand without considering other options, relying on brand recognition as a shortcut for quality.</li>
      <li>During a debate, an individual relies on popular slogans instead of engaging with the actual arguments presented.</li>
      <li>A student skips reading the full assignment and instead relies on summaries, missing important details that affect their understanding.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’ll just go with my gut on this."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"It’s worth taking a moment to think this through carefully before deciding."</div>
    </div>

    <MenuBottomCD v-bind:current="'CM'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
