<template>
  <div>
    <h1>
      Personalization
    </h1>
    
    <h4>Explained</h4>

    <p>Personalization is a cognitive distortion, or a pattern of thinking that is irrational and leads to negative emotions. It involves taking negative events or circumstances and believing that they are somehow related to or caused by the individual, even when there is no evidence to support this belief.</p>

    <p>For example, imagine that Alice is having a conversation with a colleague and the colleague suddenly gets angry and storms out of the room. Alice might start to believe that she said or did something wrong, and that the colleague's anger is somehow her fault. This belief would be an example of personalization, as it involves taking an external event (the colleague's anger) and attributing it to the individual's own actions or characteristics.</p>

    <p>Another example of personalization might involve a person who is struggling with a medical condition, and believes that their illness is a direct result of something they did wrong or a personal failing on their part. This belief could lead to feelings of guilt, shame, or self-blame, even if there is no evidence to support the idea that the person's illness is their own fault.</p>

    <p>Personalization can be a particularly harmful cognitive distortion, as it can lead to feelings of self-doubt, low self-esteem, and a sense of powerlessness. It can also cause people to blame themselves for things that are beyond their control, which can lead to unnecessary suffering.</p>

    <p>
      Here are a few more examples of personalization:
      <br>
      <ul>
        <li>A student gets a low grade on a test and believes that it is because they are not intelligent enough, rather than considering other factors such as not studying enough, not understanding the material, or being distracted during the test.</li>
      
        <li>A person is rejected for a job and believes that it is because they are not good enough, rather than considering other factors such as the employer's needs, the qualifications of other candidates, or the company's hiring process.</li>
      
        <li>A person gets into a car accident and believes that it is because they are a bad driver, rather than considering other factors such as the weather, road conditions, or the actions of other drivers.</li>
      
        <li>A person is criticized by a friend and believes that it is because they are not a good enough friend, rather than considering the possibility that the friend might be having a bad day or be upset about something else.</li>
      
        <li>A person has a disagreement with a partner and believes that it is because they are not a good enough partner, rather than considering the possibility that the disagreement might be due to a misunderstanding or a difference in opinion.</li>
      
        
      </ul>
    </p>

    <p>It is important to recognize that personalization can lead to negative thought patterns and emotions, and that it is often helpful to challenge these beliefs and consider other possible explanations for negative events or circumstances.</p>

    <p>There are a few reasons why personalization can be harmful. First, it can lead to feelings of low self-esteem and self-worth, as individuals may feel that they are responsible for things that are beyond their control. This can also lead to feelings of helplessness and hopelessness, as individuals may feel that they are unable to change their circumstances or the outcomes of events.</p>

    <p>In addition, personalization can lead to avoidance of responsibility and a tendency to deflect blame onto others. This can be harmful in relationships, as it can cause resentment and conflict. It can also be harmful in a professional setting, as it can lead to a lack of accountability and a lack of progress or improvement.</p>
    
    <p>To combat personalization, it can be helpful to challenge the thought patterns that lead to this distortion. This might involve questioning the evidence for the thought, looking for alternative explanations, and considering the impact of external factors on the situation. It can also be helpful to practice self-compassion, recognizing that everyone makes mistakes and that it is normal to have negative experiences from time to time. Seeking support from friends, family, or a mental health professional can also be beneficial in managing and overcoming personalization.</p>

    <CBTAppText v-bind:current="'Per'"></CBTAppText>

    <menu-bottom v-bind:current="'Per'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>