<template>
  <div>
    <h1>Discounting Positives</h1>

    <h3>You insist your positive qualities don’t count.</h3>

    <h4>
      <strong>Explained</strong>
    </h4>

    <p>Discounting Positives is a cognitive distortion that occurs when a person insists that their positive qualities or accomplishments don't count or are not important. This type of thinking error involves rejecting or devaluing positive experiences or aspects of oneself, and it can have a negative impact on a person's self-image and overall well-being.</p>

    <p>For example, someone who is experiencing the Discounting Positives cognitive distortion might downplay their successes or achievements, attributing them to luck or external factors rather than their own skills or abilities. They might also minimize the importance of their positive qualities or attribute them to others, rather than recognizing and valuing them in themselves.</p>

    <p>This type of distorted thinking can be harmful because it can lead to a negative self-image and low self-esteem. It can also limit a person's potential and prevent them from recognizing and building on their strengths and accomplishments.</p>

    <p>Discounting Positives cognitive distortion is similar to the <a href="/mental-filter" target="_blank">Mental Filter</a> in that they both involve a focus on the negative, but they have a subtle difference. While both involve a focus on the negative aspects of a situation, the Mental Filter thinking error involves ignoring or not being aware of the positives, while the Discounting Positives cognitive distortion involves seeing the positives but actively rejecting or devaluing them.</p>

    <p>To address the Discounting Positives cognitive distortion, it can be helpful to engage in cognitive-behavioral therapy (CBT) or other forms of therapy that can help a person recognize and challenge distorted thoughts. This can involve identifying the distorted thinking pattern, questioning the evidence for and against the thought, and reframing the thought in a more rational and balanced way.</p>

    <p>For example, someone who is discounting their positive qualities might benefit from identifying the evidence that supports their positive qualities, such as feedback from others or specific examples of their successes. They might also try to reframe their distorted thoughts by acknowledging their positive qualities and reminding themselves that they are deserving of recognition and appreciation.</p>

    <p>In summary, the Discounting Positives cognitive distortion is a type of thinking error that involves rejecting or devaluing positive experiences or aspects of oneself. It can have negative impacts on self-image and well-being, but it can be addressed through therapy and other strategies that help a person recognize and challenge distorted thoughts.</p>

    <h4>Example</h4>

    <p>Example of a thought with the Discounting Positives cognitive distortion: "I got a promotion at work, but it's probably just because my boss felt sorry for me, not because I'm actually good at my job."</p>

    <p>Rewritten/reframed thought: "I am proud of myself for getting a promotion at work. I have worked hard and have developed my skills, and my boss saw that and chose to recognize my efforts with a promotion. This is a valid accomplishment and I deserve to feel proud of myself for it."</p>

    <CBTAppText v-bind:current="'DP'"></CBTAppText>

    <menu-bottom v-bind:current="'DP'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>