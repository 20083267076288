<template>
    <div>
      <h1>Gambler's Fallacy</h1>
  
      <h3>You believe that past random events influence the likelihood of future ones.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Gambler's Fallacy is the mistaken belief that if something happens more frequently than normal during a given period, it will happen less frequently in the future (or vice versa). This is also known as the "Monte Carlo Fallacy" or "The Fallacy of the Maturity of Chances."</p>
      <p>For example, in games of chance like roulette, people often believe that if a red number has come up several times in a row, a black number is "due" to appear. In reality, the outcome of each spin is independent of previous spins, meaning the probability remains the same regardless of past results.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Gambler's Fallacy can lead to poor decision-making, particularly in gambling and other areas involving risk and probability. People might bet more heavily on an outcome they believe is "due," leading to significant financial losses when the anticipated change does not occur.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This fallacy stems from a cognitive bias known as the "law of small numbers," where people incorrectly believe that small samples should represent the underlying probability distribution. It also arises from an incorrect understanding of statistical independence, where individuals assume that past events can influence future outcomes in situations where they do not.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To avoid falling into the Gambler's Fallacy, it's crucial to remember that each event in a series of independent events is just that—independent. Understanding the concept of statistical independence and the true nature of probability can help you make more rational decisions. Taking a step back and considering whether past events actually influence future outcomes in the context of the situation can also be helpful.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research has shown that the Gambler's Fallacy is a widespread cognitive bias. A study by Croson and Sundali (2005) found that gamblers in a casino exhibited the fallacy frequently, particularly in games of roulette. The study observed that players often bet against a streak, believing that a different outcome was due, even though each spin of the roulette wheel was independent of previous spins.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A gambler believes that after flipping a coin and getting heads five times in a row, the next flip is more likely to be tails."</li>
        <li>"In a lottery, a player thinks that numbers that haven't been drawn in a while are 'due' to come up soon."</li>
        <li>"A person avoids investing in a stock that has been rising, assuming that a decline is imminent because it has gone up for so long."</li>
        <li>"Someone thinks that after losing several hands in a card game, they are 'bound' to win the next one."</li>
        <li>"A person believes that after a series of unlucky events, they are 'due' for some good luck."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"The roulette wheel has landed on red five times in a row, so black must be next."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Each spin of the roulette wheel is independent, so the odds of red or black are the same every time."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'GF'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  