<template>
    <div>
      <h1>Congruence Bias</h1>
  
      <h3>You tend to test hypotheses in a way that confirms your initial expectations.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Congruence bias occurs when we design tests or experiments in a way that only seeks to confirm our initial hypothesis rather than challenge it. This bias leads us to overlook alternative explanations or contrary evidence, reinforcing our original beliefs even when they might be flawed. It is a subtle but significant error in reasoning that can distort our understanding of reality.</p>
      <p>Unlike confirmation bias, which focuses on interpreting existing information to support our beliefs, congruence bias involves the active creation of scenarios that are likely to yield confirming evidence. This can result in a narrow and skewed view of the world, where we miss out on critical insights and fail to consider other possibilities.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The impact of congruence bias can be far-reaching, affecting fields such as science, medicine, and everyday decision-making. In scientific research, it can lead to experiments that are designed to confirm the researcher’s hypothesis rather than explore all possible outcomes, resulting in biased findings. In medicine, a doctor might test for diseases that align with their initial diagnosis, potentially overlooking other conditions that could explain the symptoms.</p>
      <p>In everyday life, congruence bias can lead to poor decision-making, as we tend to seek out information that aligns with our expectations while ignoring evidence to the contrary. This can reinforce misconceptions and prevent us from seeing the bigger picture.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>Congruence bias is often driven by cognitive laziness, where individuals prefer to stick with their initial beliefs rather than exert the mental effort required to challenge them. It can also be influenced by a desire for certainty, as people tend to feel more comfortable with information that confirms what they already believe.</p>
      <p>Additionally, social and cultural factors can play a role in reinforcing congruence bias. In environments where dissent or questioning is discouraged, individuals may be more likely to seek out confirming evidence rather than challenge the status quo.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>Preventing congruence bias requires a conscious effort to design tests and experiments that genuinely challenge our hypotheses. This means actively seeking out disconfirming evidence and being open to alternative explanations. In science, this might involve creating experiments that can falsify the hypothesis, rather than just confirm it.</p>
      <p>In everyday life, cultivating a mindset of curiosity and skepticism can help counteract congruence bias. By questioning our assumptions and considering a range of possibilities, we can develop a more accurate and nuanced understanding of the world.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research on congruence bias has highlighted its prevalence in various fields. For example, Wason (1960) demonstrated how individuals tend to seek out confirming evidence when testing their hypotheses, often overlooking alternative explanations. This research underscores the importance of adopting a critical and open-minded approach to hypothesis testing and decision-making.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A researcher designs an experiment that only tests for outcomes that support their theory, ignoring other potential results.</li>
        <li>A doctor orders tests that align with their initial diagnosis, missing signs of a different condition that could explain the patient’s symptoms.</li>
        <li>An investor looks for information that supports their decision to buy a particular stock, ignoring data that suggests it might not be a good investment.</li>
        <li>A teacher develops a lesson plan based on the assumption that all students learn the same way, overlooking alternative teaching methods that could benefit different learners.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I’ll design this test to confirm my theory."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should design this test to explore all possible outcomes, not just the ones that confirm my theory."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I’m sure I’m right, so I’ll just look for evidence that supports my belief."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"It’s important to consider all possibilities, even those that challenge my belief."</div>
      </div>
  
      <MenuBottomRB v-bind:current="'CB'"></MenuBottomRB>
    </div>
  </template>
  
  <script>
    import MenuBottomRB from '../../components/MenuBottomRB.vue';
  
    export default {
      components: {
        MenuBottomRB,
      }
    }
  </script>
  