<template>
    <div>
      <h1>Contrast Effect</h1>
  
      <h3>Your perception of something is influenced by comparisons to other things.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Contrast Effect is a cognitive bias that occurs when our perception of something is influenced by comparisons to other things, often leading us to see things as better or worse than they actually are. For example, if you look at a moderately expensive car right after seeing a very expensive one, the moderate car might seem like a good deal, even if it’s still overpriced.</p>
      <p>This bias can distort our judgments in many areas of life, from making purchasing decisions to evaluating people's performances. It's especially prevalent in situations where choices are presented sequentially, as the contrast between the options can overshadow their individual merits or flaws.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Contrast Effect can significantly impact decision-making in various contexts. In marketing, for example, retailers might place high-priced items next to moderately priced ones to make the latter appear more affordable. Similarly, during job interviews, the evaluation of one candidate can be heavily influenced by the performance of the previous candidate.</p>
      <p>This bias can also affect personal relationships, where people may compare their current partner to an idealized version of someone else, leading to dissatisfaction. Understanding the Contrast Effect is crucial for making more objective decisions, as it helps us recognize when our judgments are being unfairly influenced by comparisons.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The Contrast Effect is caused by our brain's tendency to simplify complex evaluations by relying on comparisons. When faced with multiple options, it's easier to judge them in relation to each other than to assess each one on its own merits. This cognitive shortcut can be helpful in some situations but often leads to skewed perceptions.</p>
      <p>Additionally, the Contrast Effect is reinforced by the way information is presented. Sequential presentation of options, common in retail and competitive environments, makes it difficult to evaluate items independently, as the memory of the previous option influences the perception of the current one.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To mitigate the Contrast Effect, it's important to evaluate options independently rather than in comparison with others. This can be done by setting clear criteria for judgment before being exposed to different options and by taking breaks between evaluations to reduce the impact of sequential presentation.</p>
      <p>Another strategy is to actively seek out information that allows for an objective comparison based on facts rather than perceptions. In contexts like shopping or hiring, consider all relevant factors and remember that initial impressions can be misleading due to this bias.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research on the Contrast Effect has shown that this bias can significantly influence consumer behavior and decision-making. A study by Parducci (1965) demonstrated how judgments of stimuli, like brightness or loudness, are affected by the presence of other stimuli, leading to skewed perceptions based on contrast rather than actual differences.</p>
      <p>Further research by Cialdini (1993) explored how the Contrast Effect is used in persuasion and sales tactics, showing that people are more likely to make favorable judgments about an option when it follows a less attractive alternative, even if the option is not objectively better.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A realtor shows you a run-down house before showing you a slightly overpriced one. The second house appears much more desirable by comparison.</li>
        <li>When buying clothes, a $50 shirt might seem cheap after looking at $200 jackets, even though $50 is still expensive for a shirt.</li>
        <li>In a job interview, a mediocre candidate may seem outstanding if they follow a particularly weak candidate, skewing the interviewer's judgment.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This car is a great deal because it's much cheaper than the other one I saw."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should evaluate this car on its own value and features, not just in comparison to the more expensive one."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This job candidate is excellent compared to the last one."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I need to assess this candidate based on their own qualifications, not just in comparison to others."</div>
      </div>
  
      <MenuBottomPB v-bind:current="'CE'"></MenuBottomPB>
    </div>
  </template>
  
  <script>
    import MenuBottomPB from '../../components/MenuBottomPB.vue';
  
    export default {
      components: {
        MenuBottomPB,
      }
    }
  </script>
  