<template>
  <div>
    <h1>System Justification</h1>

    <h3>You defend and justify the status quo, even at your own expense.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>System Justification is a cognitive bias where individuals defend and rationalize the status quo, often in ways that perpetuate social inequalities and justify existing power structures. This bias can lead to the acceptance of systems that are unfair or disadvantageous, as individuals develop beliefs and attitudes that justify the way things are.</p>
    <p>This phenomenon can occur at both individual and collective levels, where people accept societal norms, institutions, and policies even when they are harmful or unjust. The need for cognitive consistency and the comfort of familiarity often drive individuals to rationalize and support the current system, even when it conflicts with their personal interests or values.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>System Justification can lead to the perpetuation of social inequalities, as people may resist changes that could improve conditions for marginalized groups. It can also hinder social progress by maintaining the status quo and discouraging critical examination of existing systems. On a personal level, this bias can lead to acceptance of unfavorable conditions or the justification of one’s own disadvantages.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is driven by the human need for cognitive consistency and the desire to reduce uncertainty. By justifying the status quo, individuals can avoid the discomfort of acknowledging systemic injustices or the need for change. Socialization and cultural norms also play a role, as individuals internalize the beliefs and values of their society, leading them to support the existing system even when it is flawed.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract System Justification, it’s important to develop a critical perspective on societal norms and institutions. Questioning the fairness and effectiveness of existing systems, seeking out diverse viewpoints, and engaging in social activism can help challenge and change unjust systems. Additionally, fostering a mindset of openness to change and willingness to address social inequalities is key to overcoming this bias.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Jost and Banaji (1994) explored the psychological mechanisms underlying System Justification, showing how individuals often rationalize and defend the status quo to maintain a sense of stability and order. Studies by Kay and Friesen (2011) examined how System Justification affects perceptions of social and economic inequalities, revealing how this bias can lead to the acceptance of unjust systems.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An employee accepts unfair working conditions, believing that it’s just the way things are and that challenging the system would be futile.</li>
      <li>A person justifies gender inequality in their culture by arguing that traditional gender roles are natural and beneficial for society.</li>
      <li>A community resists changes to social policies that would benefit marginalized groups, defending the existing system as fair and just.</li>
      <li>An individual defends the economic system that disadvantages them, believing that success is simply a matter of hard work and merit.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This is just how things are, and there’s nothing I can do about it."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I can question the system and work towards changes that promote fairness and equality."</div>
    </div>

    <MenuBottomCD v-bind:current="'SJ'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
