<template>
  <div>
    <h1>False Cause</h1>

    <h3>You assume that because one event follows another, the first event must have caused the second.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The False Cause fallacy, also known as "post hoc ergo propter hoc" (Latin for "after this, therefore because of this"), occurs when someone assumes that because one event occurred after another, the first event must have caused the second. This logical error overlooks the possibility of coincidence or other factors that could have influenced the outcome.</p>
    <p>This fallacy is common in both everyday reasoning and more formal arguments. People often fall into this trap when they see a pattern or correlation and mistakenly believe it implies causation. However, just because two events are correlated does not mean one causes the other. Critical thinking and further investigation are necessary to establish a true causal relationship.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The False Cause fallacy can lead to faulty reasoning and poor decision-making. For example, in medical contexts, it can result in the incorrect attribution of a treatment’s effectiveness to a patient's recovery, when in fact the recovery could be due to other factors like natural healing or a placebo effect. In politics and social policy, this fallacy can lead to misguided laws or regulations based on assumed causes that are not actually responsible for the outcomes observed.</p>
    <p>In personal life, this fallacy might cause individuals to make poor decisions, such as avoiding certain behaviors or making unnecessary changes based on incorrect assumptions about cause and effect. By recognizing and avoiding this fallacy, people can improve their reasoning and make more informed decisions.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The False Cause fallacy often arises from a human tendency to seek patterns and make quick judgments. This cognitive shortcut, while useful in some situations, can lead to errors when it comes to understanding complex systems or processes. Confirmation bias also plays a role, as people may selectively notice and remember instances that support their preconceived beliefs about cause and effect.</p>
    <p>Another contributing factor is the lack of awareness or understanding of other possible explanations for an observed outcome. Without considering alternative causes, it is easy to fall into the trap of assuming a direct causal link between two correlated events.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid the False Cause fallacy, it is important to critically evaluate whether a correlation truly implies causation. This can be done by considering other possible explanations for the observed correlation and seeking out additional evidence. One useful approach is to look for experiments or studies that isolate the variables in question, helping to establish whether one event actually causes another.</p>
    <p>Additionally, developing a habit of questioning initial assumptions and being open to multiple interpretations of events can help prevent this fallacy. By acknowledging the complexity of most causal relationships, individuals can avoid jumping to conclusions and make more reasoned judgments.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Clark Glymour and colleagues (2010) on causal inference explores the complexities of determining causation from correlation. Their work emphasizes the importance of rigorous statistical methods in distinguishing true causal relationships from spurious correlations.</p>
    <p>Another key study by Judea Pearl (2000) in his book "Causality: Models, Reasoning, and Inference" highlights the challenges of establishing causality and the dangers of falling into the False Cause fallacy. Pearl's research provides a framework for understanding and analyzing causal relationships in a more scientifically robust way.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>Someone assumes that because a rooster crowed before sunrise, the rooster's crowing caused the sun to rise.</li>
      <li>A person believes that their favorite sports team wins every time they wear a particular shirt, assuming the shirt must be causing the victories.</li>
      <li>An individual concludes that because they started feeling better after taking a certain supplement, the supplement must have cured their illness, ignoring other possible factors like natural recovery.</li>
      <li>A politician claims that a policy change caused economic improvement because the improvement followed the policy, without considering other factors like global market trends.</li>
      <li>A manager attributes a company’s success to a recent change in leadership, without recognizing that the success could be due to long-term strategies implemented before the change.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"This happened after that, so it must have been caused by it."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"Just because one event followed another doesn’t mean it was caused by it. I should consider other possible explanations."</div>
    </div>

    <MenuBottomLF v-bind:current="'FC'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
