<template>
    <div>
      <h1>Outcome Bias</h1>
  
      <h3>You judge the quality of a decision based on its outcome rather than the process.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Outcome Bias occurs when the evaluation of a decision is influenced by the result, rather than the quality of the decision-making process itself. This bias leads individuals to unfairly judge a decision as good or bad based on the outcome, ignoring the reasoning and factors that went into making it.</p>
      <p>This can distort the assessment of decisions, leading to an overemphasis on luck or external factors that may have influenced the outcome.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Outcome Bias can lead to misleading assessments in areas such as business, medicine, and personal decisions. It can result in rewarding bad decisions that happened to work out well or punishing good decisions that had poor outcomes due to factors beyond control.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This bias is often driven by hindsight and the human tendency to overvalue outcomes as indicators of the quality of the decision-making process. It is exacerbated when outcomes are highly visible or impactful.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To prevent Outcome Bias, it's essential to focus on the decision-making process itself rather than the result. By evaluating decisions based on the information available at the time, and considering the reasoning behind the choice, individuals can make more fair and accurate assessments.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Studies have shown that Outcome Bias is prevalent across various domains. For example, a study by Baron and Hershey (1988) demonstrated how participants judged the quality of medical decisions differently depending on the outcome, even when the decision-making process was the same.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A manager rewards an employee for a risky decision that paid off, despite the poor reasoning behind it."</li>
        <li>"A coach is criticized for a play that didn't work, even though it was the right call based on the situation."</li>
        <li>"A medical treatment is judged as ineffective because a patient didn't recover, ignoring that it was the best option available."</li>
        <li>"An investor is praised for a successful investment, even though the decision was based on flawed analysis."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"The decision failed, so it must have been a bad choice."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"The outcome was poor, but the decision was sound based on the information we had."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'OB'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  