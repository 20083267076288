<template>
  <div>
    <h1>Overconfidence Bias</h1>

    <h3>You overestimate your own abilities or knowledge.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Overconfidence Bias is a cognitive distortion where individuals have an inflated sense of their abilities, knowledge, or control over situations. This bias can lead to risky decisions and underestimation of challenges, as overconfident individuals may believe they are less likely to experience negative outcomes than others.</p>
    <p>People with Overconfidence Bias tend to overestimate the accuracy of their judgments and underestimate the possibility of error. This can result in poor decision-making, as they may take on more risk than is warranted or fail to prepare adequately for potential setbacks.</p>
    <p>Addressing Overconfidence Bias is important for making more realistic and informed decisions, particularly in situations involving uncertainty or risk.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Overconfidence Bias can significantly affect areas such as finance, business, and personal relationships. For example, overconfident investors might take excessive risks in the stock market, leading to substantial losses. In business, leaders who overestimate their abilities may pursue overly ambitious projects without proper planning, resulting in failure. In personal relationships, overconfidence can lead to misunderstandings or conflicts, as individuals may assume they know what is best for others without considering their perspectives.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>Overconfidence Bias is often driven by a desire to maintain a positive self-image and a belief in one's competence. It can be exacerbated by success in previous endeavors, leading individuals to assume that future challenges will be equally manageable. Social and cultural factors that reward confidence and assertiveness can also contribute to the development of this bias.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To prevent Overconfidence Bias, it's important to regularly assess your abilities and knowledge objectively. Seeking feedback from others, considering alternative viewpoints, and acknowledging the possibility of error can help reduce overconfidence. Additionally, taking time to evaluate decisions carefully and considering the potential risks and challenges can lead to more balanced and realistic outcomes.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Lichtenstein, Fischhoff, and Phillips (1982) explored the prevalence of Overconfidence Bias, showing how individuals often overestimate the accuracy of their judgments. Studies by Moore and Healy (2008) further examined the effects of overconfidence in competitive settings, revealing its impact on risk-taking and decision-making performance.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>An investor believes they can consistently pick winning stocks, leading them to take on excessive risk without fully considering the potential for loss.</li>
      <li>A manager assumes they can successfully lead a large project without seeking input from others or planning for potential obstacles, resulting in project delays or failure.</li>
      <li>A student is confident they will ace an exam without studying, only to be surprised by the difficulty of the questions and their own lack of preparation.</li>
      <li>A driver overestimates their ability to drive safely in hazardous conditions, leading to reckless behavior and increased risk of accidents.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’m sure I’ll succeed without much effort because I’ve done it before."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While I’ve succeeded in the past, I should carefully assess this new challenge and prepare thoroughly to ensure success."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I don’t need anyone’s help; I can handle this on my own."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"While I’m confident in my abilities, seeking input and assistance from others can improve the chances of success."</div>
    </div>

    <MenuBottomJE v-bind:current="'OCB'"></MenuBottomJE>
  </div>
</template>

<script>
  import MenuBottomJE from '../../components/MenuBottomJE.vue';

  export default {
    components: {
      MenuBottomJE,
    }
  }
</script>
