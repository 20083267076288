<template>
  <div>
    

    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Logical Fallacies List</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Logical Fallacies</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Logical fallacies are errors in reasoning that undermine the logic of an argument. They often arise from a misunderstanding or misuse of logic, leading to conclusions that are not logically sound. Recognizing these fallacies can help in evaluating arguments critically and avoiding faulty reasoning in your own thinking.

      <div style="height:40px;"></div>
    </div>

    <!-- False Cause -->
    <div v-if="current != 'FC'">
      <router-link to="/false-cause">
        <div class="footer-div">
          FC
        </div>
        <div class="footer-div-label">
          False Cause
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You assume that because one event follows another, the first event must have caused the second.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FC
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        False Cause
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Straw Man -->
    <div v-if="current != 'SM'">
      <router-link to="/straw-man">
        <div class="footer-div">
          SM
        </div>
        <div class="footer-div-label">
          Straw Man
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You misrepresent someone's argument to make it easier to attack.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SM
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Straw Man
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Slippery Slope -->
    <div v-if="current != 'SS'">
      <router-link to="/slippery-slope">
        <div class="footer-div">
          SS
        </div>
        <div class="footer-div-label">
          Slippery Slope
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You argue that a small first step will inevitably lead to a chain of related events resulting in a significant impact.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SS
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Slippery Slope
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- False Dichotomy -->
    <div v-if="current != 'FD'">
      <router-link to="/false-dichotomy">
        <div class="footer-div">
          FD
        </div>
        <div class="footer-div-label">
          False Dichotomy
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You present two options as the only possible choices when others exist.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FD
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        False Dichotomy
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Circular Reasoning -->
    <div v-if="current != 'CR'">
      <router-link to="/circular-reasoning">
        <div class="footer-div">
          CR
        </div>
        <div class="footer-div-label">
          Circular Reasoning
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You assume the truth of the conclusion within the argument itself.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CR
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Circular Reasoning
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Appeal to Emotion -->
    <div v-if="current != 'AE'">
      <router-link to="/appeal-to-emotion">
        <div class="footer-div">
          AE
        </div>
        <div class="footer-div-label">
          Appeal to Emotion
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You manipulate emotions instead of using valid reasoning to win an argument.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        AE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Appeal to Emotion
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Framing Effect -->
    <div v-if="current != 'FE'">
      <router-link to="/framing-effect">
        <div class="footer-div">
          FE
        </div>
        <div class="footer-div-label">
          Framing Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You react differently to the same information depending on how it is presented.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Framing Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Halo Effect -->
    <div v-if="current != 'HE'">
      <router-link to="/halo-effect">
        <div class="footer-div">
          HE
        </div>
        <div class="footer-div-label">
          Halo Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You let one positive trait influence your overall judgment of a person or thing.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        HE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Halo Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Authority Bias -->
    <div v-if="current != 'AB'">
      <router-link to="/authority-bias">
        <div class="footer-div">
          AB
        </div>
        <div class="footer-div-label">
          Authority Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You place undue trust in the opinions of an authority figure, regardless of the validity of the argument.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        AB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Authority Bias
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>
  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
