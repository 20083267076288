<template>
  <div>
    <h1>
      Should Statements
    </h1>
    
    <h3>You use “shoulds,” “shouldn’ts,” “musts,” “oughts,” and “have tos”.</h3>

    <h4>Explained</h4>

    <p>Should statements are a type of cognitive distortion, which refers to irrational and unhealthy thought patterns that can lead to negative emotions and behaviors. Should statements involve making demands of ourselves or others that are not realistic or fair, and can contribute to feelings of guilt, shame, and anxiety.</p>

    <p>
      Examples of should statements include:
      <br>
      <ul>
        <li>"I should be able to handle this problem on my own."</li>
        <li>"I should always be successful."</li>
        <li>"I should never make mistakes."</li>
        <li>"I should be able to control my emotions."</li>
        <li>"I should be able to please everyone."</li>
        <li>"Others should treat me a certain way."</li>
      </ul>
    </p>

    <p>These types of thoughts can be harmful because they set unrealistic expectations for ourselves and others, and can lead to feelings of inadequacy and disappointment when we don't live up to them. Should statements can also cause us to judge ourselves and others harshly, leading to negative emotions and relationships.</p>

    <p>When we engage in the Should Statements cognitive distortion, we often believe that we know the universal rules that should govern all aspects of life, and we insist that things should follow these rules. This can lead us to feel frustrated and annoyed when things don't go as we think they should, and can contribute to negative emotions and behaviors.</p>

    <p>Should statements can be directed at other people, or at oneself. For example, we might say "I should be able to handle this problem on my own" or "You should be more considerate." They can also be directed at non-human objects, such as "It shouldn't rain today. This is not right!"</p>

    <p>While there can be good should statements, such as rules that help us to organize society, such as "It is not allowed to steal," or "We should cross the street only when the green light is on," should statements can become thinking errors when we look at things from a subjective perspective and insist that our subjective rules are the only true ones. In these cases, the rules we impose on others or ourselves may not be based on the common reality upon which people agree collectively, but are instead subjective and specific to a particular individual or a relatively small group of individuals.</p>

    <p>In order to address should statements and other cognitive distortions, it can be helpful to recognize and challenge these types of thoughts when they come up. We can try to reframe them in a more realistic and compassionate way, and practice techniques such as mindfulness, relaxation, and exposure to feared situations to develop healthier and more balanced thinking patterns. With practice and support, we can learn to let go of unhealthy should statements and develop more realistic and compassionate ways of thinking about ourselves and the world around us.</p>

    <h4>Example</h4>

    <p>Thought with should statements error: "I should be able to control my emotions. If I can't, there's something wrong with me."</p>

    <p>Reframed thought: "It's normal and healthy to have a range of emotions, and it's okay if I can't always control them. I am not defined by my emotions, and there is nothing wrong with me just because I experience difficult feelings."</p>

    <p>This reframed thought recognizes that it is normal and healthy to have a range of emotions, and that it is not always possible or healthy to try to control them. It also reminds us that our emotions do not define us, and that there is nothing wrong with us just because we experience difficult feelings. This reframed thought can help to reduce feelings of shame and inadequacy, and can encourage a more compassionate and understanding approach to ourselves and our emotions.</p>

    
    <CBTAppText v-bind:current="'SS'"></CBTAppText>

    <menu-bottom v-bind:current="'SS'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>