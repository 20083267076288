<template>
  <div>
    <h1>Emotional Reasoning</h1>

    <h3>You reason from your feelings: “I feel like an idiot, so I must be one.”</h3>

    <h4>Explained</h4>

    <p>Emotional reasoning is a type of cognitive distortion, or thinking error, that occurs when someone assumes that their emotions reflect reality. This means that if someone feels a certain way, they believe that their feelings must be true and that the situation must be as they perceive it. This type of thinking can lead to problems because it ignores logic, evidence, and other important factors that may contradict the individual's emotions.</p>

    <p>For example, if someone is feeling anxious, they may believe that they are in danger, even if there is no logical reason to think this. Or if someone is feeling guilty, they may believe that they have done something wrong, even if they have not. In both cases, the individual's emotions are dictating their beliefs, rather than the other way around.</p>

    <p>Emotional reasoning can have negative consequences because it can lead to misunderstandings, poor decision-making, and unhealthy behaviors. For example, if someone is feeling depressed, they may believe that their life is hopeless and that there is no point in trying to change things. This can lead to a lack of motivation and a downward spiral of negative emotions. Similarly, if someone is feeling angry, they may lash out at others or engage in aggressive behavior, even if it is not warranted.</p>

    <p>So what can be done about emotional reasoning? One effective strategy is to practice mindfulness and self-awareness. This involves paying attention to one's thoughts and emotions, and noticing when they may be distorting reality. It can also be helpful to challenge these thoughts and emotions by asking oneself questions such as "Is this really true?" or "What evidence do I have to support this belief?" It can also be helpful to seek the perspective of others, as they may have a different perspective on the situation.</p>

    <p>Another effective strategy is to practice cognitive-behavioral therapy (CBT) techniques, which involve identifying and changing negative thought patterns. This may involve identifying the underlying beliefs that contribute to emotional reasoning and replacing them with more realistic and positive thoughts.</p>

    <p>In conclusion, emotional reasoning is a type of cognitive distortion that occurs when someone assumes that their emotions reflect reality. It can have negative consequences and lead to misunderstandings, poor decision-making, and unhealthy behaviors. To overcome emotional reasoning, it can be helpful to practice mindfulness and self-awareness, challenge negative thoughts and emotions, and seek the perspective of others. Practicing CBT techniques can also be effective in changing negative thought patterns and replacing them with more realistic and positive thoughts.</p>


    <h4>Example</h4>

    <p>
      Here is an example of a thought with emotional reasoning error:<br>
      "I feel anxious about my job performance, therefore I must be a terrible employee and I'm going to get fired."
    </p>

    <p>
      Here is the same thought rewritten, or reframed, to eliminate the emotional reasoning error:<br>
      "I feel anxious about my job performance, but that doesn't necessarily mean that I am a terrible employee or that I am going to get fired. I can take steps to improve my performance and communicate with my boss to address any concerns they may have."
    </p>

    <p>In the rewritten thought, the individual is acknowledging their feelings of anxiety, but they are not allowing those feelings to dictate their belief about their job performance or their future at the company. Instead, they are taking a more balanced and logical approach by acknowledging the possibility of improving their performance and seeking ways to address any concerns. This reframed thought allows the individual to better manage their anxiety and make more informed decisions about their job and career.</p>

    <CBTAppText v-bind:current="'ER'"></CBTAppText>

    <menu-bottom v-bind:current="'ER'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>