import { render, staticRenderFns } from "./contrast-effect.vue?vue&type=template&id=c1f03f10"
import script from "./contrast-effect.vue?vue&type=script&lang=js"
export * from "./contrast-effect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports