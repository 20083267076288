<template>
  <div>
    <h1>Hyperbolic Discounting</h1>

    <h3>You prefer smaller, immediate rewards over larger, delayed ones.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>Hyperbolic Discounting is a cognitive bias where individuals disproportionately prefer smaller, immediate rewards over larger, delayed ones. This bias reflects a preference for instant gratification, often leading to decisions that favor short-term benefits at the expense of long-term gains.</p>
    <p>This phenomenon can explain why people might choose to spend money now rather than save it for future needs or why they might procrastinate on important tasks that offer delayed rewards. The tendency to undervalue future benefits can have significant implications for financial planning, health behaviors, and overall life satisfaction.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>Hyperbolic Discounting can lead to a range of negative outcomes, including poor financial decisions, such as excessive spending and under-saving, unhealthy behaviors like overeating or substance abuse, and procrastination. Over time, these choices can accumulate, leading to long-term consequences that far outweigh the short-term benefits that were initially prioritized.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This bias is rooted in the brain's reward system, which is more sensitive to immediate rewards than to those that are delayed. The discomfort associated with waiting, combined with a lack of self-control, often leads individuals to favor instant gratification. Environmental factors, such as stress or lack of future-oriented thinking, can exacerbate this tendency.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To mitigate the effects of Hyperbolic Discounting, it’s important to practice self-discipline and to set up systems that encourage long-term thinking. Breaking down long-term goals into smaller, more immediate rewards can help in maintaining motivation. Additionally, developing a habit of considering the long-term consequences of decisions before acting can lead to more balanced choices.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Laibson (1997) explored the implications of Hyperbolic Discounting for economic decision-making, showing how individuals often make choices that favor immediate gratification at the expense of long-term benefits. Studies by Ainslie (1975) also demonstrated the pervasiveness of this bias in behavioral economics, highlighting how it influences a wide range of decisions, from savings behavior to health choices.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person chooses to spend money on a vacation now rather than saving for retirement, underestimating the long-term impact on their financial security.</li>
      <li>An individual decides to eat junk food instead of sticking to a diet plan, prioritizing immediate pleasure over long-term health benefits.</li>
      <li>A student procrastinates on studying for an exam, opting to enjoy leisure activities now and dealing with the stress of cramming later.</li>
      <li>An employee spends their bonus on luxury items instead of investing it, focusing on short-term satisfaction rather than long-term financial growth.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I’ll enjoy this now and deal with the consequences later."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"If I wait, the future benefits will be much greater than the small reward I get now."</div>
    </div>

    <MenuBottomCD v-bind:current="'HD'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
