<template>
    <div>
      <h1>Base Rate Neglect</h1>
  
      <h3>You ignore general statistical information in favor of specific information.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Base Rate Neglect occurs when individuals disregard the general prevalence of an event (base rate) in favor of specific information about the case at hand. This bias leads to overestimating the importance of specific cases or anecdotal evidence, often at the expense of the broader context.</p>
      <p>This can result in flawed decision-making, particularly when people focus on vivid or recent examples rather than considering the overall statistical likelihood.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Base Rate Neglect can lead to significant errors in judgment, particularly in areas like medical diagnoses, legal decisions, and financial forecasting. By focusing on specific information without considering the broader statistical context, people can make decisions that are not truly representative of reality.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This bias often arises due to a preference for concrete, vivid, or recent information over abstract statistical data. Cognitive overload, time pressure, or a lack of statistical understanding can exacerbate the tendency to neglect base rates.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To avoid Base Rate Neglect, it's crucial to always consider the broader statistical context when making decisions. Balancing specific information with base rate data, seeking out objective statistical insights, and avoiding overreliance on anecdotal evidence can help mitigate this bias.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research has shown that individuals frequently fall prey to Base Rate Neglect, especially in situations involving complex or uncertain decision-making. For example, a study by Kahneman and Tversky (1973) highlighted how people often ignore base rate information in favor of specific case details, leading to suboptimal judgments.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A doctor overestimates the likelihood of a rare disease because of a recent case, ignoring the overall low prevalence."</li>
        <li>"An investor focuses on a company's recent success and ignores broader market trends and statistics."</li>
        <li>"A juror is swayed by a vivid testimony rather than considering the statistical likelihood of events presented in the case."</li>
        <li>"Someone avoids flying due to a recent plane crash, despite knowing that flying is statistically safer than driving."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This stock has performed well recently, so it must continue to rise."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"While the stock has done well, I should consider broader market trends and historical data."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'BRN'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  