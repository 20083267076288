<template>
    <div>
      <h1>Hot-Hand Fallacy</h1>
  
      <h3>You believe that a person who has experienced success is more likely to continue having success.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Hot-Hand Fallacy is a cognitive bias where individuals believe that someone who has experienced success in the past is more likely to continue succeeding in the future. This belief persists even when the success is due to chance rather than skill.</p>
      <p>This bias is common in gambling, sports, and financial decisions, where people may assume that a streak of success will continue indefinitely, ignoring the role of probability and chance.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Hot-Hand Fallacy can lead to poor decision-making, particularly in situations involving risk and uncertainty. It can cause people to take undue risks, overestimate their abilities, or make investments based on a belief in continued success.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>This fallacy often arises from a misunderstanding of probability and randomness. People tend to see patterns where none exist, leading to the belief that success will continue simply because it has happened before.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To avoid the Hot-Hand Fallacy, it's important to recognize the role of chance and probability in success. Analyzing the factors that contributed to past success and considering the likelihood of future outcomes can help make more rational decisions.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>The Hot-Hand Fallacy has been studied extensively in the context of sports and gambling. A notable study by Gilovich, Vallone, and Tversky (1985) demonstrated that people often perceive a pattern of success in random events, leading to the belief in a "hot hand."</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A gambler increases their bets after a winning streak, believing that their luck will continue."</li>
        <li>"An investor puts more money into a stock that has been performing well, assuming it will keep rising."</li>
        <li>"A basketball player takes more shots, believing they are on a hot streak and can't miss."</li>
        <li>"A person keeps playing the lottery after winning once, thinking their luck will strike again."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I'm on a winning streak, so I should keep going."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Success in the past doesn't guarantee future success. I should assess the situation objectively."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'HHF'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  