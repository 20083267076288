<template>
  <div>
    <h1>False Consensus Effect</h1>

    <h3>You overestimate how much others agree with your beliefs or behaviors.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The False Consensus Effect is a cognitive bias where individuals overestimate the extent to which their beliefs, opinions, and behaviors are normal and typical of others. This bias leads people to assume that others share their views and that their way of thinking is more common than it actually is. As a result, they may incorrectly believe that their perspectives are more widely held than they are.</p>
    <p>This bias can distort social interactions and decision-making, as individuals may fail to recognize the diversity of opinions and behaviors in their environment. It can also contribute to misunderstandings and conflicts, as people may be surprised or offended when others do not share their views.</p>
    <p>Recognizing the False Consensus Effect is essential for developing a more accurate understanding of others and for making decisions that take into account the true diversity of perspectives in a group or society.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The False Consensus Effect can have significant social and psychological consequences. In social settings, it can lead to the reinforcement of groupthink, where individuals conform to what they believe is the majority opinion, even when it is not. This can stifle diversity of thought and innovation, as people may be less likely to express dissenting opinions or explore alternative ideas.</p>
    <p>In decision-making, this bias can lead to poor choices, as individuals may base their decisions on the mistaken belief that their preferences are more common or accepted than they actually are. This can result in policies or actions that are not well-received by others, leading to conflicts and misunderstandings. In extreme cases, the False Consensus Effect can contribute to social polarization, as people become more entrenched in their views, believing them to be the norm.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The False Consensus Effect is driven by the human tendency to rely on their own experiences and perspectives when interpreting the world. People are naturally inclined to assume that others think and behave as they do, particularly when they are surrounded by like-minded individuals. This bias is also reinforced by social and cultural factors that encourage conformity and discourage the expression of dissenting opinions.</p>
    <p>Additionally, cognitive factors such as the availability heuristic, where individuals rely on readily available examples from their own experience, can contribute to the False Consensus Effect. When people frequently encounter others who share their views, they may incorrectly assume that these views are more widespread than they are.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To counteract the False Consensus Effect, it is important to actively seek out diverse perspectives and to question assumptions about how widely held one’s views are. Engaging in open dialogue with people from different backgrounds and with different viewpoints can help in recognizing the true diversity of opinions and behaviors in a group or society.</p>
    <p>Practicing humility and recognizing the limits of one’s own experience can also help in reducing this bias. By acknowledging that others may have different perspectives and that these perspectives are equally valid, individuals can make more informed decisions and contribute to a more inclusive and diverse environment.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Ross, Greene, and House (1977) first identified the False Consensus Effect, demonstrating how individuals overestimate the extent to which others share their opinions and behaviors. Their studies found that people were more likely to believe that others agreed with them when they were confident in their own views, highlighting the role of self-assurance in this bias.</p>
    <p>Further studies by Marks and Miller (1987) explored the implications of the False Consensus Effect for social behavior and decision-making, finding that this bias can lead to overconfidence and poor judgment in group settings. Their research underscores the importance of considering diverse perspectives in decision-making processes.</p>
    <p>A study by Gilovich, Savitsky, and Medvec (1998) examined how the False Consensus Effect influences social interactions, showing that individuals are often surprised when others do not share their views, leading to conflicts and misunderstandings. The findings suggest that reducing this bias requires greater awareness of the diversity of perspectives in any given group or society.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>A person assumes that most people share their political views and is surprised to learn that many of their friends and family members have different opinions.</li>
      <li>A manager believes that all employees agree with their approach to a project, only to discover that many team members have concerns or different ideas.</li>
      <li>A student assumes that their classmates will find a particular joke funny because they do, but the joke falls flat because it does not resonate with others.</li>
      <li>Someone believes that their preference for a specific type of music is common, only to be surprised when others express a dislike for that genre.</li>
      <li>A person assumes that everyone prefers the same vacation destination as they do, only to find out that others have different travel preferences.</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"Everyone must agree with me because it’s so obvious."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should ask others for their opinions, as they may have different perspectives that I haven’t considered."</div>
    </div>

    <div class="space-thoughts"></div>

    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"Most people think like I do, so my decision will be well-received."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should gather feedback from others to ensure that my decision aligns with the views of the group."</div>
    </div>

    <MenuBottomCD v-bind:current="'FCE'"></MenuBottomCD>
  </div>
</template>

<script>
  import MenuBottomCD from '../../components/MenuBottomCD.vue';

  export default {
    components: {
      MenuBottomCD,
    }
  }
</script>
