<template>
    <div>
      <h1>Source Confusion</h1>
  
      <h3>You misattribute the source of a memory, confusing whether it was something you experienced, imagined, or heard from someone else.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Source confusion occurs when you incorrectly recall the origin of a memory. You might remember the content accurately but be unsure whether you read it, heard it in a conversation, or even imagined it. This distortion can significantly impact your perception of truth and reliability, leading to a mix-up between fact and fiction.</p>
      <p>This cognitive bias often arises because our brains prioritize the information content over its origin, making it easy to confuse sources over time. For instance, if you’ve heard a story multiple times from different sources, you might forget where you first heard it. This can lead to false memories or the spread of misinformation when the source is wrongly attributed.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>Source confusion can have serious consequences, particularly in legal contexts, journalism, or everyday decision-making. When the reliability of a memory is tied to its source, misremembering where information came from can lead to misunderstandings, errors in judgment, or the acceptance of false information as true.</p>
      <p>For example, in legal testimony, source confusion can lead to the misattribution of critical evidence, potentially altering the outcome of a case. In personal relationships, it can cause unnecessary conflicts if someone mistakenly recalls a conversation as having been more critical or less supportive than it was.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>One of the primary causes of source confusion is the brain’s tendency to focus on the information content rather than its origin. As time passes, the details of where or when we learned something can fade, leaving us with only the memory of the content itself. Additionally, frequent exposure to similar information from various sources can blur the lines between them, making it difficult to remember the original source.</p>
      <p>Media consumption, social interactions, and even dreams can contribute to source confusion. Our brains might amalgamate details from different sources, creating a cohesive memory that doesn’t accurately reflect reality. This is particularly prevalent in the digital age, where information is readily available from multiple channels.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>Preventing source confusion involves being mindful of the origins of your information. When consuming media, take note of the sources and contexts in which you encounter information. Keeping a journal or making notes can help solidify the connection between the content and its source, reducing the risk of misattribution later.</p>
      <p>In conversations, clarifying the origin of information can also help prevent source confusion. For example, if you’re sharing a story, mention where you heard it to reinforce the memory's source. Additionally, critically evaluating the credibility of sources can prevent the acceptance of false information as true.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research into memory suggests that source confusion is a common phenomenon, particularly in situations involving high cognitive load or stress. Studies on eyewitness testimony have shown that source confusion is a leading cause of inaccuracies in legal cases. Memory researchers like Elizabeth Loftus have demonstrated through experiments how easily false memories can be created, with individuals often misattributing the source of their memories.</p>
      <p>Further studies have indicated that our brains are not always designed to store memories with precise details about their origins, which is why source confusion occurs so frequently. Understanding these mechanisms can lead to better practices in both personal memory retention and broader fields like law and education.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>You recall a piece of trivia but are unsure if you learned it from a friend, a book, or a TV show.</li>
        <li>An eyewitness in a trial misremembers where they saw a suspect, leading to inaccurate testimony.</li>
        <li>You confidently tell a story as if it happened to you, only to realize later that you heard it from someone else.</li>
        <li>A journalist mistakenly attributes a quote to the wrong source, leading to a retraction or correction.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I’m sure I remember where this information came from."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should double-check the source to ensure my memory is accurate."</div>
      </div>
      
      <div class="space-thoughts"></div>
      
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I remember this clearly, so it must be true."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Just because I remember something clearly doesn’t mean I remember where I heard it."</div>
      </div>
  
      <MenuBottomPB v-bind:current="'SC'"></MenuBottomPB>
    </div>
  </template>
  
  <script>
    import MenuBottomPB from '../../components/MenuBottomPB.vue';
  
    export default {
      components: {
        MenuBottomPB,
      }
    }
  </script>
  