<template>
  <div>    
    <h4>CBT App</h4>

    <p>To address the {{ distortion }} cognitive distortion, consider using the <a href="/cbt-app" target="_blank">CBT app</a>. This privacy-focused digital tool allows you to record and analyze your thoughts, identifying the {{ distortion }} thinking error and reframing it with more rational thinking.</p>

    <p>By actively challenging and rewriting these distorted thoughts, you can address and correct this type of thinking pattern. The CBT app offers a convenient and private way to practice Cognitive Behavioral Therapy techniques, helping you improve your overall thought processes and lead a happier, healthier life.</p>
  </div>
</template>

<script>

  export default {

    data: function() {
      return {
        distortion: ''
      }
    },

    mounted () {
      // console.log(this.current);
      if (this.current == 'AoN') {
        this.distortion = 'All or Nothing Thinking'
      }
      else if (this.current == 'MoM') {
        this.distortion = 'Magnification or Minimization'
      }
      else if (this.current == 'MF') {
        this.distortion = 'Mental Filter'
      }
      else if (this.current == 'DP') {
        this.distortion = 'Discounting Positives'
      }
      else if (this.current == 'Lab') {
        this.distortion = 'Labeling'
      }
      else if (this.current == 'Per') {
        this.distortion = 'Personalization'
      }
      else if (this.current == 'FT') {
        this.distortion = 'Fortune Telling'
      }
      else if (this.current == 'MR') {
        this.distortion = 'Mind Reading'
      }
      else if (this.current == 'Ovg') {
        this.distortion = 'Overgeneralization'
      }
      else if (this.current == 'SS') {
        this.distortion = 'Should Statements'
      }
      else if (this.current == 'SB') {
        this.distortion = 'Self Blame'
      }
      else if (this.current == 'OB') {
        this.distortion = 'Other Blame'
      }
      else if (this.current == 'ER') {
        this.distortion = 'Emotional Reasoning'
      }
    },

    beforeDestroy () {
      
    },

    render () {
      
    },

    props: [ 'current' ]
    
  }

</script>


<style scoped>

</style>