<template>
  <div>
    <h1>Straw Man</h1>

    <h3>You misrepresent someone's argument to make it easier to attack.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Straw Man fallacy occurs when someone distorts or oversimplifies an opponent's argument to make it easier to refute. Instead of addressing the actual point being made, the person sets up a "straw man" version of the argument—something weaker or more extreme than what was originally stated—and then attacks that instead.</p>
    <p>This tactic is often used in debates to sidestep the real issues and divert attention away from the stronger aspects of the opponent's argument. By knocking down the straw man, the individual can claim victory without actually engaging with the substantive points of the original argument.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Straw Man fallacy can seriously undermine productive discourse, leading to misunderstandings and unproductive arguments. In public debates or political discourse, it can cause the audience to be misled about the true nature of the arguments being presented. This fallacy can also contribute to polarization, as it fosters an environment where nuanced discussions are replaced by simplistic and adversarial exchanges.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>The Straw Man fallacy often arises from a lack of understanding or a deliberate attempt to mislead. It may also result from cognitive biases, such as the tendency to interpret opposing arguments in the most negative light possible. Additionally, the desire to win an argument at any cost can lead individuals to resort to this tactic, as it provides a quick and easy way to undermine an opponent.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid falling into the Straw Man fallacy, it’s important to engage with the strongest version of an opponent's argument. This means accurately representing their position and addressing their key points directly. Asking clarifying questions and summarizing the opponent's argument before responding can help ensure that you are engaging with their actual position rather than a distorted version of it.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research on argumentative strategies by Van Eemeren and Grootendorst (1992) emphasizes the importance of addressing arguments fairly and avoiding fallacies like the Straw Man. Their work in pragma-dialectics provides a framework for analyzing and evaluating arguments in a way that promotes constructive dialogue.</p>
    <p>A study by Mercier and Sperber (2011) on the "argumentative theory of reasoning" suggests that people are naturally inclined to construct arguments that favor their own viewpoints, which can lead to fallacies like the Straw Man. Understanding this tendency can help individuals be more mindful of their own biases and strive for more honest and effective argumentation.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>Person A: "We should invest more in public education." Person B: "My opponent wants to throw money at a broken system without any accountability."</li>
      <li>Person A: "I think we should improve regulations on the financial industry." Person B: "My opponent thinks that we should strangle businesses with red tape and kill the economy."</li>
      <li>Person A: "I believe in climate change." Person B: "My opponent wants us all to live in caves and give up modern technology."</li>
      <li>Person A: "We should consider raising taxes on the wealthy." Person B: "My opponent wants to take all the money from hardworking people and give it to those who don't deserve it."</li>
      <li>Person A: "We need to reduce the number of standardized tests in schools." Person B: "My opponent wants to get rid of all forms of assessment and let students coast through school without learning anything."</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"My opponent said this, so it’s easy to refute."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I need to address the strongest version of my opponent's argument, even if it’s more challenging."</div>
    </div>

    <MenuBottomLF v-bind:current="'SM'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
