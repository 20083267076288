<template>
    <div>
      <h1>Planning Fallacy</h1>
  
      <h3>You underestimate the time, costs, and risks of future actions while overestimating the benefits.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>The Planning Fallacy is a cognitive bias where individuals or groups underestimate the time, costs, and risks of future actions while overestimating the potential benefits. This bias is particularly common in project planning, where timelines are often overly optimistic, budgets are underestimated, and challenges are downplayed.</p>
      <p>Despite experience with similar tasks, people tend to believe that "this time will be different," leading to repeated underestimations. The optimism inherent in the Planning Fallacy often results in project delays, cost overruns, and unmet expectations.</p>
      <p>This bias is influenced by a variety of factors, including wishful thinking, the complexity of the task, and the tendency to focus on the most optimistic scenarios. It is further exacerbated when individuals fail to account for unforeseen events or changes in circumstances.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The Planning Fallacy has far-reaching consequences in both personal and professional contexts. In business, it can lead to failed projects, missed deadlines, and financial losses. In personal life, it might cause stress, disappointment, and strained relationships due to unmet expectations. The bias is particularly problematic in large-scale projects, where the stakes are high and the margin for error is small.</p>
      <p>For instance, major infrastructure projects like highways, bridges, or stadiums often experience significant cost overruns and delays due to the Planning Fallacy. Similarly, individuals might underestimate the time required to learn a new skill, leading to frustration and giving up prematurely.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>The Planning Fallacy is rooted in a number of cognitive processes. One primary cause is optimism bias, where individuals focus on best-case scenarios rather than realistic outcomes. Another contributing factor is the tendency to break down complex tasks into smaller, more manageable pieces, which can lead to underestimating the overall effort required.</p>
      <p>Additionally, the illusion of control—where individuals believe they have more influence over outcomes than they actually do—can exacerbate this bias. Finally, people often fail to learn from past experiences, believing that previous failures were due to external factors rather than inherent flaws in planning.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>To counteract the Planning Fallacy, it's essential to adopt a more realistic approach to planning. Techniques such as "reference class forecasting," where planners compare their current project to similar past projects, can help in creating more accurate estimates. Breaking down tasks and considering potential risks and obstacles can also improve the accuracy of planning.</p>
      <p>Incorporating contingency plans and allowing for flexibility in timelines and budgets can further mitigate the impact of this bias. Additionally, involving multiple perspectives in the planning process, including those of skeptics or critics, can provide a more balanced view and help identify potential pitfalls.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research by Daniel Kahneman and Amos Tversky first highlighted the Planning Fallacy, demonstrating that people consistently underestimate the time required to complete tasks. Subsequent studies have confirmed that this bias is widespread across different domains, from personal tasks like writing papers to large-scale projects like construction and IT development.</p>
      <p>For example, a study by Buehler, Griffin, and Ross (1994) found that people tend to be overly optimistic in their time estimates, even when they are familiar with the task and have prior experience. This research underscores the need for more conservative and evidence-based planning approaches.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>"A software development team underestimates the time needed to complete a new feature, leading to a delayed product launch."</li>
        <li>"A student plans to write a term paper in one night, only to find that it takes much longer and results in a rushed, lower-quality submission."</li>
        <li>"Homeowners budget for a kitchen renovation based on optimistic estimates, but encounter unexpected costs and delays that double the original budget."</li>
        <li>"A project manager assures stakeholders that a construction project will be completed within a year, but unforeseen challenges extend the timeline by several months."</li>
        <li>"An individual plans a road trip and assumes they can drive for 12 hours straight, underestimating the toll of fatigue and the need for breaks."</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"This project will go smoothly and finish on time."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I should account for possible delays and challenges, and plan for contingencies."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I can finish this task in a couple of hours."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"Based on past experience, this might take longer than expected. I'll give myself extra time."</div>
      </div>
  
      <MenuBottomJE v-bind:current="'PF'"></MenuBottomJE>
    </div>
  </template>
  
  <script>
    import MenuBottomJE from '../../components/MenuBottomJE.vue';
  
    export default {
      components: {
        MenuBottomJE,
      }
    }
  </script>
  