<template>
  <div>

    <!-- <div v-if="home" style="height:10px;"></div> -->
    <div v-if="!home" style="height:20px;"></div>

    <h4 v-if="home !== true">Cognitive Distortions List</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Cognitive Distortions</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
          
      Cognitive distortions are biased patterns of thinking that distort reality and often lead to negative emotions. These habitual thought patterns can cause us to perceive situations more negatively than they actually are, reinforcing unhelpful beliefs and contributing to emotional distress. By identifying and challenging these distortions, we can develop more balanced, accurate, and constructive ways of thinking, leading to better decision-making and improved mental well-being.

      <div style="height:40px;"></div>
      
    </div>


    <!-- Availability Heuristic -->
    <div v-if="current != 'AvH'">
      <router-link to="/availability-heuristic">
        <div class="footer-div">
          AvH
        </div>
        <div class="footer-div-label">
          Availability Heuristic
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You judge the probability of events by how easily examples come to mind.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        AvH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Availability Heuristic
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Confirmation Bias -->
    <div v-if="current != 'CB'">
      <router-link to="/confirmation-bias">
        <div class="footer-div">
          CB
        </div>
        <div class="footer-div-label">
          Confirmation Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You favor information that confirms your preexisting beliefs.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Confirmation Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Sunk Cost Fallacy -->
    <div v-if="current != 'SCF'">
      <router-link to="/sunk-cost-fallacy">
        <div class="footer-div">
          SCF
        </div>
        <div class="footer-div-label">
          Sunk Cost Fallacy
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You continue a behavior or endeavor because of previously invested resources.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SCF
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Sunk Cost Fallacy
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Hindsight Bias -->
    <div v-if="current != 'HB'">
      <router-link to="/hindsight-bias">
        <div class="footer-div">
          HB
        </div>
        <div class="footer-div-label">
          Hindsight Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You see past events as having been more predictable than they actually were.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        HB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Hindsight Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>




    <!-- Illusory Correlation -->
    <div v-if="current != 'IC'">
      <router-link to="/illusory-correlation">
        <div class="footer-div">
          IC
        </div>
        <div class="footer-div-label">
          Illusory Correlation
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You falsely perceive a relationship between two unrelated events.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        IC
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Illusory Correlation
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Belief Bias -->
    <div v-if="current != 'BB'">
      <router-link to="/belief-bias">
        <div class="footer-div">
          BB
        </div>
        <div class="footer-div-label">
          Belief Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You judge arguments based on your existing beliefs rather than the strength of the argument.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        BB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Belief Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Cognitive Dissonance -->
    <div v-if="current != 'CD'">
      <router-link to="/cognitive-dissonance">
        <div class="footer-div">
          CD
        </div>
        <div class="footer-div-label">
          Cognitive Dissonance
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You feel discomfort from holding conflicting beliefs or behaviors.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CD
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Cognitive Dissonance
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- System Justification -->
    <div v-if="current != 'SJ'">
      <router-link to="/system-justification">
        <div class="footer-div">
          SJ
        </div>
        <div class="footer-div-label">
          System Justification
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You defend and justify the status quo, even at your own expense.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SJ
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        System Justification
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- False Memory -->
    <div v-if="current != 'FM'">
      <router-link to="/false-memory">
        <div class="footer-div">
          FM
        </div>
        <div class="footer-div-label">
          False Memory
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You remember events differently from how they actually occurred.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FM
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        False Memory
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Pseudocertainty Effect -->
    <div v-if="current != 'PCE'">
      <router-link to="/pseudocertainty-effect">
        <div class="footer-div">
          PCE
        </div>
        <div class="footer-div-label">
          Pseudocertainty Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You take on more risk when decisions are framed in a certain way.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        PCE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Pseudocertainty Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Hyperbolic Discounting -->
    <div v-if="current != 'HD'">
      <router-link to="/hyperbolic-discounting">
        <div class="footer-div">
          HD
        </div>
        <div class="footer-div-label">
          Hyperbolic Discounting
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You prefer smaller, immediate rewards over larger, delayed ones.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        HD
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Hyperbolic Discounting
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Cognitive Miser -->
    <div v-if="current != 'CM'">
      <router-link to="/cognitive-miser">
        <div class="footer-div">
          CM
        </div>
        <div class="footer-div-label">
          Cognitive Miser
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You tend to think and solve problems in simple and less effortful ways.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CM
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Cognitive Miser
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Law of the Instrument -->
    <div v-if="current != 'LotI'">
      <router-link to="/law-of-the-instrument">
        <div class="footer-div">
          LotI
        </div>
        <div class="footer-div-label">
          Law of the Instrument
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You over-rely on a familiar tool or method, even when it may not be suitable.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        LotI
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Law of the Instrument
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Overconfidence Effect -->
    <div v-if="current != 'OE'">
      <router-link to="/overconfidence-effect">
        <div class="footer-div">
          OE
        </div>
        <div class="footer-div-label">
          Overconfidence Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You have excessive confidence in your own answers or judgments.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        OE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Overconfidence Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Pessimism Bias -->
    <div v-if="current != 'PB'">
      <router-link to="/pessimism-bias">
        <div class="footer-div">
          PB
        </div>
        <div class="footer-div-label">
          Pessimism Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overestimate the likelihood of negative outcomes.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        PB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Pessimism Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Optimism Bias -->
    <div v-if="current != 'OB'">
      <router-link to="/optimism-bias">
        <div class="footer-div">
          OB
        </div>
        <div class="footer-div-label">
          Optimism Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overestimate the likelihood of positive outcomes.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        OB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Optimism Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Curse of Knowledge -->
    <div v-if="current != 'CK'">
      <router-link to="/curse-of-knowledge">
        <div class="footer-div">
          CK
        </div>
        <div class="footer-div-label">
          Curse of Knowledge
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You assume others have the same knowledge as you.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CK
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Curse of Knowledge
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Dunning-Kruger Effect -->
    <div v-if="current != 'DKE'">
      <router-link to="/dunning-kruger-effect">
        <div class="footer-div">
          DKE
        </div>
        <div class="footer-div-label">
          Dunning-Kruger Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overestimate your competence in areas where you lack knowledge.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        DKE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Dunning-Kruger Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Bias Blind Spot -->
    <div v-if="current != 'BBS'">
      <router-link to="/bias-blind-spot">
        <div class="footer-div">
          BBS
        </div>
        <div class="footer-div-label">
          Bias Blind Spot
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You fail to recognize your own cognitive biases.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        BBS
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Bias Blind Spot
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Illusion of Control -->
    <div v-if="current != 'IoC'">
      <router-link to="/illusion-of-control">
        <div class="footer-div">
          IoC
        </div>
        <div class="footer-div-label">
          Illusion of Control
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overestimate your ability to control events.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        IoC
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Illusion of Control
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Self-Handicapping -->
    <div v-if="current != 'SH'">
      <router-link to="/self-handicapping">
        <div class="footer-div">
          SH
        </div>
        <div class="footer-div-label">
          Self-Handicapping
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You create obstacles to your own success as an excuse for potential failure.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Self-Handicapping
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Just-World Hypothesis -->
    <div v-if="current != 'JWH'">
      <router-link to="/just-world-hypothesis">
        <div class="footer-div">
          JWH
        </div>
        <div class="footer-div-label">
          Just-World Hypothesis
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You believe that people get what they deserve in life.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        JWH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Just-World Hypothesis
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Mere Ownership Effect -->
    <div v-if="current != 'MOE'">
      <router-link to="/mere-ownership-effect">
        <div class="footer-div">
          MOE
        </div>
        <div class="footer-div-label">
          Mere Ownership Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You value things more simply because you own them.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        MOE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Mere Ownership Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Egocentric Bias -->
    <div v-if="current != 'EB'">
      <router-link to="/egocentric-bias">
        <div class="footer-div">
          EB
        </div>
        <div class="footer-div-label">
          Egocentric Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You exaggerate the importance of your own role or contributions.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        EB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Egocentric Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Hostile Attribution Bias -->
    <div v-if="current != 'HAB'">
      <router-link to="/hostile-attribution-bias">
        <div class="footer-div">
          HAB
        </div>
        <div class="footer-div-label">
          Hostile Attribution Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You interpret others' ambiguous behavior as having hostile intent.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        HAB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Hostile Attribution Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Actor-Observer Bias -->
    <div v-if="current != 'AOB'">
      <router-link to="/actor-observer-bias">
        <div class="footer-div">
          AOB
        </div>
        <div class="footer-div-label">
          Actor-Observer Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You attribute your own actions to external factors, while blaming others for their actions.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        AOB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Actor-Observer Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Reactive Devaluation -->
    <div v-if="current != 'RD'">
      <router-link to="/reactive-devaluation">
        <div class="footer-div">
          RD
        </div>
        <div class="footer-div-label">
          Reactive Devaluation
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You devalue proposals or solutions simply because they come from an opposing party.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        RD
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Reactive Devaluation
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- False Consensus Effect -->
    <div v-if="current != 'FCE'">
      <router-link to="/false-consensus-effect">
        <div class="footer-div">
          FCE
        </div>
        <div class="footer-div-label">
          False Consensus Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overestimate how much others agree with your beliefs or behaviors.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FCE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        False Consensus Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Base Rate Fallacy -->
    <div v-if="current != 'BRF'">
      <router-link to="/base-rate-fallacy">
        <div class="footer-div">
          BRF
        </div>
        <div class="footer-div-label">
          Base Rate Fallacy
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You ignore general statistical information in favor of specific, anecdotal information.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        BRF
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Base Rate Fallacy
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Denomination Effect -->
    <div v-if="current != 'DE'">
      <router-link to="/denomination-effect">
        <div class="footer-div">
          DE
        </div>
        <div class="footer-div-label">
          Denomination Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You are less likely to spend larger bills than their equivalent in smaller denominations.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        DE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Denomination Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Focusing Effect -->
    <div v-if="current != 'FE'">
      <router-link to="/focusing-effect">
        <div class="footer-div">
          FE
        </div>
        <div class="footer-div-label">
          Focusing Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You place too much emphasis on one aspect of an event or situation, ignoring others.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Focusing Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Self-Enhancement Bias -->
    <div v-if="current != 'SEB'">
      <router-link to="/self-enhancement-bias">
        <div class="footer-div">
          SEB
        </div>
        <div class="footer-div-label">
          Self-Enhancement Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You have an inflated view of your own abilities or character.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SEB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Self-Enhancement Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>


    <!-- Affect Heuristic -->
    <div v-if="current != 'AH'">
      <router-link to="/affect-heuristic">
        <div class="footer-div">
          AH
        </div>
        <div class="footer-div-label">
          Affect Heuristic
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You make decisions based on your emotions rather than objective information.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        AH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Affect Heuristic
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>



<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
