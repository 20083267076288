import Vue from 'vue'
import Router from 'vue-router'

import Home from "./views/Home.vue";
import CbtApp from "./views/CbtApp.vue";
import Page from "./views/Page.vue";


Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  
  routes: [
    {
      path: '/',
      name: "Home",
      component: Home,
      meta: {title: 'Thinking Bugs - CBT Cognitive Distortions, Thinking Errors List'}
    },
    {
      path: '/cbt-app',
      name: "CbtApp",
      component: CbtApp,
      meta: {title: 'CBT App - Cognitive Behavioral Therapy App, Thought Record'}
    },
    {
      path: "/all-or-nothing-thinking",
      component: Page,
      meta: {title: 'All or Nothing Thinking - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/discounting-positives",
      component: Page,
      meta: {title: 'Discounting Positives - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/emotional-reasoning",
      component: Page,
      meta: {title: 'Emotional Reasoning - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/fortune-telling",
      component: Page,
      meta: {title: 'Fortune Telling - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/labeling",
      component: Page,
      meta: {title: 'Labeling - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/magnification-or-minimization",
      component: Page,
      meta: {title: 'Magnification or Minimization - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/mental-filter",
      component: Page,
      meta: {title: 'Mental Filter - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/mind-reading",
      component: Page,
      meta: {title: 'Mind Reading - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/other-blame",
      component: Page,
      meta: {title: 'Other Blame - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/overgeneralization",
      component: Page,
      meta: {title: 'Overgeneralization - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/personalization",
      component: Page,
      meta: {title: 'Personalization - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/self-blame",
      component: Page,
      meta: {title: 'Self Blame - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/should-statements",
      component: Page,
      meta: {title: 'Should Statements - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/magical-thinking",
      component: Page,
      meta: {title: 'Magical Thinking - CBT Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/About",
      component: Page,
      meta: {title: 'Thinking Bugs - About'}
    },
    {
      path: "/anchoring",
      component: Page,
      meta: {title: 'Anchoring - Behavioral Trap | Thinking Bugs'}
    },
    {
      path: "/availability-heuristic",
      component: Page,
      meta: {title: 'Availability Heuristic - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/confirmation-bias",
      component: Page,
      meta: {title: 'Confirmation Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/sunk-cost-fallacy",
      component: Page,
      meta: {title: 'Sunk Cost Fallacy - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/hindsight-bias",
      component: Page,
      meta: {title: 'Hindsight Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/motivated-reasoning",
      component: Page,
      meta: {title: 'Motivated Reasoning - Judgment Errors | Thinking Bugs'}
    },
    {
      path: "/wishful-thinking",
      component: Page,
      meta: {title: 'Wishful Thinking - Emotional Pitfalls | Thinking Bugs'}
    },
    {
      path: "/overconfidence-bias",
      component: Page,
      meta: {title: 'Overconfidence Bias - Judgment Errors | Thinking Bugs'}
    },
    {
      path: "/illusory-correlation",
      component: Page,
      meta: {title: 'Illusory Correlation - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/belief-bias",
      component: Page,
      meta: {title: 'Belief Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/cognitive-dissonance",
      component: Page,
      meta: {title: 'Cognitive Dissonance - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/system-justification",
      component: Page,
      meta: {title: 'System Justification - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/false-memory",
      component: Page,
      meta: {title: 'False Memory - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/pseudocertainty-effect",
      component: Page,
      meta: {title: 'Pseudocertainty Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/hyperbolic-discounting",
      component: Page,
      meta: {title: 'Hyperbolic Discounting - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/cognitive-miser",
      component: Page,
      meta: {title: 'Cognitive Miser - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/law-of-the-instrument",
      component: Page,
      meta: {title: 'Law of the Instrument - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/overconfidence-effect",
      component: Page,
      meta: {title: 'Overconfidence Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/pessimism-bias",
      component: Page,
      meta: {title: 'Pessimism Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/optimism-bias",
      component: Page,
      meta: {title: 'Optimism Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/curse-of-knowledge",
      component: Page,
      meta: {title: 'Curse of Knowledge - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/dunning-kruger-effect",
      component: Page,
      meta: {title: 'Dunning-Kruger Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/bias-blind-spot",
      component: Page,
      meta: {title: 'Bias Blind Spot - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/illusion-of-control",
      component: Page,
      meta: {title: 'Illusion of Control - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/self-handicapping",
      component: Page,
      meta: {title: 'Self-Handicapping - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/just-world-hypothesis",
      component: Page,
      meta: {title: 'Just-World Hypothesis - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/mere-ownership-effect",
      component: Page,
      meta: {title: 'Mere Ownership Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/egocentric-bias",
      component: Page,
      meta: {title: 'Egocentric Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/hostile-attribution-bias",
      component: Page,
      meta: {title: 'Hostile Attribution Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/actor-observer-bias",
      component: Page,
      meta: {title: 'Actor-Observer Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/reactive-devaluation",
      component: Page,
      meta: {title: 'Reactive Devaluation - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/false-consensus-effect",
      component: Page,
      meta: {title: 'False Consensus Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/base-rate-fallacy",
      component: Page,
      meta: {title: 'Base Rate Fallacy - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/denomination-effect",
      component: Page,
      meta: {title: 'Denomination Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/focusing-effect",
      component: Page,
      meta: {title: 'Focusing Effect - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/self-enhancement-bias",
      component: Page,
      meta: {title: 'Self-Enhancement Bias - Cognitive Distortion | Thinking Bugs'}
    },
    {
      path: "/system-1-and-system-2-thinking",
      component: Page,
      meta: {title: 'System 1 and System 2 Thinking - Reasoning Bug | Thinking Bugs'}
    },
    {
      path: "/affect-heuristic",
      component: Page,
      meta: {title: 'Affect Heuristic - Cognitive Distortion | Thinking Bugs'}
    },
    
    // {
    //   path: "/magical-thinking",
    //   component: Page,
    //   meta: {title: 'Magical Thinking - CBT Cognitive Distortion | Thinking Bugs'}
    // },


    
    // Logical Fallacies
    {
      path: "/false-cause",
      component: Page,
      meta: { title: 'False Cause - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/straw-man",
      component: Page,
      meta: { title: 'Straw Man - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/slippery-slope",
      component: Page,
      meta: { title: 'Slippery Slope - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/false-dichotomy",
      component: Page,
      meta: { title: 'False Dichotomy - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/circular-reasoning",
      component: Page,
      meta: { title: 'Circular Reasoning - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/appeal-to-emotion",
      component: Page,
      meta: { title: 'Appeal to Emotion - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/framing-effect",
      component: Page,
      meta: { title: 'Framing Effect - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/halo-effect",
      component: Page,
      meta: { title: 'Halo Effect - Logical Fallacy | Thinking Bugs' }
    },
    {
      path: "/authority-bias",
      component: Page,
      meta: { title: 'Authority Bias - Logical Fallacy | Thinking Bugs' }
    },

    // Judgment errors

    {
      path: "/gamblers-fallacy",
      component: Page,
      meta: {title: "Gambler’s Fallacy - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/base-rate-neglect",
      component: Page,
      meta: {title: "Base Rate Neglect - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/outcome-bias",
      component: Page,
      meta: {title: "Outcome Bias - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/misinformation-effect",
      component: Page,
      meta: {title: "Misinformation Effect - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/serial-position-effect",
      component: Page,
      meta: {title: "Serial Position Effect - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/primacy-effect",
      component: Page,
      meta: {title: "Primacy Effect - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/recency-effect",
      component: Page,
      meta: {title: "Recency Effect - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/hot-hand-fallacy",
      component: Page,
      meta: {title: "Hot-Hand Fallacy - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/planning-fallacy",
      component: Page,
      meta: {title: "Planning Fallacy - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/zero-risk-bias",
      component: Page,
      meta: {title: "Zero-Risk Bias - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/neglect-of-probability",
      component: Page,
      meta: {title: "Neglect of Probability - Judgment Error | Thinking Bugs"}
    },
    {
      path: "/survivorship-bias",
      component: Page,
      meta: {title: "Survivorship Bias - Judgment Error | Thinking Bugs"}
    },

    // Social Thinking Traps
    {
      path: "/groupthink",
      component: Page,
      meta: {title: "Groupthink - Social Thinking Trap | Thinking Bugs"}
    },
    {
      path: "/bandwagon-effect",
      component: Page,
      meta: {title: "Bandwagon Effect - Social Thinking Trap | Thinking Bugs"}
    },
    {
      path: "/ingroup-bias",
      component: Page,
      meta: {title: "Ingroup Bias - Social Thinking Trap | Thinking Bugs"}
    },
    {
      path: "/outgroup-homogeneity",
      component: Page,
      meta: {title: "Outgroup Homogeneity - Social Thinking Trap | Thinking Bugs"}
    },
    {
      path: "/stereotyping",
      component: Page,
      meta: {title: "Stereotyping - Social Thinking Trap | Thinking Bugs"}
    },
    {
      path: "/stereotype-threat",
      component: Page,
      meta: {title: "Stereotype Threat - Social Thinking Trap | Thinking Bugs"}
    },
    {
      path: "/moral-credential-effect",
      component: Page,
      meta: {title: "Moral Credential Effect - Social Thinking Trap | Thinking Bugs"}
    },

    // Emotional pitfalls

    {
      path: "/self-serving-bias",
      component: Page,
      meta: { title: 'Self-Serving Bias - Emotional Pitfall | Thinking Bugs' }
    },
    {
      path: "/visceral-bias",
      component: Page,
      meta: { title: 'Visceral Bias - Emotional Pitfall | Thinking Bugs' }
    },
    {
      path: "/ego-bias",
      component: Page,
      meta: { title: 'Ego Bias - Emotional Pitfall | Thinking Bugs' }
    },

    // Perceptual Biases

    {
      path: "/fundamental-attribution-error",
      component: Page,
      meta: { title: 'Fundamental Attribution Error - Perceptual Bias | Thinking Bugs' }
    },
    {
      path: "/contrast-effect",
      component: Page,
      meta: { title: 'Contrast Effect - Perceptual Bias | Thinking Bugs' }
    },
    {
      path: "/reactance-bias",
      component: Page,
      meta: { title: 'Reactance Bias - Perceptual Bias | Thinking Bugs' }
    },
    {
      path: "/representativeness-heuristic",
      component: Page,
      meta: { title: 'Representativeness Heuristic - Perceptual Bias | Thinking Bugs' }
    },
    {
      path: "/mere-exposure-effect",
      component: Page,
      meta: { title: 'Mere Exposure Effect - Perceptual Bias | Thinking Bugs' }
    },
    
    // Behavioral Traps

    {
      path: "/order-effects",
      component: Page,
      meta: { title: 'Order Effects - Behavioral Trap | Thinking Bugs' }
    },
    {
      path: "/vertical-line-failure",
      component: Page,
      meta: { title: 'Vertical Line Failure - Behavioral Trap | Thinking Bugs' }
    },
    {
      path: "/search-satisficing",
      component: Page,
      meta: { title: 'Search Satisficing - Behavioral Trap | Thinking Bugs' }
    },
    {
      path: "/diagnosis-momentum",
      component: Page,
      meta: { title: 'Diagnosis Momentum - Behavioral Trap | Thinking Bugs' }
    },
    {
      path: "/zebra-retreat",
      component: Page,
      meta: { title: 'Zebra Retreat - Behavioral Trap | Thinking Bugs' }
    },

    // Reasoning Bugs

    {
      path: "/congruence-bias",
      component: Page,
      meta: { title: 'Congruence Bias - Reasoning Bug | Thinking Bugs' }
    },
    {
      path: "/blind-spot-bias",
      component: Page,
      meta: { title: 'Blind Spot Bias - Reasoning Bug | Thinking Bugs' }
    },
    {
      path: "/semmelweis-reflex",
      component: Page,
      meta: { title: 'Semmelweis Reflex - Reasoning Bug | Thinking Bugs' }
    },

    // Memory distortions

    {
      path: "/source-confusion",
      component: Page,
      meta: { title: 'Source Confusion - Perceptual Biases | Thinking Bugs' }
    },
    // {
    //   path: "/mere-ownership-effect",
    //   component: Page,
    //   meta: { title: 'Mere Ownership Effect - Memory Distortion | Thinking Bugs' }
    // },

    
    // Decision-Making Fallacies

    {
      path: "/pro-innovation-bias",
      component: Page,
      meta: { title: 'Pro-Innovation Bias - Judgment Errors | Thinking Bugs' }
    },
    // {
    //   path: "/system-1-and-system-2-thinking",
    //   component: Page,
    //   meta: { title: 'System 1 and System 2 Thinking - Decision-Making Fallacy | Thinking Bugs' }
    // },

    
    
    

  ]
})
