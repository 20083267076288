<template>
  <div>
    <h1>Appeal to Emotion</h1>

    <h3>You manipulate emotions instead of using valid reasoning to win an argument.</h3>

    <!-- Explained Section -->
    <h4>Explained</h4>
    <p>The Appeal to Emotion fallacy occurs when someone uses emotional appeals rather than logical reasoning to persuade others. This fallacy exploits people's emotions—such as fear, pity, anger, or pride—to win an argument, often bypassing critical thinking. While emotions are an important part of human experience, relying on them alone to make decisions or prove a point can lead to poor judgments.</p>
    <p>This fallacy is particularly effective because it taps into the immediate and powerful influence of emotions, which can override rational analysis. It is commonly used in advertising, political rhetoric, and other forms of persuasive communication where the goal is to elicit a strong emotional response rather than present a sound argument.</p>

    <!-- Impact Section -->
    <h4>Impact</h4>
    <p>The Appeal to Emotion fallacy can lead to decisions based on feelings rather than facts, which can be problematic in contexts where rational decision-making is crucial, such as in policy-making or ethical discussions. It can also contribute to manipulation and exploitation, as individuals may be swayed by their emotions rather than by a careful consideration of the issues.</p>

    <!-- Causes Section -->
    <h4>Causes</h4>
    <p>This fallacy often arises from a desire to win an argument quickly and easily by appealing to people's emotional responses. It may also result from a lack of strong evidence to support a position, leading the individual to rely on emotions as a substitute for logical reasoning.</p>

    <!-- Prevention Section -->
    <h4>Prevention</h4>
    <p>To avoid the Appeal to Emotion fallacy, it is important to separate emotions from logical reasoning when evaluating an argument. While emotions can provide valuable insights, they should not be the sole basis for decision-making. Asking questions like "What is the evidence?" and "Is this argument based on logic or emotion?" can help ensure that decisions are made rationally.</p>

    <!-- Research Section -->
    <h4>Research</h4>
    <p>Research by Leventhal and Scherer (1987) on "The Relationship Between Emotion and Cognition" explores how emotions influence reasoning and decision-making. Their findings suggest that while emotions play a role in thinking, overreliance on emotional appeals can lead to cognitive biases and fallacies like the Appeal to Emotion.</p>
    <p>A study by Schwarz and Clore (1983) on "Mood and Information Processing" demonstrates how emotional states can affect the way people process information, highlighting the importance of being aware of emotional influences when evaluating arguments.</p>

    <!-- Examples Section -->
    <h4>Examples</h4>
    <ul>
      <li>"Think of the children! We must pass this law to protect them."</li>
      <li>"You should donate to this cause because it’s so heartbreaking to see those animals suffer."</li>
      <li>"How could you not support this candidate? Don’t you care about our country’s future?"</li>
      <li>"If you don’t vote for this bill, you are letting down all the people who are counting on us."</li>
      <li>"This product is the best because it will make you feel happier and more confident."</li>
    </ul>

    <!-- Reframing Section -->
    <h4>Reframing</h4>
    <div class="reframing-example">
      <i>Original thought:</i>
      <div class="reframing-original-thought">"I feel strongly about this, so it must be right."</div>
      <i>Reframed thought:</i>
      <div class="reframing-reframed-thought">"I should consider whether my feelings are clouding my judgment and look at the facts."</div>
    </div>

    <MenuBottomLF v-bind:current="'AE'"></MenuBottomLF>
  </div>
</template>

<script>
  import MenuBottomLF from '../../components/MenuBottomLF.vue';

  export default {
    components: {
      MenuBottomLF,
    }
  }
</script>
