<template>
  <div>
    <h1>
      Self Blame
    </h1>
    
    <h3>You blame yourself for something you weren’t entirely responsible for.</h3>


    <h4>Explained</h4>

    <p>Self-blame, also known as self-blaming or self-directed blame, is a cognitive distortion that occurs when an individual attributes responsibility for their negative experiences or outcomes to themselves, even when external factors may have played a significant role. This type of thinking error can be harmful because it can lead to feelings of low self-esteem, guilt, and depression, and it can also prevent individuals from seeking help or support when they need it.</p>

    <p>
      Examples of self-blame include:<br>
      <ul>
        <li>"I must have done something to cause my partner to cheat on me."</li>
        <li>"I'm such a failure because I couldn't get that promotion."</li>
        <li>"It's my fault that my child is struggling in school, I must not be a good enough parent."</li>
      </ul>
    </p>

    <p>Self-blame can be particularly common in situations where there is a lack of clarity about what caused a negative outcome, or when an individual feels helpless or powerless to change the situation. It can also be a coping mechanism for individuals who have a tendency to internalize their emotions or who have perfectionistic tendencies.</p>

    <p>Self-blame can be harmful because it can lead to negative emotions and harmful behaviors. It can contribute to feelings of low self-esteem, guilt, and depression, and it can also prevent individuals from seeking help or support when they need it.</p>

    <p>Self-blame can also create a cycle of negative thinking and behavior. For example, if an individual blames themselves for their partner cheating on them, they may feel unworthy of love or respect, which can lead to negative self-talk and behaviors that further reinforce their belief in their own worthlessness.</p>

    <p>In conclusion, self-blame is a common cognitive distortion that can be harmful to our mental health and well-being. By recognizing when we're engaging in self-blame, practicing self-compassion, seeking support, and reframing our thoughts, we can reduce its negative impact and work towards a more balanced and healthy perspective.</p>

    <h4>Example</h4>

    <p>Self-blame error: "I'm such an idiot for forgetting my keys at the office. I can't believe I made such a stupid mistake."</p>

    <p>Reframed thought: "Everyone makes mistakes sometimes. It was an honest error to forget my keys at the office. I'll just make sure to double check before I leave in the future."</p>

    
    <CBTAppText v-bind:current="'SB'"></CBTAppText>

    <menu-bottom v-bind:current="'SB'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>