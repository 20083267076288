<template>
  <div>
    <h1>
      Labeling
    </h1>

    <h3>Instead of saying, “I made a mistake,” you tell yourself, “I’m a jerk” or “I’m a loser”.</h3>

    <h4>Explained</h4>
        
    <p>Labeling is a type of cognitive distortion, which refers to the way our thoughts can sometimes distort reality and lead us to negative conclusions or behaviors. Labeling is when we assign global, negative labels to ourselves or others based on one negative trait or action. This can be damaging because it leads to oversimplification and black-and-white thinking, and can cause us to view ourselves or others in a negative light that is not accurate or fair.</p>

    <p>One example of labeling is when someone makes a mistake and labels themselves as "stupid" or "incompetent." This is an extreme and global label that is not accurate, as no one is inherently "stupid" or "incompetent." Everyone makes mistakes, and it's important to recognize that these mistakes do not define us as a person.</p>

    <p>Another example of labeling is when someone gets into an argument with a friend and labels them as "mean" or "selfish." This is also an oversimplification, as no one is entirely "mean" or "selfish." People have good and bad qualities, and it's important to recognize that one negative interaction does not define someone's entire character.</p>

    <p>Labeling is harmful because it leads to negative self-talk and can cause us to view ourselves or others in a negative light that is not accurate or fair. It can also lead to a lack of self-compassion and understanding, as we are not acknowledging the complexity of ourselves or others.</p>

    <p>To combat labeling, it's important to recognize when we are engaging in this type of thinking and to challenge it. Instead of labeling ourselves or others, we can try to recognize the specific behavior or action that led to the negative label and focus on that. We can also try to view ourselves and others with more compassion and understanding, recognizing that everyone has both positive and negative traits.</p>

    <p>Overall, labeling is a harmful cognitive distortion that can lead to negative self-talk and misunderstandings. By recognizing and challenging this type of thinking, we can cultivate a more positive and accurate view of ourselves and others.</p>
    
    <h4>Example</h4>
    
    <p>
      Example of a thought with the Labeling cognitive distortion:<br>
      "I'm such a failure. I can't even get this simple task right."
    </p>

    <p>
      Rewritten/reframed thought:<br>
      "I made a mistake on this task, but that doesn't mean I am a failure as a person. Everyone makes mistakes and it's an opportunity to learn and improve for the future."
    </p>

    <CBTAppText v-bind:current="'Lab'"></CBTAppText>

    <menu-bottom v-bind:current="'Lab'"></menu-bottom>
    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>