<template>
  <div>
    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Memory Distortions List</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Memory Distortions</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Memory distortions are errors related to the recall or retention of information, affecting how accurately we remember events or details. These distortions can lead to false memories, confusion about the source of information, or altered perceptions of past events. Understanding and recognizing these distortions is crucial for improving the accuracy of our memories and avoiding misunderstandings based on faulty recall.
      <div style="height:40px;"></div>
    </div>

    <!-- Source Confusion -->
    <div v-if="current != 'SC'">
      <router-link to="/source-confusion">
        <div class="footer-div">
          SC
        </div>
        <div class="footer-div-label">
          Source Confusion
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You misattribute the source of a memory, confusing whether it was something you experienced, imagined, or heard from someone else.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SC
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Source Confusion
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Mere Ownership Effect -->
    <div v-if="current != 'MOE'">
      <router-link to="/mere-ownership-effect">
        <div class="footer-div">
          MOE
        </div>
        <div class="footer-div-label">
          Mere Ownership Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You remember objects or information more favorably simply because you own or possess them.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        MOE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Mere Ownership Effect
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
