<template>
    <div>
      <h1>Self-serving Bias</h1>
  
      <h3>You attribute your successes to internal factors and your failures to external factors.</h3>
  
      <!-- Explained Section -->
      <h4>Explained</h4>
      <p>Self-serving Bias is a common cognitive distortion where individuals tend to credit themselves for their successes, attributing them to internal factors like their skills, intelligence, or effort. Conversely, when things go wrong, they blame external factors such as bad luck, unfair circumstances, or other people's actions.</p>
      <p>This bias serves as a psychological defense mechanism, helping to protect and enhance self-esteem by shifting responsibility away from oneself during negative outcomes. It allows individuals to maintain a positive self-image, even in the face of failure. While this can boost confidence and resilience, it also hinders personal growth by preventing individuals from acknowledging and learning from their mistakes.</p>
      <p>Over time, Self-serving Bias can lead to an unrealistic and inflated sense of one's abilities and a tendency to underappreciate the contributions of others. It can also strain relationships, as others may perceive the person as arrogant, dismissive, or unwilling to accept responsibility.</p>
  
      <!-- Impact Section -->
      <h4>Impact</h4>
      <p>The impact of Self-serving Bias can be significant both personally and professionally. In personal relationships, it can create friction and resentment, as others may feel that their efforts are not acknowledged or that they are unfairly blamed for failures. This can lead to a breakdown in communication and trust.</p>
      <p>In professional settings, Self-serving Bias can hinder teamwork and collaboration. If individuals consistently take credit for successes and deflect blame for failures, it can create a toxic work environment. This behavior can also stifle innovation and learning, as mistakes are not openly discussed and addressed.</p>
  
      <!-- Causes Section -->
      <h4>Causes</h4>
      <p>Self-serving Bias is primarily driven by the need to maintain and protect self-esteem. When faced with success, attributing it to internal factors reinforces a positive self-concept. Conversely, when faced with failure, blaming external factors helps mitigate the negative impact on self-esteem.</p>
      <p>This bias is often reinforced by societal and cultural norms that value success and competence. Additionally, individuals may be more prone to Self-serving Bias if they have a strong desire for control or a fear of failure. This bias is also more likely to occur in competitive environments where success is highly valued.</p>
  
      <!-- Prevention Section -->
      <h4>Prevention</h4>
      <p>Preventing Self-serving Bias involves cultivating self-awareness and a willingness to critically evaluate one's actions and decisions. Acknowledging both successes and failures objectively can help mitigate this bias. Seeking feedback from others and being open to constructive criticism can also provide a more balanced perspective.</p>
      <p>Practicing humility and gratitude can counteract Self-serving Bias by helping individuals appreciate the contributions of others and recognize the role of external factors in their successes. By focusing on continuous learning and growth, individuals can develop a more realistic and balanced view of their abilities.</p>
  
      <!-- Research Section -->
      <h4>Research</h4>
      <p>Research on Self-serving Bias has shown that it is a widespread phenomenon across different cultures and contexts. A study by Miller and Ross (1975) found that people are more likely to attribute successes to internal factors and failures to external factors, demonstrating the pervasiveness of this bias.</p>
      <p>Further research has explored how Self-serving Bias is influenced by individual differences, such as self-esteem and personality traits. Studies have also examined how this bias affects decision-making and interpersonal relationships, highlighting its impact on both personal and professional outcomes.</p>
  
      <!-- Examples Section -->
      <h4>Examples</h4>
      <ul>
        <li>A student who receives a high grade on an exam might attribute their success to their intelligence and hard work, but if they receive a low grade, they might blame the exam's difficulty or poor teaching.</li>
        <li>In a workplace setting, an employee might take credit for a successful project, citing their leadership and skills, but if the project fails, they might blame team members or unforeseen circumstances.</li>
        <li>An athlete who wins a competition might attribute their victory to their training and talent, while if they lose, they might blame the referee or bad weather conditions.</li>
      </ul>
  
      <!-- Reframing Section -->
      <h4>Reframing</h4>
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"I did well because I'm naturally talented."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"My hard work paid off this time, but I should also acknowledge the support I received from others."</div>
      </div>
  
      <div class="space-thoughts"></div>
  
      <div class="reframing-example">
        <i>Original thought:</i>
        <div class="reframing-original-thought">"It's not my fault the project failed; the team didn't cooperate."</div>
        <i>Reframed thought:</i>
        <div class="reframing-reframed-thought">"I could have communicated better with the team to ensure everyone was on the same page."</div>
      </div>
  
      <MenuBottomEP v-bind:current="'SSB'"></MenuBottomEP>
    </div>
  </template>
  
  <script>
    import MenuBottomEP from '../../components/MenuBottomEP.vue';
  
    export default {
      components: {
        MenuBottomEP,
      }
    }
  </script>
  